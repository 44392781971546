import React from 'react';
import "./_RequestStatusBadge.scss";



function RequestStatusBadge ({status}) {
    const sentimentClass = {
        denied: 'danger',
        approved: 'success',
        pending: 'warning',
        invalid: ''
    }
  return (
    <hbr-tag class="text-capitalize text-center" sentiment={sentimentClass[status.toLowerCase()]} size="medium">{status.toLowerCase()}</hbr-tag>
  )
}


export default RequestStatusBadge;