import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './_ActionModals.scss';
import Button from 'react-bootstrap/Button';
import CommonConfiramation from '../../../../common/base/CommonConfiramation/CommonConfiramation';

function ActionModals({show, onClose, onSubmit, type}) {
   // const [denyReason, SetDenyReason] = useState('');
    const ModalData = {
        "approve": {
          header:"Confirm Request Approval",
          message:"Are you sure you want to Approve this Request?",
          okButtonLabel:"Approve"
        },
        "deny": {
          header:"Confim Request Denial",
          provideDenialReason:true,
          message:"Are you sure you want to deny this API access request? You can approve or revoke this decision later if needed.",
          okButtonLabel:"Deny",
          sentiment:'danger'
        },
        "remove": {
          header:"Confim Request Removal",
          message:"Are you sure you want to Remove this Request?",
          okButtonLabel:"Remove",
          sentiment:'danger'
        }
      }
      const popupConfig = ModalData[type];
      return (
        <CommonConfiramation show={show} popupConfig={popupConfig} onCancel={onClose} onSubmit={(denyReason)=>onSubmit(denyReason)}></CommonConfiramation>
    )
}

export default ActionModals;