import React, { createContext, useState, useContext, useEffect } from 'react';

const CombinedContext = createContext();

export const CombinedProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingSet, setLoadingSet] = useState(new Set());

  const showLoader = (id) => {
    console.log("show ",id);
    setLoadingSet(prevSet => new Set(prevSet).add(id));
  };

  const hideLoader = (id) => {
    console.log("hide ",id);
    setLoadingSet(prevSet => {
      const newSet = new Set(prevSet);

      if (id == "failed") {
        newSet.clear();
      } else {
        newSet.delete(id);
      }

      
      return newSet;
    });
  };
  
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  return (
    <CombinedContext.Provider value={{ loading, showLoader, hideLoader, breadcrumbItems,
      setBreadcrumbItems, loadingSet }}>
      {children}
    </CombinedContext.Provider>
  );
};

export const useCombined = () => useContext(CombinedContext);

