/**
 * Created by aaikepae on 11/3/16.
 */
import React from "react"
import {smoothScroll} from "../../../../common/utility/dom";

import Footer from "../Foorter";


import WidgetLoader from "../../../../common/base/WidgetLoader"

import {
  parseURL,
  updateRelatedLink,
  removeVideoAutoPlay
} from "../../../../common/utility/utils";


import {
  generateCodeSection
} from "../../../../common/utility/codeSection"
import MyAccessPage from "../../../../siteEngine/components/MyAcessPage/MyAccessPage";
import MyApps from "../../MyApps/MyApps";

export default class Basic extends React.Component {


  // get absolute URI path of current content
  get contentURLPath() {
    const {config, selected} = this.props;
    if (config && config.location && selected && selected.item && selected.item.content) {
      const contentPath = selected.item.content;
      return config.location + contentPath.substr(0, contentPath.lastIndexOf("/") + 1);
    }
  }

  // find pubhub instance's container

  get container() {
    if (this.refs) {
      const docEL = this.refs["doc"];
      return Array.from(document.querySelectorAll(".pubhub-container")).find(function (el) {
        return el.contains(docEL)
      });
    }
  }


  constructor(props) {
    super(props);

    this.html = null;
    this._scrolling = false;
    this._scrollingTimer = null;
    this._scrollingSection = false;
    this.selectedCodeSectionID = null;
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.calcCodeSectionPosition = this.calcCodeSectionPosition.bind(this);
    this.deferScroll = this.deferScroll.bind(this);
    this.selectCodeSection = this.selectCodeSection.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    // if change section, scroll to section
    // if (this.props.selected.section !== nextProps.selected.section) {
    //   this.scrollToSection(nextProps.selected.section);
    // }
  }

  onClickOrphenLink = (e) => {
    if (e.target.tagName != 'A' || e.target.className != 'orphan_link' || !e.target.href) return;
    const href = e.target.href.toLowerCase();
    if (!href.endsWith('.html') && !href.endsWith('.htm')) return;

    e.preventDefault();
    this.props.onStartLoadOrphenLink && this.props.onStartLoadOrphenLink(e.target.href);
  }

  componentDidMount() {
    //generate html fragment and generate codeSection
    const wrapper = document.createElement('div');
    wrapper.innerHTML = this.props.data;

    //process
    updateRelatedLink(wrapper, this.contentURLPath, this.props.config.location);
    removeVideoAutoPlay(wrapper);

    // code section
    const { selected } = this.props;
    const codeSection = generateCodeSection(wrapper, this.props.config, selected && selected.item && selected.item.config);
    this.props.dispatch({
      type: "UPDATE_CODE_SECTION",
      payload: codeSection
    });
    //

    this.html = wrapper.innerHTML;

    // send GA tracking information
    //trackDoc(this.props.selected.item.title, this.props.selected.item.url);

    // 
    window.addEventListener('click', this.onClickOrphenLink);
  }

  componentDidUpdate(prevProps, prevState) {

    // first time render the content
    if (this.props.selected.codeSections !== prevProps.selected.codeSections) {

      //register scroll event
      window.addEventListener('scroll', this.handleScroll);

      // scroll to section
      this.scrollToSection()
    }

    if (this.props.selected.section !== prevProps.selected.section) {
      this.scrollToSection();
    }

  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.config !== this.props.config) {
      return true
    }

    if (this._scrollingSection) {
      return false;
    }

    if (this._scrolling) {
      return false;
    }

    // for anchor jump inside of a doc without refreshing
    if (nextProps.selected.data == this.props.selected.data && nextProps.selected.section !== this.props.selected.section) {
      this.scrollToSection(nextProps.selected.section);
      return false
    }


    if (nextProps.selected.codeSections == this.props.selected.codeSections) {
      if (nextProps.selected.currentCodeSection !== this.props.selected.currentCodeSection) {
        return false
      }
    }
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('click', this.onClickOrphenLink);  
  };

  renderOrphenLinkGoback = () => {
    if (!this.props.selected.orphenLinkUrl) return null;

    return (
      <div className="orphen-link-goback-section">
        <span className="orphen-link-goback-link" onClick={this.props.onOrphenLinkGoback}>{'< Back'} </span>
      </div>
    );
  }

  render() {
    const {config, selected, items} = this.props;

    if ((selected.itemURL === 'my_apps' || this.props.data) && selected.codeSections) {

      let codeLayoutClass = config.codeLayout;
      if ((codeLayoutClass == "two-columns" && config["_platform"] !== "lg") || selected.codeSections.length == 0) {
        codeLayoutClass = "one-column"
      }

      const contentClass = ["column-doc column-doc-static", codeLayoutClass, selected.item.type, config.layout].join(" ");

      return (
        <div className={contentClass} ref="doc">
          {this.renderOrphenLinkGoback()}

          {selected.itemURL === 'my_apps' ? (<></>
              //  <MyApps selected={selected} config ={config} items={items}/>
          ):
           ( 
            <>
           <WidgetLoader html={this.html} context={this.props} config={this.props.config} className="pb-doc-content"/>
         
           <Footer router={this.props.router} config={this.props.config} selected={this.props.selected}/>
            </>)
          }
         
        </div>
      )
    } else {
      return <div/>
    }
  }

  handleScroll() {
    if (this.props.selected.orphenLinkUrl) return;

    if (this._scrollingSection) {
      return
    }

    //
    this._scrolling = true;

    const docEL = this.refs["doc"];
    const bound = docEL.getBoundingClientRect();
    const codeTabs = Array.from(docEL.querySelectorAll("h1,h2"));
    const offsets = codeTabs.map((el, index) => {
      return {offset: el.offsetTop, index, id: el.id}
    });

    // find visible code sections
    const visibleItems = offsets.filter((item) => {
      return item.offset + bound.top > 10 && item.offset + bound.top < window.innerHeight - 30;
    });

    let id = null;
    let currentID = this.props.selected.section;

    if (visibleItems.length > 0) {
      id = visibleItems[0].id;
    }

    if (id && id !== currentID) {
      if (this.props.router.mode === 'history') {
        this.props.router.queryString = this.props.selected.itemURL + location.search + "#" + id
      } else {
        window.location.href = "#!" + this.props.selected.itemURL + "/" + id;
      }
    }


    //throttle holder, defer
    if (this._scrollingTimer) {
      clearTimeout(this._scrollingTimer);
    }

    this._scrollingTimer = setTimeout(() => {
      this.deferScroll();
      this._scrolling = false;
    }, 300);

  }

  deferScroll() {
    this.calcCodeSectionPosition();
  }


  scrollToSection(targetSection) {

    //
    if (this._scrolling) {
      return;
    }

    this._scrollingSection = true;

    //throttle holder
    if (this._scrollTimer) {
      clearTimeout(this._scrollTimer);
    }

    this._scrollTimer = setTimeout(() => {

      const section = targetSection || this.props.selected.section;
      //not render yet
      if (!this.container) {
        this._scrollingSection = false;
        return;
      }


      //query by id
      let el = document.getElementById(decodeURIComponent(section));

      // query by name
      if (!el) {
        el = document.querySelector("[name='" + section + "']")
      }

      // if target is code section
      if (el && el.classList.contains("code-tab-container")) {
        this.selectedCodeSectionID = section;
        this.selectCodeSection(section, el);
      }

      // if there is a section need to scroll then use that element's offsetTop
      // otherwise scroll to container top

      let scollDistance = 0;
      const scrollingContainer = document.scrollingElement || document.documentElement;
      const _navTopOffset = this.props.config._navTopOffset;


      if (el) {
        scollDistance = scrollingContainer.scrollTop + el.getBoundingClientRect().top - 10 - _navTopOffset; //+ scrollingContainer.scrollTop
      } else {
        scollDistance = 0;
      }

      // const _scroll_offset_top = el ?  : this.container.querySelector(".pubhub-doc-container").offsetTop;

      smoothScroll(scrollingContainer, scollDistance, 400, () => {
        if (!this.selectedCodeSectionID) {
          this.calcCodeSectionPosition();
        }
        this._scrollingSection = false;
      });

    }, 100);

  }

  calcCodeSectionPosition() {
    const docEL = this.refs["doc"];
    if (!docEL) {
      return;
    }
    const bound = docEL.getBoundingClientRect();
    const codeTabs = Array.from(docEL.querySelectorAll(".code-tab-container"));
    const offsets = codeTabs.map((el, index) => {
      return {offset: el.offsetTop, index, id: el.id}
    });


    // if the selected code section in the screen
    if (this.selectedCodeSectionID) {
      // find visible code sections
      const visibleItems = offsets.filter((item) => {
        return item.offset + bound.top > 10 && item.offset + bound.top < window.innerHeight - 60;
      });

      const isSelectedSectionVisible = visibleItems.some((el) => el.id == this.selectedCodeSectionID);

      if (isSelectedSectionVisible) {
        return
      }

      this.selectedCodeSectionID = null;

    }


    // find all visible code sections
    const visibleItems = offsets.filter((item) => {
      return item.offset + bound.top > 10 && item.offset + bound.top < window.innerHeight / 2;
    });


    if (visibleItems.length > 0) {

      // // check if current code section in the detecting area
      // let currentCodeSection = parseInt(document.querySelector(".code-tab-container-active").getAttribute("data-index"));//this.props.selected.currentCodeSection;
      // const isCurrentCodeSectionVisible = visibleItems.some((el) => el.index == currentCodeSection);
      // if (isCurrentCodeSectionVisible) {
      //   return
      // }


      this.selectCodeSection(visibleItems[0].id);

    }

  }


  //
  selectCodeSection(id, sectionElement = null) {

    const el = sectionElement ? sectionElement : document.getElementById(id);
    const index = el.getAttribute("data-index");

    let currentCodeSection = parseInt(document.querySelector(".code-tab-container-active").getAttribute("data-index"));//this.props.selected.currentCodeSection;

    if (index !== null && index !== currentCodeSection) {

      // remove current codeSection
      const currentCodeSectionEL = document.querySelector(".code-tab-container-active");
      if (currentCodeSectionEL) {
        currentCodeSectionEL.classList.remove("code-tab-container-active");
      }

      //active current code section
      el.classList.add("code-tab-container-active");

      this.props.dispatch({
        type: "UPDATE_CURRENT_CODE_SECTION",
        payload: index
      })
    }

  }

}



