import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  isSameDay,
  addDays,
  startOfMonth,
  isSameMonth,
  subMonths,
  subDays,
} from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./metricsFilter.scss";
import Dropdown from "./Dropdown";
import useMetricsFilter from "./useMetricsFilter";

const customStaticRanges = createStaticRanges([
  {
    label: "1 Day",
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), -1),
    }),
    isSelected(range) {
      return (
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, addDays(new Date(), -1))
      );
    },
  },
  {
    label: "3 Days",
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), -3),
    }),
    isSelected(range) {
      return (
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, addDays(new Date(), -3))
      );
    },
  },
  {
    label: "5 Days",
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), -5),
    }),
    isSelected(range) {
      return (
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, addDays(new Date(), -5))
      );
    },
  },
  {
    label: "7 Days",
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), -7),
    }),
    isSelected(range) {
      return (
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, addDays(new Date(), -7))
      );
    },
  },
  {
    label: "15 Days",
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), -15),
    }),
    isSelected(range) {
      return (
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, addDays(new Date(), -15))
      );
    },
  },
  {
    label: "30 Days",
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), -30),
    }),
    isSelected(range) {
      return (
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, addDays(new Date(), -30))
      );
    },
  },
  {
    label: "60 Days",
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), -60),
    }),
    isSelected(range) {
      return (
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, addDays(new Date(), -60))
      );
    },
  },
  {
    label: "90 Days",
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), -90),
    }),
    isSelected(range) {
      return (
        isSameDay(range.startDate, new Date()) &&
        isSameDay(range.endDate, addDays(new Date(), -90))
      );
    },
  },
]);

const initialState = [
  {
    startDate: addDays(new Date(), 0),
    endDate: addDays(new Date(), 0),
    key: "selection",
  },
];

const MetricsFilter = ({onDateChange, onOrgChange, onRefresh, onReset}) => {
  const {
    loading,
    getOrgList,
    getAPIList,
    getInstancesList,
    setSelectedAPIName,
    resetFilters,
    orgList,
    publisherApiList,
    instancesList,
    selectedApi,
    selectedIds
  } = useMetricsFilter();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [state, setState] = useState(initialState);
 const [selectedDate, setSelectedDate] = useState(initialState);
  const [showSelect, setShowSelect] = useState(false);
 const [showSelectedApi, setShowSelectedApi] = useState({org:'', apiName:''});
  const dateRange = useRef();
  const selectRef = useRef();
  const calendarFocus = useRef("backwards");

  useEffect(() => {
    getOrgList();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (dateRange.current && !dateRange.current.contains(e.target)) {
      hideDatePicker();
    }
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setShowSelect(false);
    }
  };

  const handleConfirm = () => {
    if (state[0].endDate > state[0].startDate) {
      setSelectedDate(state);
      onDateChange && onDateChange(state[0]);
    } else {
      const selectedDates = {
        startDate: state[0].endDate,
        endDate: state[0].startDate,
      };
      setSelectedDate([selectedDates]);
      onDateChange && onDateChange(selectedDates);
    }
    setShowDatePicker(false);
  };

  const getFormattedDates = (date) => {
    return dayjs(date).format("DD/MM/YYYY");
  };

  const hideDatePicker = () => {
    setState(selectedDate);
    setShowDatePicker(false);
  };

  const onHbrSelect = (e) => {
    switch (e.target.name) {
      case "businessGroupSelect":
        getAPIList(e.target.value, e.target.selectedData);
        break;
      case "apiSelect":
        getInstancesList(e.target.value, e.target.selectedData);
        break;
      case "instanceSelect":
        setSelectedAPIName(e.target.value, e.target.selectedData);
        break;
    }
  };

  const handleSelectClick = () => {
    setShowSelect((prevValue) => !prevValue);
  };

  const handleView = () => {
    if(selectedIds.orgName && selectedIds.apiName && selectedIds.insName) {
      setShowSelect(false);
      setShowSelectedApi(selectedApi);
      onOrgChange && onOrgChange(selectedIds);
    }
  };

  const getTopPosition = () => {
    const field = selectRef.current;
    return `${field?.offsetHeight || 65}px`;
  };

  const handleDateChange = (item) => {
    const startDateMonth = startOfMonth(item.selection.startDate);
    const endDateMonth = startOfMonth(item.selection.endDate);
    const lastMonthStartDate = startOfMonth(subMonths(new Date(), 1));
    const currentMonth = startOfMonth(new Date());
    if (isSameMonth(startDateMonth, lastMonthStartDate)) {
      calendarFocus.current = "forwards";
    } else {
      calendarFocus.current = isSameMonth(startDateMonth, endDateMonth) || isSameMonth(startDateMonth, currentMonth)
        ? "backwards"
        : "forwards";
    }
  };

  const handleReset = () => {
    resetFilters();
    setSelectedDate(initialState);
    setState(initialState);
    setShowSelectedApi({org: '', apiName: ''});
    onReset && onReset(initialState);
  }

  return (
    <div className="metrics-filter-container">
      <div
        className="api-selection"
        ref={selectRef}
      >
        <div
          className="select-field"
          onClick={handleSelectClick}
        >
          <div className="select-trigger">
            {showSelectedApi.org && <label>{showSelectedApi.org}</label>}
            <label className="d-flex align-items-center">
              <hbr-icon name="nav-outbreak-control"></hbr-icon>
              <hbr-tooltip hoist content={showSelectedApi.apiName || 'Select an API'} placement="top" className="atm-tooltip--large">
                <span>{showSelectedApi.apiName || 'Select an API'}</span>
              </hbr-tooltip>
            </label>
          </div>
          {!loading && <i className={`atm-icon ${showSelect ? "chevron-down" : "chevron-right"}`}/>}
          {loading && <hbr-progress-ring size="50" indeterminate="true" />}
        </div>
        {showSelect && (
          <div
            className="dropdown-container"
            style={{ top: getTopPosition() }}
          >
            <Dropdown
              name="businessGroupSelect"
              data={orgList}
              label="Business Group Name"
              placeholder="Select Business Group"
              onHbrSelect={onHbrSelect}
              idKey="organizationId"
              descKey="businessGroupName"
              value={selectedIds.orgId}
            />
            <Dropdown
              name="apiSelect"
              data={publisherApiList}
              label="API Name"
              placeholder="Select API"
              onHbrSelect={onHbrSelect}
              idKey="apiId"
              descKey="assetId"
              value={selectedIds.apiId}
            />
            <Dropdown
              name="instanceSelect"
              data={instancesList}
              label="Version / Instance"
              placeholder="Select Version / Instance"
              onHbrSelect={onHbrSelect}
              idKey="instanceId"
              descKey={["productVersion", "instanceLabel"]}
              value={selectedIds.insId}
            />
            <div className="button-container">
              <button className="iap-btn btn btn-secondary" onClick={() => setShowSelect(false)}>Cancel</button>
              <button disabled={(!selectedIds.orgName || !selectedIds.apiName || !selectedIds.insName)} className="iap-btn btn btn-primary" onClick={handleView}>View Metrics</button>
            </div>
          </div>
        )}
      </div>
      <div
        ref={dateRange}
        className="date-selection ml-4"
      >
        <div className="date-range-container">
          <a
            className="calendar-icon"
            onClick={() => setShowDatePicker(true)}
          >
            <i className="atm-icon calendar" />
          </a>
          <div className="date-range">
            <p>
              <strong>From: </strong>
              {getFormattedDates(selectedDate[0]?.startDate)}
            </p>
            <p>
              <strong>To: </strong>
              {getFormattedDates(selectedDate[0]?.endDate)}
            </p>
          </div>
        </div>
        {showDatePicker && (
          <div className="date-range-picker">
            <DateRangePicker
              onChange={(item) => {
                handleDateChange(item);
                setState([item.selection]);
              }}
              showDateDisplay={false}
              showSelectionPreview={false}
              headerContent="Show last:"
              months={2}
              calendarFocus={calendarFocus.current}
              ranges={state}
              minDate={subDays(new Date(), 90)}
              maxDate={new Date()}
              direction={window.innerWidth < 936 ? "vertical" : "horizontal"}
              staticRanges={window.innerWidth < 576 ? [] : customStaticRanges}
              inputRanges={[]}
            />
            <div className="button-container d-flex flex-column flex-sm-row justify-content-end">
              <button className="iap-btn btn btn-secondary mr-2" onClick={hideDatePicker}>Cancel</button>
              <button className="iap-btn btn btn-primary" onClick={handleConfirm}>Confirm Selection</button>
            </div>
          </div>
        )}
        <div className="reload">
          <hbr-tooltip hoist content="Refresh data" placement="top" className="atm-tooltip--small">
            <hbr-icon name="refresh" onClick={onRefresh}></hbr-icon>
          </hbr-tooltip>
        </div>
        {/* <div className="reset">
          <hbr-tooltip hoist content="Reset filters" placement="top" className="atm-tooltip--small">
            <i className="atm-icon refresh" onClick={handleReset}/>
          </hbr-tooltip>
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(MetricsFilter);
