import axios from "axios";
import { 
    APPROVE_REQUEST,
    DELETE_REQUEST,
    DENY_REQUEST,
    SET_LOADER,
    CATCH_ERROR,
    FETCH_ACCESS_REQUESTS,
    FETCH_PENDING_REQUESTS,
    RESET_ACTION
} from "./type";
import { API_PATHS, bearerToken } from "./constant";

export const setLoader = (loader) => (dispatch) => {
    dispatch({ type: SET_LOADER, payload: loader })
}

export const resetAction = () => (dispatch) => {
  dispatch({ type: RESET_ACTION, payload: {} });
};

export const fetchAccessRequest = (currentPage, pageSize, searchTxt, approveStatus, id) => {
  const addId = id ? `&id=${id}` : "";
  return (dispatch) => {
    axios
      .get(`${API_PATHS.ACCESS_PAGE_API}?page=${currentPage}&size=${pageSize}&searchText=${searchTxt}&filterBy=${approveStatus.toUpperCase()}${addId}`)
      .then((res) => {
        console.log("access list ",res);
        dispatch({ type: FETCH_ACCESS_REQUESTS, payload: { data: res.data }});
      })
      .catch((err) => {
        dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data?.error, status: false }});
      })
  }
}
export const fetchPendingRequests = () => {
  return (dispatch) => {
    axios
      .get(`${API_PATHS.PENDING_REQUESTS}`)
      .then((res) => {
        console.log("pending requests ",res);
        dispatch({ type: FETCH_PENDING_REQUESTS, payload: { data: res.data }});
      })
      .catch((err) => {
        dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data?.error, status: false }});
      })
  }
}

export const approveRequest = (id, type) => {
    return (dispatch) => {
      axios
        .post(`${API_PATHS.ACCESS_PAGE_API}/${id}/${type}`)
        .then((res) => {
            console.log("approve_request ",res);
          dispatch({
            type: APPROVE_REQUEST,
            payload: {
              data: res.data,
            },
          })
        })
        .catch((err) => {
          dispatch({
            type: CATCH_ERROR,
            payload: {
              data: err.response?.data?.error,
              status: false
            },
          })
        })
    }
  }

  export const denyRequest = (id, type, reason = "") => {
    return (dispatch) => {
      axios
      .post(`${API_PATHS.ACCESS_PAGE_API}/${id}/${type}`, {
        reason: reason,
      })
        .then((res) => {
            console.log("deny_request ",res);
          dispatch({
            type: DENY_REQUEST,
            payload: {
              data: res.data,
            },
          })
        })
        .catch((err) => {
          dispatch({
            type: CATCH_ERROR,
            payload: {
              data: err.response?.data?.error,
              status: false
            },
          })
        })
    }
  }

export const deleteRequest = (id) => {
    return (dispatch) => {
      axios
        .delete(`${API_PATHS.ACCESS_PAGE_API}/${id}/delete`)
        .then((res) => {
            console.log("delete_request ",res);
          dispatch({
            type: DELETE_REQUEST,
            payload: {
              data: res.data,
              status: false
            },
          })
        })
        .catch((err) => {
          dispatch({
            type: CATCH_ERROR,
            payload: {
              data: err,
              status: false
            },
          })
        })
    }
  }