import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import EditOrCreateApplication from '../MyApps/EditOrCreateApplication/EditOrCreateApplication';
import { connect } from 'react-redux';

import { Modal, Button, Form } from 'react-bootstrap';
import CommonConfiramation from '../../../common/base/CommonConfiramation/CommonConfiramation';
import { useCombined } from  '../../../common/base/CombinedContext';
import './_RequestAccess.scss';
import { useToast } from '../../../common/base/ToastContext';
import { fetchAPIInstance, fetchApplications, getSLATiers, reFetchApplication } from '../../actions/request';
import PopupAlert from '../../../common/Alert/PopupAlert';
import { API_BASE_PATH, commonHeaders } from '../../actions/constants';
import { LENGTH_RANGE, TEXT_PATTERN } from '../../../common/base/constants';


function RequestAccess({data, selected, config, ...props}) {
  const [apiInstance, setApiInstance] = useState('');
  const [application, setApplication] = useState('');
  const [slaTier, setSlaTier] = useState('');
  // const [requestReason, setRequestReason] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [applications, setApplications] = useState(['Create Application'
  ]);
  const [apiInstances, setApiInstances] = useState([]);
  const [apiSelectedInstance , setApiSelectedInstance] = useState(null)
  const [popupConfig, setPopupConfig] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { showLoader, hideLoader } = useCombined();
  const [popupData, setPoupupData] = useState({title: "", message: "", status: "", showAlert: false});

  const [isFormValid, setIsFormValid] = useState(false);
  const selectInstanceRef = useRef(null);
  const selectApplicationRef = useRef(null);
  const selectSlaRef = useRef(null);
  const [requestReason, setRequestReason] = useState({ value: "", invalid: true, error: false, helpText: `0 / ${LENGTH_RANGE.MAX}`, touched: false });

  const accessToken = "eyJraWQiOiJpMnFmaDNzekZVd25KaHdMeW1pMnFTcy01cEhtQzBsTmVfVkUtaEcwMkQ0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULk5oSzFpNkhmdTFKQmJlWngzVUo4djFuS2xOOWppZDVMNktJaC1QdWdfR2siLCJpc3MiOiJodHRwczovL2ludC1pZC5jaXNjby5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNzE3NzY4MDEyLCJleHAiOjE3MTc3NzE2MTIsImNpZCI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCIsInNjcCI6WyJjdXN0b21zY29wZSJdLCJhY2Nlc3NfbGV2ZWwiOjEsInN1YiI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCIsImF6cCI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCJ9.a2GI46D6C-BbQ8nSkokpY9zkyGaH2nJX5fzhJeiC7qhsUBXCrGMFpUjsIc3cQMVnSYkMQ90i5BRse7zpFFmeMQaIzn6dUmlfGkeenamJLE5vA7juYruDwO9ZRxA6noXDNqsj97Da7C9ARgDpaTwih-PUgaf4ckPW8E-x86y1c8ge_MSXOCkegAYBve1M8czy9il1fS1g_3ymGFG8GAomWAoCFOFLVlXnNG5dW2O-MvSiC3mzsUqEZRK7o4mqhMpnWS__HFJoqWa5ddhEzDbgB5fbaEYNk7VRM9UVxSg5QaUk7rNy8G6ap712Z3VZSRLki9-9ata0BRu4VGkc8fS6Uw"
 // let hostURL = "https://it-developer-nprd-dev.cisco.com"//
  let hostURL = location.protocol + '//' + location.host + '/auth';
  let headers = {
      //  'ru':"rvidyasa",
      //  'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
  const [slaTiers, setSlaTiers] = useState([]);
  const { showToast } = useToast();
  
  useEffect(() => {
    if(apiInstance) {
        getApplications();
    }
  }, [apiInstance]);

  useEffect(() => {
    const selectInstance = selectInstanceRef.current;
    const selectApplication = selectApplicationRef.current;
    const selectSla = selectSlaRef.current;
    if (!selectApplication || !selectApplication || !selectSla) {
      return;
    }
    
    if (!showPopup) {
      selectInstance.removeEventListener('hbr-change', handleApiInstanceChange);
      selectApplication.removeEventListener('hbr-change', handleApplicationChange);
      selectSla.removeEventListener('hbr-change', handleSlaTierChange);
    } else {
      selectInstance.addEventListener('hbr-change', handleApiInstanceChange);
      selectApplication.addEventListener('hbr-change', handleApplicationChange);
      selectSla.addEventListener('hbr-change', handleSlaTierChange);
    }

}, [showPopup]);

  useEffect(() => {
    if (application && application !== 'Create Application') {
      getSLATiersList();
    }
  }, [application]);

  function handleApiInstanceChange(e) {
    setApiInstance(e.target.value);
};

  const handleApplicationChange = (e) => {
    setApplication(e.target.value);
    if(e.target.value === 'Create Application') {
      setShowPopup(false);
    }
  };

  const handleSlaTierChange = (e) => {
    setSlaTier(e.target.value);
  };

  const handleRequestReasonChange = (e) => {
    const textArea = e.target;
    let reasonMsgObj = {
      invalid: textArea.invalid ?? true,
      value: textArea.value ?? '',
      touched: requestReason.touched,
    };
    const inputLength = textArea.value?.length ?? 0;
    reasonMsgObj.helpText = `${inputLength} / ${LENGTH_RANGE.MAX}`;
    setRequestReason(reasonMsgObj);
  };

  const checkDisabled = () => {
    
    if (slaTiers.length > 0) {
      if (!requestReason.value || !slaTier || requestReason.invalid) {
        return true;
      }
    } else if (!requestReason.value || !application || requestReason.invalid) {
      return true;
    }

    return false;

  }

  const handleSubmit = (e) => {
    let selectedInstance  = apiInstances.find(x=> x.apiInstanceId === parseInt(apiInstance));
    let seleApp = applications.find(x=> x.id === parseInt(application));
    let seleTier = slaTiers.find(x=> x.slaTierId === parseInt(slaTier));
    let autoApprove = seleTier ? seleTier.autoApprove : true;

    let payload = {
        "acceptedTerms": true,
        "apiInstanceId": apiInstance,
        "instanceType": "api",
        "slaTierId": slaTier ? parseInt(slaTier) : "",
      //  "slaTierName": seleTier.slaTierName,
      //  "slaTierMaximumRequests": seleTier.limits[0].maximumRequests,
      //  "slaTierTimePeriodInMilliseconds": seleTier.limits[0].timePeriodInMilliseconds,
       // "autoApprove": seleTier.autoApprove,
        "version": selectedInstance.apiInstanceVersion,
        "versionGroup": config.versionGroup,
        "apiInstanceName": selectedInstance.apiInstanceName,
        "clientAppName": seleApp.name,
        "domainName": config.site,
        "subdomainName": config.apiGroup,
        "apiName": config.apiTitle,
        "meta": {
          "reason": requestReason.value,
        },
        "assetId": config.assetId,
        "organizationId": config.organizationId,
        "basePath": config.basePath
       // "groupId": config.organizationId,
      }
    createRequestDetail(payload,seleApp.id,autoApprove)
    
  };

  const openPopup = (getInstance = true) => {
    setShowPopup(true);
    setApplication('');
    setRequestReason({ value: "", invalid: true, error: false, helpText: `0 / ${LENGTH_RANGE.MAX}`, touched: false });
    setSlaTier('');
    if(getInstance) {
       setApiInstance('')
       fetchAPIInstances();
    } else {
      getApplications();
    }
  }

  const handleClose = () => setShowPopup(false);

  useEffect(() => {
    setPoupupData({title: "", message: "", status: "", showAlert: false});
  }, [showPopup === false])

  useEffect(() => {
    if (props.errorResponse?.status == false) {
      hideLoader("failed");
      setPoupupData({title: "Request Failed", message: `${props.errorResponse?.data}`, status: "danger", showAlert: true});
    }
  }, [props.errorResponse])

  useEffect(() => {
    if (props.apiInstances.data) {
      hideLoader("fetchAPIInstances");
      setApiInstances(props.apiInstances.data);
    }
  }, [props.apiInstances])

  useEffect(() => {
    if (props.applicationInsatnce.data) {
      hideLoader("getApplications");
      setApplications(props.applicationInsatnce.data.clients);
    }
  }, [props.applicationInsatnce])

  useEffect(() => {
    if (props.slaTiers.data) {
      hideLoader("getSLATiersList");
      setSlaTiers(props.slaTiers.data)
      // setSlaTiers([])
    }
  }, [props.slaTiers])
    

  async function fetchAPIInstances () {
    showLoader("fetchAPIInstances");
    props.dispatch(fetchAPIInstance(config.organizationId, config.assetId, config.versionGroup));
  }

  async function getApplications () {
    showLoader("getApplications");
    props.dispatch(fetchApplications(config.organizationId, apiInstance));
  }

  async function getSLATiersList () {
    showLoader("getSLATiersList");
    props.dispatch(getSLATiers(config.organizationId, config.assetId, config.versionGroup, apiInstance));
  }

  async function createRequestDetail (payload,appId,autoApprove) {
    // showLoader();
    setPoupupData({title: "", message: "", status: "", showAlert: false});
    // props.dispatch((createRequest(config.organizationId, appId, payload)));
    showLoader("createRequestDetail");
    try {
    const response = await window.fetch(`${API_BASE_PATH}/organizations/${config.organizationId}/applications/${appId}/accessRequests`,
      {
        method: 'POST',
        headers: commonHeaders,
        body: JSON.stringify(payload)
      });
      if(response.ok) {
        const data = await response.json();
        showConfirmationModal(autoApprove, data)
      } else {
        const errorData = await response.json();
        setPoupupData({title: "Request Failed", message: `${errorData.error}`, status: "danger", showAlert: true});
      }
      hideLoader("createRequestDetail");
    } catch (error) {
      hideLoader("createRequestDetail");
      setPoupupData({title: "Request Failed", message: `${error.error}`, status: "danger", showAlert: true});
      console.error('Error fetching request:', error);
    }
  }

 function showConfirmationModal(autoApprove, response) {
  setShowPopup(false);
  if(!autoApprove) {
    setPopupConfig({
      header:"Request Sent",
      message:"Your request have sent successfully to the approver. You will get notifications once reviewer approve your request. Visit ‘My Apps’ for More details",
      okButtonLabel:"Close",
      cancelbuttonLabel:"",
      showCancel:false
  })
  } else {
        setPopupConfig({
          header:"Request Approved",
          message:"Your request has approved successfully. Visit ‘My Apps’ for More details. Use below client credentials to Access the API instance.",
          okButtonLabel:"Close",
          cancelbuttonLabel:"",
          showCancel:false,
          data: {
            client_id: response?.clientId,
            client_secret: response?.clientSecret
          }
      })

  }
  setShowConfirmation(true)
 }
 

 function  getslaDetails(tier) {
    if(tier && tier.limits) {
       const keys = Object.keys(tier.limits[0]);
        let limit = tier.limits[0];
       return `${keys[0]}: ${limit[keys[0]]}, ${keys[1]}: ${limit[keys[1]]}`;
    }
  }

  const createApplicationRecord = async (updatedData) => {
    setPoupupData({title: "", message: "", status: "", showAlert: false});
    // props.dispatch((createApplication(config.organizationId, apiInstance, updatedData)));
    
    showLoader("createApplicationRecord");
    try {
        const response = await window.fetch(`${API_BASE_PATH}/organizations/${config.organizationId}/applications?apiInstanceId=${apiInstance}`, {
          method: 'POST',
          body: JSON.stringify(updatedData),
          headers: commonHeaders,
        });
        
        if (response.ok) {
          const data = await response.json();
          openPopup(false);
          props.dispatch((reFetchApplication()));
          setPoupupData({title: "Success", message: "Application created successfully.", status: "success", showAlert: true});
        }
        else {
          const errorData = await response.json();
          setPoupupData({title: "Request Failed", message: `${errorData.error}`, status: "danger", showAlert: true});
        }
        hideLoader("createApplicationRecord");
      } catch (error) {
        hideLoader("createApplicationRecord");
        setPoupupData({title: "Request Failed", message:`${error.error}`, status: "danger", showAlert: true});
        console.error('Error creating application:', error);
      }
    };
  
    
    return (
        <>
        
    {application === 'Create Application' && 
       <EditOrCreateApplication show={application === 'Create Application'} showAlert={popupData.showAlert} status={popupData.status} message={popupData.message} type={'Create'} onSubmit={(updatedData) => createApplicationRecord(updatedData)} 
       title={popupData.title}
       onClose={() => openPopup(false)} />
    }
    {showConfirmation &&(
      <CommonConfiramation show={showConfirmation} popupConfig={popupConfig} onCancel={()=>setShowConfirmation(false)} onSubmit={()=>setShowConfirmation(false)}/>
    )}
    <Modal show={showPopup} centered onHide={handleClose} className='app-modal-popup fixed-width'>
      <Modal.Header className='app-modal-header' closeButton>
        <Modal.Title>Request Access</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-5 pt-4 requestAcces-body'>
        {popupData.showAlert && (
          <PopupAlert status={popupData.status} message={popupData.message} title={popupData.title} />
        )}
        <hbr-form autocomplete="off">
          
          <hbr-select 
          label="API Instance" 
          placeholder="Select API Instance" 
          clearable="false"
          value={apiInstance}
          required
          ref={selectInstanceRef}
          >
            {apiInstances && apiInstances.map((app, index) => (
              <React.Fragment key={index}>
                <hbr-option value={app.apiInstanceId}>{app.apiInstanceName}</hbr-option>
              </React.Fragment>
              
            ))}
          </hbr-select>

          <hbr-select 
          label="Application" 
          placeholder="Select Application" 
          clearable="false"
          disabled={!apiInstance}
          value={application}
          required
          ref={selectApplicationRef}
          >
              {applications.map((app, index) => (
                <React.Fragment key={index}>
                  <hbr-option value={app.id}>{app.name}</hbr-option>
                </React.Fragment>
              ))}
              <React.Fragment>
                <hbr-option value="Create Application">Create Application</hbr-option>
              </React.Fragment>

          </hbr-select>

          <hbr-select 
          label="SLA Tier" 
          placeholder="Select SLA Tier" 
          clearable="false"
          disabled={!application}
          value={slaTier}
          required={slaTiers.length > 0 ? true : false}
          ref={selectSlaRef}
          >
              {slaTiers.map((tier, index) => (
                <React.Fragment key={index}>
                  <hbr-option value={tier.slaTierId}><span style={{fontSize: "16px"}}>{`${tier.slaTierName}`}</span><br /><span>{`(${getslaDetails(tier)})`}</span></hbr-option>
                </React.Fragment>
              ))}

          </hbr-select>

          <hbr-textarea
              pattern={TEXT_PATTERN}
              onInput={handleRequestReasonChange}
              label="Request Reason"
              name="requestReason"
              placeholder="Type your message here"
              value={requestReason.value}
              required
              resize="none"
              maxLength={LENGTH_RANGE.MAX}
              minLength={LENGTH_RANGE.MIN}
              error={requestReason.touched && requestReason.invalid}
              onBlur={() => setRequestReason({ ...requestReason, touched: true })}
          >
            <div slot='label' className='denial_label'>Request Reason</div>
            <div slot='help-text' className='help-text d-flex justify-content-between'>
              <span>
                {requestReason.invalid && requestReason.touched && 'Please provide message with minimum 3 characters'}
              </span>
              <span>
                {requestReason.helpText}
              </span>
            </div>
          </hbr-textarea>

        </hbr-form>
      </Modal.Body>
      <Modal.Footer className='app-modal-footer'>
          <Button variant="secondary" onClick={handleClose}>
              Cancel
          </Button>
          <Button 
          disabled={checkDisabled()}
          variant="primary" 
          onClick={handleSubmit}
          >
              Request
          </Button>
      </Modal.Footer>
    </Modal>
    
    <a  className="hyperlink" onClick={() => openPopup()}>{data.title}</a>
        </>

  );
}

const mapStateToProps = (state) => {
  return {
      loader: state.request.isLoading,
      apiInstances: state.request.apiInstances,
      applicationInsatnce: state.request.applicationInsatnce,
      errorResponse: state.request.errorResponse,
      response: state.request.response,
      slaTiers: state.request.slaTiers
  }
}

export default connect(mapStateToProps)(RequestAccess);