import React from "react";
import { generateConfigData, generateInfoCard, processLink, generateLink } from "Common/utility/infoCard"
import { generateCardsJson } from "Common/utility/serialize"
import { removeBR } from "../../../js/common/utility/utils";


export default class PortalCapabilities extends React.Component {
    render() {
        const { config, location, type } = this.props;

        if (!config) {
            return <span>Loading</span>
        }
        const cards = generateCardsJson(config)
        const data = generateConfigData(config);
        return (
            <>
                <div class="features-container portalCapabilities cisc-befitis section-padding-sm">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <h2 class="main-section-header mt-0 mb-3">{data.title}</h2>

                            {/* <p class="sub-text">{removeBR(data.description)}</p> */}

                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row 
                             justify-content-sm-center justify-content-center justify-content-md-start justify-content-lg-start">
                                {cards.length > 0 &&
                                    cards.map((element, index) => (
                                        <div className="card col m-3" key={index}>
                                            <div className="card-body pt-0">
                                                <div className="text-center">
                                                    <i className={`atm-pictogram ${element.pictoref}`}></i>
                                                    <h5>{element.title}</h5>
                                                    <div className="feature-description">{element.description}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}