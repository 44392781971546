import { useState } from "react";
import axios from "axios";
import { API_BASE_PATH } from "../../actions/constant";

const overViewInitialRes = {
  results: [
    {
      metrics: [
        {
          name: "REQUESTS_VOLUME",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "FAILED_REQUESTS",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "TOTAL_4XX_REQUESTS",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "TOTAL_5XX_REQUESTS",
          tags: {},
          columns: [],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "AVERAGE_RESPONSE_TIME",
          tags: {},
          columns: ["time", "sum_sum"],
          values: [],
        },
      ],
    },
  ],
};

const requestsMetricsInitialData = {
  results: [
    {
      metrics: [
        {
          name: "REQUESTS_BY_METHOD",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "REQUESTS_BY_RESPONSE_CODE",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "REQUESTS_BY_REQUEST_SIZE",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "REQUESTS_BY_RESPONSE_SIZE",
          tags: {},
          columns: [],
          values: [],
        },
      ],
    },
  ],
};

const failuresMetricsInitialData = {
  results: [
    {
      metrics: [
        {
          name: "FAILURES_BY_METHOD",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "FAILURES_BY_RESPONSE_CODE",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "FAILURES_BY_REQUEST_SIZE",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    },
    {
      metrics: [
        {
          name: "FAILURES_BY_RESPONSE_SIZE",
          tags: {},
          columns: [],
          values: [],
        },
      ],
    },
  ],
};

const clientApplicationInitialData = {
  results: [
    {
      metrics: [
        {
          name: "",
          tags: {},
          columns: ["time", "sum"],
          values: [],
        },
      ],
    }
  ]
}

const useApiMetrics = () => {
  const [loading, setLoading] = useState(false);
  const [metricsData, setMetricsData] = useState(null);
  
  const getMetricsData = (requestPayload, reload=false) => {
    if(!requestPayload.organizationId) {
      setMetricsData(null);
      return;
    }
    setLoading(true);
    axios
      .post(`${API_BASE_PATH}/publisher_metrics/api_metrics`, requestPayload)
      .then((res) => {
        if (res.data) {
          setMetricsData(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
        setMetricsData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    metricsData,
    overViewInitialRes,
    requestsMetricsInitialData,
    failuresMetricsInitialData,
    clientApplicationInitialData,
    getMetricsData,
    setMetricsData,
  };
};

export default useApiMetrics;
