//trigger bug scss
import "../sass/pubhub.scss";

import "regenerator-runtime/runtime";
import react from "react"
//import reactDom from 'react-dom'
import dayjs from 'dayjs';

import DocEngine from './docEngine/DocEngine';
import SiteEngine from './siteEngine/SiteEngine';

import "Common/utility/element"
import {getCookies, setCookie, getCookie, deleteCookie, setCiscoCookie} from './common/utility/cookie'
import {smoothScroll, scrollTo} from './common/utility/dom';
import {loadJSFile, loadCSSFile, QueryString} from './common/utility/utils'
import {fetchBlogs/*, getCreatorAvatar*/} from './common/utility/blog'
import {logged, loginRequired, onLoaderReady} from './common/utility/socialLogin'
import {subscribe, checkSubscriptionStatus} from './common/utility/subscription'

import {registerWidget} from "./common/base/WidgetLoader"

import ConfigParser from './common/utility/configParser';
import Element from '../widgets/basic/element';
import wrapWidget from '../widgets/basic/wrapper';
import {registerElement} from './common/utility/customElements'

import {getParsedHash, generateHash, updateHash, replaceHash} from './common/utility/hash'

import Alert from 'react-s-alert';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from "axios";
//import '../../pages/site/page/styles/impt.scss'
const token = 'eyJraWQiOiJpMnFmaDNzekZVd25KaHdMeW1pMnFTcy01cEhtQzBsTmVfVkUtaEcwMkQ0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULjlzeUVyUlNiZnVoR1kwN1NxY0Fzb19jakhLZ3pTaFJPVGVZUXlJU3VabVUiLCJpc3MiOiJodHRwczovL2ludC1pZC5jaXNjby5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNzIxNzEyODMwLCJleHAiOjE3MjE3MTY0MzAsImNpZCI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCIsInNjcCI6WyJjdXN0b21zY29wZSJdLCJhY2Nlc3NfbGV2ZWwiOjEsInN1YiI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCIsImF6cCI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCJ9.EmOAN-zryUVBqS1nZoLKd0e7icIwSiVkrbW_1NMKUYvYsm2V2OzyoAGjWrk-TnjVGPjd7cOoIw26STjt-A0kAmpUV1ag5NrA5sxoftYfFvL5BdQXab6MHz_1CXNSCijh4hruWcTxRrcxI4H4S0p1Yd5KcoQiCra7J8r2BzGYDn4zpyh1zt0mSIEt7LlwifaJ7yIaCIeihrXQtsbA_GoZHyBU667aNakt63KiVypk-oJoYfNX7P8hp7kQ7z6ql-PXeyS10VKHH_ZsgkxMlKVShvNHlF8ar5VYf9SpyZPQw-_9dd-yNJRmWvFbyfFSM-BM_ciuegyw3-0BH5B7rrmpAQ';

const cache = {};

const generateCacheKey = (config) => {
    const paramsString = config.params ? new URLSearchParams(config.params).toString() : '';
    let dataString = '';
    try {
        const data = JSON.parse(config.data);
        dataString = config.data ? Object.keys(data).map(key => data[key]).join('/') : '';
    }catch(e) {
        dataString = config.data ? Object.keys(config.data).map(key => config.data[key]).join('/') : '';
    }
    return `${config.cacheKey}_${config.url}_${paramsString}_${dataString}`;
};

axios.interceptors.request.use(
    function (requestConfig) {
        const cacheKey = generateCacheKey(requestConfig);

        if (requestConfig.cache && cacheKey && cache[cacheKey]) {
            return Promise.reject({ requestConfig, data: cache[cacheKey], fromCache: true });
        }

        if (location.host.includes("local.cisco.com")) {
            requestConfig.headers["Authorization"] = `Bearer ${token}`;
            requestConfig.headers["remote-user"] = 'rvidyasa';
            requestConfig.headers["x-lifecycle"] = "DEV";
            requestConfig.headers["X-Request-Id"] = "123";
        }

       // requestConfig.headers["x-act-as-partner"] = sessionStorage.actAsPartner;
        
        return requestConfig;
    },
    function (error) {
        return Promise.reject(error);
    }
)

axios.interceptors.response.use((response) => {
    const cacheKey = generateCacheKey(response.config);
    
    if (response.config.cache && cacheKey && response.status === 200) {
        cache[cacheKey] = response.data;
    }
    
    return response;
  }, (error) => {
    if (error.fromCache) {
      // Return cached data for failed request due to cache interception
      return Promise.resolve({ ...error, data: error.data });
    }
    return Promise.reject(error);
});

// intercept fetch request to add "x-act-as-partner" request header
// const originalFetch = window.fetch;
// window.fetch = async (url, options = {}) => {
//     const headers = {
//         'x-act-as-partner': sessionStorage.actAsPartner,
//         ...options.headers,
//     };
//     const response = await originalFetch(url, { ...options, headers });
//     return response;
// };

// Hmmmmm
window.Zepto = window.jQuery

class PubHub {
    static env = CONFIG['ENV'] || 'staging';

    static _create(cfg, cls) {
        let instance = new cls();

        //set container first
        if (cfg["container"]) {
            instance.container = cfg["container"];
            delete cfg["container"];
        }

        //copy config and delete that from cfg
        // to set config after set all properties
        var _config;
        if (cfg["config"]) {
            _config = {...cfg["config"]};
            delete cfg["config"]
        }

        // set properties
        for (let i in cfg) {
            instance[i] = cfg[i];
        }


        // set config
        if (_config) {
            for (let i in _config) {
                instance.updateConfig(i, _config[i])
            }
        }

        window.PubHub.instance = instance;

        return instance
    }

    static createDocPage(cfg) {
        try {
            const instance = PubHub._create(cfg, DocEngine);
            window.PubHubDocInstance = instance;
            return instance
        } catch (err) {

        }

    }

    static createSitePage(cfg) {
        try {
            const instance = PubHub._create(cfg, SiteEngine);
            window.PubHubSiteInstance = instance;
            return instance

        } catch (err) {

        }

    }

    static get APIHost() {
        return PubHub.env == "production" ? "https://devnet.cisco.com" : "https://devnet-testing.cisco.com";
    }

    static get communityAPIHost() {
        return "https://communities.devnetcloud.com";
        //return location.host.indexOf("cisco.com") !== -1 ? "https://communities.devnetcloud.com" : "https://communities.testing.devnetcloud.com";
    }

    static get DevNetCreationAPI() {
        return "https://devnetapps.cisco.com/devnetcreation-data";
    }

    static get APIProxy() {
        return PubHub.env !== "production" ? "https://pubhub.cisco.com/app/project/proxy/?url=" : "";
    }

    static get smartsheetHost(){
        return PubHub.env !== "production" ? "https://d2o5w3xyvxcs9z.cloudfront.net" : "https://dcl3wxcscqm28.cloudfront.net";
    }

    static get SDKURL() {
        try {
            var sdk_js = document.querySelector("[src*='pubhub.js']");
            if (sdk_js) {
                var url = sdk_js.getAttribute("src").split("?")[0];
                return url.substr(0, url.length - 9);
            }

        } catch (e) {

        }
    }

}

const cookie = {
    getCookies: getCookies,
    setCookie: setCookie,
    getCookie: getCookie,
    deleteCookie: deleteCookie,
    setCiscoCookie: setCiscoCookie
};

function registerLocalWidgets(widgets, prefix = 'phex-') {
    for (let selector in widgets) {
        registerElement(selector, widgets[selector], prefix);
    }
}

PubHub.util = {
    smoothScroll: smoothScroll,
    scrollTo: scrollTo,
    cookie: cookie,
    loadJSFile: loadJSFile,
    loadCSSFile: loadCSSFile,
    fetchBlogs,
    // fetchBlogAuthorAvatar: getCreatorAvatar,
    dayjs: dayjs,
    QueryString,
    socialLogin: {
        logged: logged,
        loginRequired: loginRequired,
        onLoaderReady: onLoaderReady
    },
    localWidget: {
        configParser: ConfigParser,
        element: Element,
        wrapWidget: wrapWidget,
        registerWidgets: registerLocalWidgets
    },
    hash: {
        get: getParsedHash,
        generate: generateHash,
        update: updateHash,
        replace: replaceHash
    },
    subscription: {
        subscribe: subscribe,
        check: checkSubscriptionStatus
    },
    alert: Alert,
    React:react,
    //ReactDOM:reactDom
};


PubHub.registerWidget = registerWidget;

window.PubHub = PubHub;

//legecy， delete
window.PubHubCookie = cookie;

// redirect "https://devnet.cisco.com" to "https://developer.cisco.com"
(function () {

    // Check grant type Implicit and redirect to the path
    if (sessionStorage.getItem("current_path") && sessionStorage.getItem("grant_type") == "implicit") {
        const url = window.location.href;
        let paramString = url.split('#')[1];
        const params = new URLSearchParams(paramString);

        if (params.has("access_token") && params.has("token_type")) {
            sessionStorage.setItem("token", params.get("access_token"));
            sessionStorage.setItem("token_type", params.get("token_type"));
            sessionStorage.setItem("expires_in", params.get("expires_in"));
            sessionStorage.setItem('rightPanel', true);
            window.location.href = sessionStorage.getItem("current_path");
        }

        if (params.has("error") && params.has("error_description")) {
            sessionStorage.setItem("error", params.get("error"));
            sessionStorage.setItem("error_description", params.get("error_description"));
            sessionStorage.setItem('rightPanel', true);
            window.location.href = sessionStorage.getItem("current_path");
        }

    }
    
    if(sessionStorage.getItem('current_path') && sessionStorage.getItem('grant_type') === 'authorizationCode') {
        const urlObj = new URL(window.location.href);
        const params = new URLSearchParams(urlObj.search);
        if (params.has("code")) {
            sessionStorage.setItem("authorizationCode", params.get("code"));
            sessionStorage.setItem('rightPanel', true);
            window.location.href = sessionStorage.getItem("current_path");
        }
    }

    if (window.location.origin === "https://devnet.cisco.com") {
        window.location.href = window.location.href.replace("devnet.", "developer.");
    }
})();
