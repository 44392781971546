/**
 * Created by aaikepae on 11/3/16.
 */

import {parseURL, parseImageURL} from "./link";
import dayjs from "dayjs";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { headerData } from "../../siteEngine/components/APImetrics/SectionHeader/SectionHeader";


//http://stackoverflow.com/questions/384286/javascript-isdom-how-do-you-check-if-a-javascript-object-is-a-dom-object
export function isElement(obj) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrom)
    return obj instanceof HTMLElement;
  }
  catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have. (works on IE7)
    return (typeof obj === "object") &&
      (obj.nodeType === 1) && (typeof obj.style === "object") &&
      (typeof obj.ownerDocument === "object");
  }
}

export function generateLink(text) {
  var string = text.replace(/[\r\n]/g, '-').replace(/[^\w\s]/gi, '').replace(/\s+/g, "-").replace(/(-+)$/, "").replace(/^(-+)/, "").replace(/--./, "-").toLowerCase();
  return string


}

export function includeCodeSection(text) {
  return text.match(/<pre class="?.+"><code/);
}


export function updateRelatedLink(wrapper, location, rootlocation) {


  //update href to absolute url
  Array.from(wrapper.querySelectorAll("[href]")).forEach(function (link) {
    const href = parseURL(link.getAttribute("href"), location, rootlocation);
    if (href) {
      link["href"] = href;
      if (href.startsWith("http") || href.startsWith("//")) {
        link["target"] = '_blank';
      }
    }

  });

  //update src to absolute url
  Array.from(wrapper.querySelectorAll("iframe[src]")).forEach(function (link) {
    link.setAttribute("src", parseImageURL(link.getAttribute("src"), location, rootlocation) + "#" + window.location.origin + window.location.pathname);
  });

  //update src to absolute url
  Array.from(wrapper.querySelectorAll("[src]:not(iframe)")).forEach(function (link) {
    link.setAttribute("src", parseImageURL(link.getAttribute("src"), location, rootlocation) + "#" + document.domain);
  });

  //add attribute to internal link
  Array.from(wrapper.querySelectorAll("a[href^='#']")).forEach(function (link) {
    //data-tag="navigation-item" data-url={data.url}
    link.setAttribute('data-tag', 'navigation-item');
    link.setAttribute('data-url', link.getAttribute("href").substr(1));
  });

  if (PubHubDocInstance && PubHubDocInstance.router.mode === 'history') {
    Array.from(wrapper.querySelectorAll("a[href^='#!']")).forEach(function (link) {
      const href = link.getAttribute("href");
      link.href = PubHubDocInstance.router.baseURL + href.substring(2).replace('/', '/#');
    });
  }

  // // update html related html tag
  Array.from(wrapper.querySelectorAll("a[href$='.html']")).forEach(function (link) {
    if(link.href.startsWith("http")){
      return
    }
    // "https://pubhub.cisco.com/media/test-webflow-project/eb57e5d0e212994a1248d570d0ac31046bbc6085/about.html => about/
    link.href = link.href.slice(link.href.lastIndexOf("/") + 1 ,".html".length * -1) + "/";
    link.target = "_parent";
  });


  return wrapper;
}


export function loadJSFiles(wrapper, location, callback) {

  //js
  const scripts = Array.prototype.slice.apply(wrapper.querySelectorAll('script[src]'));
  let urls = scripts.map(function (script, index) {
    return script.getAttribute("src");
  });

  let promises = urls.map(function (url, index) {  // create promise for loading files
    return loadJSFile(parseURL(url, location));
  });
  // insert html after all js files loaded
  Promise.all(promises).then(function () {
    console && console.log("loaded");
    callback.call(null);
  }, function () {
    console && console.log("error during loading js files");
    callback.call(null);
  });
}

export function loadJSFile(url, config = {}) {
  return new Promise(function (resolve, reject) {

    let _url = url;

    // cache the vendor js
    if(_url.startsWith("http") && document.querySelector("[src*='"+_url+"']")){
      resolve(this);
      return
    }

    try {
      if (!_url.startsWith("http") && PubHub.instance.location) {
        _url = parseURL(_url, PubHub.instance.location, PubHub.instance.location);
      }
    } catch (e) {

    }

    const script = document.createElement('script');
    script.src = _url;
    script.async = !!config["async"];  // async loading

    if (config["defer"]) {
      script.defer = !!config["defer"];
    }
    script.onload = function () {
      resolve(this);
    };
    script.onerror = function () {
      reject(this);
    };
    document.body.appendChild(script);
  });
}


export function loadCSSFile(url, config = {}) {
  return new Promise(function (resolve, reject) {
    const link = document.createElement('link');
    let _url = url;

    try {
      if (!_url.startsWith("http") && PubHub.instance.location) {
        _url = PubHub.instance.location + _url;
      }
    } catch (e) {

    }


    link.href = _url;
    link.type = "text/css";
    link.rel = "stylesheet";
    link.media = "screen,print";

    link.onload = function () {
      resolve(this);
    };
    link.onerror = function () {
      reject(this);
    };
    document.head.appendChild(link);
  });
}

export function removeVideoAutoPlay(wrapper) {
  Array.from(wrapper.querySelectorAll("video")).forEach(function (video) {
    video.removeAttribute("autoplay");
  });
  return wrapper;
}

export function removeElements(wrapper, selector) {
  Array.from(wrapper.querySelectorAll(selector)).forEach(function (el) {
    el.parentNode.removeChild(el);
  });
  return wrapper;

}


export function executeHTMLScript(wrapper) {
  const scripts = wrapper.querySelectorAll("script");
  Array.from(scripts).forEach(function (script) {
    eval(script.text);
  })
}

export function getHtml(el, selector) {
  if (!el) {
    return '';
  }

  if (!selector) {
    return el.innerHTML.trim();
  }

  let child = el.querySelector(selector);
  return child ? child.innerHTML.trim() : '';
}

export function getText(el, selector) {
  if (!el) {
    return '';
  }

  if (!selector) {
    return el.textContent.trim();
  }

  let child = el.querySelector(selector);
  return child ? child.textContent.trim() : '';
}

export const QueryString = function() {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    var query_string = {};
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
            var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        } else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
}();

export const getPropTypeLabel = (propType) => {
  const grantTypes = {
    'authorization_code': 'Authorization Code Grant',
    'client_credentials': 'Client Credential Grant',
    'resource_owner_credentials': 'Resource Owner Grant',
    'implicit': 'Implicit Grant',
    'refresh_token': 'Refresh Token',
    'password': 'Password'
  };
  return grantTypes[propType] || '';
}

export const debounce = (callback, delay = 1000) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const getActAsPartnerAccess = (key) => {
  const HIDE_FOR_ACT_AS_PARTNER = ['my_apps', 'request-access', 'request'];
  const actAsPartner = JSON.parse(sessionStorage.actAsPartner || false);
  return actAsPartner && HIDE_FOR_ACT_AS_PARTNER.includes(key);
}

export const removeBR = (content) => {
  return content && content.trim().replace('<br />', ''); 
}

const transformChartTableData = (data) => {
  var transformedData = [];

  data.results.forEach((result) => {
    let resultData = {
      headers: [],
      data: []
    };

    let allTags = {};
    
    result.metrics.forEach((metric) => {
      if (metric.values.length > 0) {
        if (metric.tags.method) {
          allTags[metric.tags.method] = true;
        }
        if (metric.tags.status_code) {
          allTags[metric.tags.status_code] = true;
        }
        if (metric.tags.api_version_id) {
          allTags[metric.tags.api_version_id] = true;
        }
        if (metric.tags.client_id) {
          allTags[metric.tags.client_id] = true;
        }
      }
    });
    
    if (Object.keys(allTags).length === 0) {
      resultData.headers = ['Date', 'Value'];
    } else {
      resultData.headers = ['Date', ...Object.keys(allTags)];
    }
    
    let timeMap = {};
    let title = '';
    result.metrics.forEach((metric) => {
      if (metric.values.length > 0) {
        metric.values.forEach(([time, sum]) => {
          const formattedDate = new Date(time).toISOString().slice(0, 10);

          if (!timeMap[formattedDate]) {
            timeMap[formattedDate] = {};
          }
          
          if (metric.tags.method) {
            timeMap[formattedDate][metric.tags.method] = sum;
          }
          if (metric.tags.status_code) {
            timeMap[formattedDate][metric.tags.status_code] = sum;
          }
          if (metric.tags.api_version_id) {
            timeMap[formattedDate][metric.tags.api_version_id] = sum;
          }
          if(metric.tags.client_id) {
            timeMap[formattedDate][metric.tags.client_id] = sum;
          }
          
          if (Object.keys(metric.tags).length === 0) {
            timeMap[formattedDate]['Value'] = sum;
          }
        });
        title = headerData[metric.name]?.title; 
      }
    });

    resultData.title = title;
    
    Object.keys(timeMap).forEach((date) => {
      let row = new Array(resultData.headers.length).fill(0);
      row[0] = date;
      
      if (Object.keys(allTags).length === 0) {
        row[1] = timeMap[date]['Value'] || 0;
      } else {
        Object.keys(allTags).forEach((tag) => {
          const tagIndex = resultData.headers.indexOf(tag);
          if (timeMap[date][tag] !== undefined) {
            row[tagIndex] = timeMap[date][tag];
          }
        });
      }

      resultData.data.push(row);
    });
    
    if (resultData.headers.length > 0 && resultData.data.length > 0) {
      transformedData.push(resultData);
    }
  });
  
  transformedData.forEach((resultData) => {
    resultData.data.sort((a, b) => new Date(b[0]) - new Date(a[0]));
  });

  return transformedData;
};


const transformDataTable = (chartTabData) => {
  let headData = [];
  chartTabData.forEach(data => {
    let labels = data.datasets.map(item => item.label)
    let obj = {
      title: data.name,
      headers: ['Date'].concat(labels),
      data: []
    }
    data.labels.forEach((val, key) => {
      let row = [val];
      labels.forEach((labl, ind) => {
        row.push(data.datasets[ind].data[key])
      });
      obj.data.push(row);
    })
    headData.push(obj)
  })
  return headData;
};

export const generateMetricsPDF = async (charts, tabName, selectedIds, selectedDate, apiResponse, tabType = "") => {
  const doc = new jsPDF();
  const startPostY = 20;
  const headerHeight = 10;
  /* doc.addFileToVFS('https://www.cisco.com/c/dam/cdc/ui/atm/1-0-0/fonts/CiscoSansTTBold.woff2', 'base64-encoded-string-of-the-font');
  doc.addFont('https://www.cisco.com/c/dam/cdc/ui/atm/1-0-0/fonts/CiscoSansTTBold.woff2', 'ciscoSans', 'bold'); */
  const logo_canvas = await html2canvas(document.querySelector('.header-logo'));
  const logoData = logo_canvas.toDataURL('image/png');
  doc.addImage(logoData, 'PNG', 10, 2, 30, 15);

  doc.setFillColor(41, 128, 185);
  doc.rect(0, startPostY, doc.internal.pageSize.width, headerHeight, 'F');
  doc.setFontSize(16);
  doc.setTextColor(19, 57, 103);
  doc.setFont('helvetica', 'bold');
  doc.text('Publisher Metrics', doc.internal.pageSize.width-33, 13, { align: 'center' });
  doc.setTextColor(255, 255, 255);
  doc.setFont('helvetica', 'normal');
  doc.text(tabName, doc.internal.pageSize.width / 2, 27, { align: 'center' });

  const boxTop = startPostY+20;
  const boxHeight = 56;
  const boxWidth = 190;
  const labelX = 15;
  const valueX = 70;
  const margin = 8.5;

  doc.setTextColor(49, 50, 51);
  doc.setFontSize(14);
  doc.text('API Information', 15, boxTop+7);
  doc.setDrawColor(200, 200, 200);
  doc.setLineWidth(0.1);
  doc.line(10, boxTop+10, 200, boxTop+10);

  
  doc.rect(10, boxTop, boxWidth, boxHeight);
  
  doc.setFontSize(12);
  
  doc.setTextColor(82, 81, 101);

  doc.text(`Business Group:`, labelX, boxTop + (margin*2));
  doc.text(`${selectedIds.orgName}`, valueX, boxTop + (margin*2), { align: 'left' });

  doc.text(`API Name:`, labelX, boxTop + (margin*3));
  doc.text(`${selectedIds.apiName}`, valueX, boxTop + (margin*3), { align: 'left' });

  doc.text(`Version/Instance:`, labelX, boxTop + (margin*4));
  doc.text(`${selectedIds.insName}/${selectedIds.insLabel}`, valueX, boxTop + (margin*4), { align: 'left' });

  const startDate = dayjs(selectedDate.startDate).format("DD/MM/YYYY");
  const endDate = dayjs(selectedDate.endDate).format("DD/MM/YYYY");

  doc.text(`Start Date:`, labelX, boxTop + (margin*5));
  doc.text(startDate, valueX, boxTop + (margin*5), { align: 'left' });

  doc.text(`End Date:`, labelX, boxTop + (margin*6));
  doc.text(endDate, valueX, boxTop + (margin*6), { align: 'left' });

  const msg = 'No data is available for the following metrics';
  const metricsName = charts.chartData.filter(item => !item.labels?.length).map(item => headerData[item.datasets[0].name]?.title);
  const msgPos = boxTop + boxHeight + 10;
  
  if(metricsName.length) {
    doc.setTextColor(255, 0, 0);
    doc.text('*', 10, msgPos);
    doc.setTextColor(49, 50, 51);
    doc.text(msg, 12, msgPos);
    doc.setFontSize(10);
    doc.setTextColor(82, 81, 101);
    doc.text(metricsName.join(', '), 12, msgPos+5);
  }

  let chartPosY = boxTop + boxHeight + 10;
  let tableData = [];
 
  if(tabType == 'REQUESTS' || tabType == 'FAILURES') {
    tableData = transformDataTable(charts.chartData);
  } else {
    tableData = transformChartTableData(apiResponse);
  }


  const refs = charts.chartRefs.filter((item, i) => charts.chartData[i].labels.length > 0);
  const dataSets = charts.chartData.filter(item => item.labels.length > 0);

  refs.forEach((chartRef, index) => {
    if (chartRef && chartRef.current && dataSets && dataSets[index]) {
      const chartData = dataSets[index];
      chartPosY = .5;

      const chartImage = chartRef.current.toBase64Image();

      doc.addPage();

        // Yannick don't want chart from first page itself
      /* if(index > 0) {
        doc.addPage();
        chartPosY = .5;
      }else {
        chartPosY = boxTop + boxHeight + 10;
      } */
      
      doc.setFillColor(41, 128, 185);
      doc.rect(0, chartPosY, doc.internal.pageSize.width, headerHeight, 'F');
      doc.setTextColor(255, 255, 255);
      doc.text(`${headerData[chartData.datasets[0].name]?.title}`, doc.internal.pageSize.width / 2, chartPosY+6.5, { align: 'center' });
      if(chartImage.length > 10) {
        doc.addImage(chartImage, 'PNG', 13, chartPosY+headerHeight+5, 180, 60);
      }

      /* const tableData = chartData.labels.map((label, index) => [
        label,
        ...chartData.datasets.map((dataset) => dataset.data[index]),
      ]); */

      

      const tableHeaders = tableData[index].headers;

      doc.autoTable({
        startY: chartPosY+headerHeight + 75,
        head: [tableHeaders],
        body: tableData[index].data,
      });
    }
  });

  if(charts.onlyTable) {
    tableData.forEach(item => {
      doc.addPage();
      doc.setFillColor(41, 128, 185);
      doc.rect(0, 1, doc.internal.pageSize.width, headerHeight, 'F');
      doc.setTextColor(255, 255, 255);
      doc.text(item.title, doc.internal.pageSize.width / 2, headerHeight-3, { align: 'center' });
      doc.autoTable({
        startY: headerHeight+10,
        head: [item.headers],
        body: item.data,
      });
    })
  }

  const fileName = `${tabName}-${selectedIds.orgName}-${selectedIds.apiName}-${selectedIds.insName}-${new Date().getTime()}`;
  // to save directly
  // doc.save(fileName);

  const pdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl);
};


export const consolidateMetrics = (data) => {
  const results = {};
  data.results.forEach(result => {
      if(!results[result.metrics[0].name]) {
        results[result.metrics[0].name] = result;
      }else {
        result.metrics.forEach(metric => {
          if(metric.values.length > 0) {
            results[result.metrics[0].name].metrics.push(metric);
          }
        })
      }
  });
  return {results: Object.values(results)};
}

export const getDate = (date, value) => {
  let newDate = date;
  try{
    const d = new Date(date);
    d.setDate(d.getDate() + value);
    newDate = d;
  }catch{
    console.log('date error');
  }
  return newDate.toISOString();
}
