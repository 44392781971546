import React, { Component } from "react";
import "./_sidebarContentViewer.scss";

class SidebarContentViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedContent: null,
      selectedMenu: null,
      expandedMenus: [],
      expandAll: false,
      defaultSummary: null,
      data: [],
      contentImage: ""
    };
  }

  async getLink() {
    const menuData = [];
    const contentImageSrc = this.props.config.querySelector('contentImageSrc')?.textContent.trim();
    const el = this.props.config.querySelector("SidebarContentViewer > nav");
    const defaultSummary = this.props.config.querySelector(
      "SidebarContentViewer > summary"
    )?.innerHTML;
    if (el) {
      const menu = el.querySelectorAll("nav-ul");
      menu.forEach((item, i) => {
        const label = item.dataset.label;
        const id = `menu-${i}`;
        const subMenu = item.querySelectorAll("nav-li");
        const submenus = [];
        subMenu.forEach((sub, j) => {
          const summary = sub.querySelector("summary");
          submenus.push({
            id: `submenu-${i}-${j}`,
            label: sub.querySelector('label')?.innerText,
            content: {
              id: `submenu-${i}-${j}-content`,
              title: sub.querySelector('title')?.textContent.trim(),
              text: summary?.innerHTML,
            },
          });
        });
        menuData.push({
          id,
          label,
          submenus,
        });
      });
      this.setState({ data: menuData, defaultSummary,contentImage: contentImageSrc });
    }
  }

  componentDidMount() {
    this.getLink();
  }

  handleSelection = (contentId, menuId) => {
    this.setState({ selectedContent: contentId, selectedMenu: menuId });
  };

  handleMenuClick = (menuId) => {
    this.setState((prevState) => {
      const { expandedMenus } = prevState;
      let newExpandedMenus;
      if (expandedMenus.includes(menuId)) {
        newExpandedMenus = expandedMenus.filter((id) => id !== menuId);
      } else {
        newExpandedMenus = [...expandedMenus, menuId];
      }

      const expandAll = newExpandedMenus.length === prevState.data.length;

      return { expandedMenus: newExpandedMenus, expandAll };
    });
  };

  toggleExpandAll = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const expandAll = !prevState.expandAll;
      const expandedMenus = expandAll
        ? this.state.data.map((menu) => menu.id)
        : [];
      return { expandAll, expandedMenus };
    });
  };

  render() {
    const {
      selectedContent,
      expandedMenus,
      data,
      expandAll,
      selectedMenu,
      defaultSummary,
      contentImage
    } = this.state;
    return (
      <div className={`g-started-screen ${!selectedContent ? 'home' : ''}`}>
        {/* <div class="container">
	<div class="title">
		<h1>GHOST</h1>
	</div>
	<div class="ghost">
		
  <i class="atm-pictogram product-design-release"/>
	</div>
</div> */}
        <div className="menu">
          <>
            <div className="expand-collapse-controls">
              <a onClick={(e) => this.toggleExpandAll(e)}>
                {expandAll ? "Collapse All" : "Expand All"}
              </a>
            </div>
            {data.map((menu) => (
              <div
                key={menu.id}
                className="menu-item"
              >
                <label onClick={() => this.handleMenuClick(menu.id)}>
                  <i
                    className={`atm-icon ${expandedMenus.includes(menu.id)
                        ? "chevron-down"
                        : "chevron-right"
                      } expand-icon`}
                  ></i>
                  {menu.label}
                </label>
                <ul
                  className={`submenu ${expandedMenus.includes(menu.id) ? "show" : ""
                    }`}
                >
                  {menu.submenus.map((submenu) => (
                    <li
                      key={submenu.id}
                      onClick={() =>
                        this.handleSelection(submenu.content.id, submenu.id)
                      }
                      className={selectedMenu === submenu.id ? "selected" : ""}
                    >
                      <label className="submenu-item">
                        <i className="atm-icon file-text-o expand-icon"></i>{" "}
                        {submenu.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </>
        </div>

        <div className="g-started-content">
          {data
            .flatMap((menu) => menu.submenus)
            .map((submenu) => (
              <React.Fragment key={submenu.content.id}>
                {selectedContent === submenu.content.id ? (
                  <div
                    id={submenu.content.id}
                    className={"content-section"}
                  >
                    <h3>{submenu.content.title}</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: submenu.content.text }}
                    />
                  </div>
                ) : null}
              </React.Fragment>
            ))}

          {(!selectedContent && defaultSummary) && (
            <div
              className="content-section"
              dangerouslySetInnerHTML={{ __html: defaultSummary }}
            />
          )}

          {!selectedContent && (
            <div className="text-center header-content">
              <h2 className="header mb-5">

                Getting Started !
                {/* <span class="pico">

                  <i class="atm-pictogram product-design-release" />
                </span> <span class="">

                  <i class="atm-pictogram product-design-release" />
                </span> */}
                

              </h2>
              <p className="sub-head mb-5">
                This guide will help you navigate through the portal and get started with using Cisco APIs effectively.
              </p>
              <img src={contentImage} />

            </div>)}

        </div>
      </div>
    );
  }
}

export default SidebarContentViewer;
