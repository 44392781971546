import React, { useState, useEffect } from 'react';

function IconChevron(props) {
    return (
        <> 
           {props.isopen ? (<i class="atm-icon chevron-down"/>) : (<i class="atm-icon chevron-right"/>
            ) }
        </>
    )
}

export default IconChevron;