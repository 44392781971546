import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./_overview.scss";
import SectionHeader from "../SectionHeader/SectionHeader";
import CommonChart from "../CommonChart";
import { COLOR_PALETTE } from "../../../../common/base/constants";
import { generateMetricsPDF, getDate } from "../../../../common/utility/utils";

const Overview = forwardRef(({ apiResponse, selectedDate, loading }, ref) => {
  const [transformedData, setTransformedData] = useState({});
  const isMounted = useRef(false);
  const chartRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    isMounted.current = true;

    if (apiResponse) {
      const data = transformDataByStatusCode(apiResponse);
      if (isMounted.current) {
        setTransformedData(data);
      }
    }

    return () => {
      isMounted.current = false;
    };
  }, [apiResponse]);

  const generatePDF = (selectedIds, selectedDate) => {
    const charts = {
      chartRefs: chartRefs.map(ref => ref.current.getRef()),
      chartData: transformedData.datasets.map((dataSet, i) => ({
        labels: transformedData.dates[i],
        datasets: [dataSet],
      }))
    }
    generateMetricsPDF(charts, 'Overview Report', selectedIds, selectedDate, apiResponse);
  }

  useImperativeHandle(ref, () => ({
    generatePDF: (selectedIds, selectedDate) => {
      generatePDF(selectedIds, selectedDate);
    }
  }))

  const getOptions = (dataSet) => {
    return {
      scales: {
        x: {
          beginAtZero: true,
          type: 'time',
          /*time: {
              unit: 'day',
              tooltipFormat: 'll',
          }, */
          min: getDate(selectedDate.startDate, - 1),
          max: getDate(selectedDate.endDate, 1),
          ticks: {
            maxRotation: 45,
            minRotation: 45,
            autoSkip: true,
            maxTicksLimit: 45,
            callback: function(value) {
              var date = new Date(value);
              var day = String(date.getDate()).padStart(2, '0');
              var month = String(date.getMonth() + 1).padStart(2, '0');
              var year = date.getFullYear();
              return `${year}/${month}/${day}`;
            }
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: dataSet.data.length === 0 ? 1 : undefined,
            callback: function (value) {
              return dataSet.name === "AVERAGE_RESPONSE_TIME"
                ? value * 1000 + " ms"
                : value;
            },
          },
          min: dataSet.data.length === 0 ? 0 : undefined,
          max: dataSet.data.length === 0 ? 1 : undefined,
        },
      },
    };
  };

  const transformDataByStatusCode = (data) => {
    const dates = [];
    const datasets = data.results.map((result) => {
      const versionId = result.metrics[0].tags.api_version_id;

      const dataPoints = result.metrics[0].values.map(([timestamp, count]) => ({
        date: new Date(timestamp).toISOString().slice(0, 10),
        count: count || 0,
      }));
      
      dates.push(dataPoints.map((dp) => dp.date));
      const transformedData = dataPoints.map((dp) => dp.count);

      return {
        name: result.metrics[0].name,
        label: versionId,
        data: transformedData,
        borderColor: COLOR_PALETTE[0],
        backgroundColor: COLOR_PALETTE[0],
        fill: false,
        tension: 0.1,
        borderWidth: 2,
        pointRadius: transformedData.length > 1 ? 0 : 3,
        expand: true,
        columns: result.metrics[0].columns,
      };
    });

    return { datasets, dates };
  };

  return (
    <div className="overview-container">
      {transformedData.datasets?.map((dataSet, i) => (
        <div
          key={`${dataSet.name}-${i}`}
          className={`chart chart-${i + 1}`}
        >
          <CommonChart
            ref={chartRefs[i]}
            expand={true}
            type="line"
            chartData={{
              labels: transformedData.dates[i],
              datasets: [dataSet],
            }}
            title={<SectionHeader type={dataSet.name} />}
            legend={false}
            styles={{ width: "100%", height: "350px" }}
            options={getOptions(dataSet)}
            loading={loading}
          />
        </div>
      ))}
    </div>
  );
});

export default React.memo(Overview);
