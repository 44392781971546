import React, { useState, useEffect, useRef } from "react";
import "./_APImetrics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { addDays } from "date-fns";
import { useCombined } from "../../../common/base/CombinedContext";
import ClientApp from "./ClientApplictaion/ClientApp";
import MetricsFilter from "./MetricsFilter";
import RequestsFailures from "./RequestsFailures/RequestsFailures";
import useApiMetrics from "./useApiMetrics";
import { Chart, registerables, CategoryScale } from "chart.js";
import 'chartjs-adapter-date-fns';
import crosshairPlugin from "chartjs-plugin-crosshair";
import Overview from "./Overview/Overview";

Chart.register(...registerables, crosshairPlugin, CategoryScale);

const initialIds = {
  orgId: "",
  orgName: "",
  apiId: "",
  apiName: "",
  selectedApi: {},
  insId: "",
  insName: "",
  insLabel: "",
};

const initialDates = {
  startDate: addDays(new Date(), 0),
  endDate: addDays(new Date(), -1),
  key: "selection",
};

const metricTabs = ["overview", "requests", "failures", "client_applications"];

function APImetrics() {
  const {
    loading,
    getMetricsData,
    setMetricsData,
    metricsData,
    overViewInitialRes,
    requestsMetricsInitialData,
    failuresMetricsInitialData,
    clientApplicationInitialData,
  } = useApiMetrics();
  const [selectedDate, setSelectedDate] = useState(initialDates);
  const [selectedIds, setSelectedIds] = useState(initialIds);
  const { setBreadcrumbItems } = useCombined();
  const [activeTab, setActiveTab] = useState("overview");
  const overviewRef = useRef(null);
  const requestFailureRef = useRef(null);
  const clientAppRef = useRef(null);
  const breadcrumbItems = [
    {
      title: "Cisco IT APIs",
      url: "/",
    },
    {
      title: "API Metrics",
      url: "",
    },
  ];

  useEffect(() => {
    setBreadcrumbItems(breadcrumbItems);
  }, [setBreadcrumbItems]);

  useEffect(() => {
    reloadData();
  }, [selectedDate, selectedIds, activeTab]);

  const callMetricsApi = (type, selectedDate, selectedIds, reload = false) => {
    const dateVal = {
      startDate: selectedDate.startDate ?? initialDates.startDate,
      endDate: selectedDate.endDate ?? initialDates.endDate
    } 
    const metricsvalue = setMetricsDateTime(dateVal);
    const startDate = metricsvalue['startDate'];
    const endDate =  metricsvalue['endDate'];
    getMetricsData(
      {
        metricsKey: type,
        organizationId: selectedIds.orgId,
        apiId: selectedIds.apiId,
        apiInstanceId: selectedIds.insId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        startDate,
        endDate,
      },
      reload
    );
  };

  const reloadData = (reload = false) => {
    switch (activeTab) {
      case "overview":
      case "requests":
      case "failures":
        callMetricsApi(
          `${activeTab.toUpperCase()}_METRICS`,
          selectedDate,
          selectedIds,
          reload
        );
        break;
      case "client_applications":
        callMetricsApi(`CLIENTS_METRICS`, selectedDate, selectedIds, reload);
        break;
    }
  };

  const handleReset = (selectedDate) => {
    setSelectedIds(initialIds);
    setSelectedDate(selectedDate);
    switch (activeTab) {
      case "overview":
      case "requests":
      case "failures":
        callMetricsApi(
          `${activeTab.toUpperCase()}_METRICS`,
          selectedDate,
          initialIds
        );
        break;
      case "client_applications":
        callMetricsApi(
          `${activeTab.toUpperCase()}_METRICS`,
          selectedDate,
          initialIds
        );
        break;
    }
  };

  const downloadPDF = () => {
    let ref = overviewRef.current;
    switch (activeTab) {
      case "overview":
        ref = overviewRef.current;
        break;
      case "requests":
      case "failures":
        ref = requestFailureRef.current;
        break;
      case "client_applications":
        ref = clientAppRef.current;
        break;
    }
    ref.generatePDF(selectedIds, selectedDate);
  };

  const setMetricsDateTime = (date) => {
    let setStartDate =  new Date(date.startDate);
    setStartDate.setUTCHours(0, 0, 0, 0);
    let setEndDate = new Date(date.endDate)
    setEndDate.setUTCDate(setEndDate.getUTCDate() + 1)
    setEndDate.setUTCHours(23, 59, 59,0);

    let revisedDate = {
      startDate: setStartDate.getTime(),
      endDate: setEndDate.getTime()
    }
    return revisedDate;
  }

  return (
    <div
      id="pubhub-widgets"
      className="pubhub-site-container"
    >
      <div className="mt-4 mx-5 metrics-container">
        <h4 className="text-primary mb-4">API Metrics Dashboard</h4>
        <hbr-banner
          closable="false"
          icon="info-circle"
          sentiment="info"
          open="true"
        >
          <i
            slot="icon"
            className="atm-icon info-circle"
          ></i>
          You can only view metrics for APIs that have Active Contracts
        </hbr-banner>
        <MetricsFilter
          onDateChange={(date) => setSelectedDate(date)}
          onOrgChange={(ids) => setSelectedIds(ids)}
          onRefresh={() => reloadData(true)}
          onReset={handleReset}
        />
        <div className="tab-container mt-1">
          <hbr-tab-group>
            {metricTabs.map((tab, indx) => (
              <React.Fragment key={tab}>
                <hbr-tab
                  panel={tab}
                  slot="nav"
                  onClick={() => setActiveTab(tab)}
                >
                  <p className="text-capitalize mb-2">
                    {tab
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                  </p>
                </hbr-tab>
              </React.Fragment>
            ))}
            <hbr-tab-panel
              role="tabpanel"
              name="overview"
            >
              <section className="atm-p-8 tab-sections row">
                {activeTab === "overview" && (
                  <Overview
                    ref={overviewRef}
                    selectedDate={selectedDate}
                    apiResponse={!loading && metricsData ? metricsData : overViewInitialRes}
                    loading={loading}
                  />
                )}
              </section>
            </hbr-tab-panel>
            <hbr-tab-panel
              role="tabpanel"
              name="requests"
            >
              <section className="atm-p-8 tab-sections row">
                {activeTab === "requests" && (
                  <RequestsFailures
                    ref={requestFailureRef}
                    selectedDate={selectedDate}
                    type="REQUESTS"
                    apiResponse={!loading && metricsData ? metricsData : requestsMetricsInitialData}
                    loading={loading}
                  />
                )}
              </section>
            </hbr-tab-panel>
            <hbr-tab-panel
              role="tabpanel"
              name="failures"
            >
              <section className="atm-p-8 tab-sections row">
                {activeTab === "failures" && (
                  <RequestsFailures
                    ref={requestFailureRef}
                    selectedDate={selectedDate}
                    apiResponse={!loading && metricsData ? metricsData : failuresMetricsInitialData}
                    loading={loading}
                    type="FAILURES"
                  />
                )}
              </section>
            </hbr-tab-panel>
            <hbr-tab-panel
              role="tabpanel"
              name="client_applications"
            >
              <section className="atm-p-8 tab-sections row">
                {activeTab === "client_applications" && (
                  <ClientApp
                    ref={clientAppRef}
                    apiResponse={metricsData || clientApplicationInitialData}
                    loading={loading}
                  />
                )}
              </section>
            </hbr-tab-panel>
          </hbr-tab-group>
          <div className="download-pdf">
            {/* disabled={!!metricsData} */}
            <hbr-button
              variant="primary"
              size="medium"
              onClick={downloadPDF}
            >
              <i
                slot="prefix"
                className="atm-icon download"
              />
             Download Report as PDF
            </hbr-button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(APImetrics);
