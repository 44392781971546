import React from 'react';

const NotificationToast = ({ show, onClose, bg, header, message, duration = 5000, closable = "true", title, link, linkTitle }) => {
  return (
    <>
      <hbr-banner class="atm-toast--default" duration={duration} closable={closable} sentiment={bg} open={show} style={{ zIndex: 1000 }}>
        {title && <span class="atm-toast--title">{title}</span>}
        {typeof message === 'string' ? message : JSON.stringify(message)}
        {bg === "success" && <i slot="icon" className="atm-icon status-check-outline"></i>}
        {bg === "danger" && <i slot="icon" className="atm-icon status-exclamation-circle-outline"></i>}
        {link && 
        <a href={link} target="_blank">
          {linkTitle}
          <i class="atm-icon direction-right-arrow"></i>
        </a>
        }
      </hbr-banner>
    </>
  );
};

export default NotificationToast;