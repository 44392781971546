/**
 * Created by aaikepae on 11/3/16.
 */


export function clearData() {
  return function (dispatch, getState) {
    dispatch({
      type:"CLEAR"
    });
  };
}

export function fetchData(data) {
  return function (dispatch, getState) {
    dispatch({
      type: "FETCH_ITEMS",
      payload: data["items"]
    });
    dispatch({
      type: "FETCH_META",
      payload: data["meta"]
    });

    dispatch({
      type: "FETCH_CONFIG",
      payload: data["config"]
    });
  };
}


export function navigateTo(url) {
  return function (dispatch, getState) {
    const state = getState();

    dispatch({
      type: "CLEAR_SEARCH"
    });

    //split url to item url and section url
    let _url = url.split(PubHub.instance.router.mode === 'history' ? '#' : '/');
    // find sequence of that url
    let sequence = state.itemsObj[_url[0]];
    const selectedItem = sequence ? getItem(state.items, sequence) : null;
    const validSelectedItem = selectedItem && (
      selectedItem.type === "doc_page"|| selectedItem.content) ? selectedItem : null;
    
    if (validSelectedItem) {
      dispatch({
        type: "SELECT_ITEM",
        payload: {
          url: _url[0],
          section: _url[1],
          item: selectedItem,
          sequence: sequence
        }
      });
    } else {
      const item = getFirstItemWithType(state.items);
      dispatch({
        type: "SELECT_ITEM",
        payload: {
          url: item["url"],
          section: null,
          item: item,
          sequence: item["sequence"]
        }
      });
    }
  }
}

export function loadFirstContent() {
  return function (dispatch, getState) {
    const state = getState();
    const item = getFirstItemWithType(state.items);
    dispatch({
      type: "SELECT_ITEM",
      payload: {
        url: item["url"],
        section: null,
        item: item,
        sequence: item["sequence"]
      }
    });
  }
}


function getItem(items, sequence) {
  var _items = items.slice();
  var sequenceAry = sequence.split(".");
  for (let i = 0; i < sequenceAry.length - 1; i++) {
    _items = _items[sequenceAry[i]]["items"];
  }
  // todo check
  return _items[sequenceAry[sequenceAry.length - 1]];
}

function getFirstItemWithType(items) {
  var item = null;
  for (var i = 0; i < items.length; i++) {
    const _item = items[i];
    if (_item["type"] && (_item["type"] != "oas") && (_item["type"] != "oas3")) { // a better(risky) way is: if (_item["routable"])
      item = _item;
      break;
    } else if (_item["items"]) {
      const subItem = getFirstItemWithType(_item["items"]);
      if (subItem) {
        item = subItem;
        break;
      }

    }
  }

  return item;
}

