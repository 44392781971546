export const URL_PATTERN = /^http(s?):\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const TEXT_PATTERN = /^[a-zA-Z0-9-_\s]+$/;
export const LENGTH_RANGE = {
    MIN: 3,
    MAX: 255
};
export const MIN_TEXTAREA_LENGTH = 16;
export const MAX_ELLIPSIS_LENGTH = 200;

export const COLOR_PALETTE = [
    '#89b97a', //green
    '#00b5d1', //blue
    '#ff506d', //red
    '#FBAB2C', //orange
    '#606162', //grey
    '#B0D8FB', //light blue
    'brown',
    'pink',
    'cyan',
    'magenta',
    'teal',
    'lavender',
    'maroon',
    'olive',
    'navy',
    'aquamarine',
    'turquoise',
    'silver',
    'lime',
    'gold',
    'goldenrod',
    'skyblue',
    'coral',
]

export const PIECOLOR = [
    "#1990fa",
    "#399e20",
    "#ff716b",
    "#e89613",
    '#a9da8e',
    "#ffc365",
    "#79befb",
    "#b3b3b3"
]