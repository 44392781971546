export const bearerToken = 'eyJraWQiOiJpMnFmaDNzekZVd25KaHdMeW1pMnFTcy01cEhtQzBsTmVfVkUtaEcwMkQ0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULjlzeUVyUlNiZnVoR1kwN1NxY0Fzb19jakhLZ3pTaFJPVGVZUXlJU3VabVUiLCJpc3MiOiJodHRwczovL2ludC1pZC5jaXNjby5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNzIxNzEyODMwLCJleHAiOjE3MjE3MTY0MzAsImNpZCI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCIsInNjcCI6WyJjdXN0b21zY29wZSJdLCJhY2Nlc3NfbGV2ZWwiOjEsInN1YiI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCIsImF6cCI6InBvcnRhbC1tZXRhcHVibGlzaGVyLWNsaWVudCJ9.EmOAN-zryUVBqS1nZoLKd0e7icIwSiVkrbW_1NMKUYvYsm2V2OzyoAGjWrk-TnjVGPjd7cOoIw26STjt-A0kAmpUV1ag5NrA5sxoftYfFvL5BdQXab6MHz_1CXNSCijh4hruWcTxRrcxI4H4S0p1Yd5KcoQiCra7J8r2BzGYDn4zpyh1zt0mSIEt7LlwifaJ7yIaCIeihrXQtsbA_GoZHyBU667aNakt63KiVypk-oJoYfNX7P8hp7kQ7z6ql-PXeyS10VKHH_ZsgkxMlKVShvNHlF8ar5VYf9SpyZPQw-_9dd-yNJRmWvFbyfFSM-BM_ciuegyw3-0BH5B7rrmpAQ';
export const commonHeaders = location.host.includes("local.cisco.com") ? {
        'remote-user':"rvidyasa",
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
        'x-lifecycle': "DEV",
        'X-Request-Id': "123"
    } : {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }

export const API_BASE_PATH = location.host.includes("local.cisco.com") ? "http://localhost:9002/clientaccessmanager" : `${location.protocol}//${location.host}/auth/clientaccessmanager`;

export const API_PATHS = {
    REQUEST_PAGE_API: `${API_BASE_PATH}`
}