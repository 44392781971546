import initialState from '../store/initialState';

import {
    SET_LOADER,
    APPROVE_REQUEST,
    DELETE_REQUEST,
    DENY_REQUEST,
    FETCH_ACCESS_REQUESTS,
    FETCH_PENDING_REQUESTS,
    CATCH_ERROR,
    RESET_ACTION
} from '../actions/type';
import { approveRequest } from '../actions';

const accessReducer = (state = initialState.access,action) => {
    switch(action.type) {
        case SET_LOADER:
            return { ...state, isLoading: action.payload }
        case APPROVE_REQUEST:
            return { ...state, response: action.payload }
        case DENY_REQUEST:
            return { ...state, response: action.payload }
        case DELETE_REQUEST:
            return { ...state, response: action.payload }
        case FETCH_ACCESS_REQUESTS:
            return { ...state, accessRequestList: action.payload }
        case FETCH_PENDING_REQUESTS:
            return { ...state, pendingRequestList: action.payload }
        case CATCH_ERROR:
            return { ...state, errorResponse: action.payload }
        case RESET_ACTION:
            return { ...state, errorResponse: [], response: [] }
        default: return state;
    }
}

export default accessReducer;