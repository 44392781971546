import React from "react";
require("./_index.scss");

const Select = React.lazy(() => import(/* webpackChunkName: "react-select" */ 'react-select'));

async function fetchPubhubVersionList() {
    try {
        const path = window.location.pathname;
        const response = await window.fetch('/pubhub_version_list', {
            headers: {
                'X-Path': path
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch pubhub_version_list');
        }
        const data = await response.json();
        window["PUBHUB_VERSION_LIST"] = data;
        console.log('Data saved to window["PUBHUB_VERSION_LIST"]: ', data);
        return data;
    } catch (error) {
        console.error('Error fetching pubhub_version_list:', error);
        throw error;
    }
}
export default class extends React.Component {
    async componentDidMount() {
        try {
            await fetchPubhubVersionList();
            this.forceUpdate();
        } catch (error) {
            console.error('Failed to fetch pubhub_version_list:', error);
        }
    }
    handleChange = (selectedOption) => {

        if (selectedOption.url) {
            window.location.href = selectedOption.url
            return;
        }

        const id = selectedOption.value;
        const route = Object.values(PUBHUB_PATH_LIST).find(i => i.id == id)
        if (route) {
            window.location.href = route.url
        }

    };
    render() {
        //fetchPubhubVersionList();
        
        if (!window["PUBHUB_VERSION_LIST"]) {
            return null
        }

        const PUBHUB_VERSION_LIST = window["PUBHUB_VERSION_LIST"];

        const { meta, router } = this.props;

        const metaId = getId(meta);
        
        const group = Object.values(PUBHUB_VERSION_LIST).find((group) => {
            return Object.values(group).find((subgroup) => {
                return subgroup.id == metaId
            })
        })

        if (!group) {
            return null
        }

        const groupList = Object.values(group);
        const options = groupList.map((item) => {
            return {
                value: item.id,
                label: item.label,
                url:   item.url
            }
        });

        options.sort((a, b) => b.label.localeCompare(a.label, undefined, { numeric: true }));

        const latestOption = this.getLatestOption(groupList);
        if (latestOption) {
            options.splice(0, 0, latestOption);
        }

        const selectedOption = this.getSelectedOption(options, meta, latestOption, router);

        return (
            <div className={"pubhub-nav-vp-container"} id={"pubhub-nav-vp"}>
                <React.Suspense fallback={<div>Loading...</div>}>
                    <Select
                        styles={{ valueContainer: (style) => ({ ...style, padding: '0 14px' })}}
                        value={selectedOption}
                        options={options}
                        classNamePrefix={"pubhub-nav-vp"}
                        onChange={this.handleChange}
                    />
                </React.Suspense>
            </div>

        )
    }

    getSelectedOption(options, meta, latestOption, router) {
        const pathname = router.mode === 'history' ? router.baseURL : window.location.pathname;
        if (latestOption && pathname == latestOption.url) {
            return latestOption;
        }

        const metaId = getId(meta);
        return options.find(item => item.value == metaId);
    }

    getLatestOption(groupList) {
        const latestVersion = groupList.find(item => {
            return item.is_latest_version && item.canonical;
        });

        if (!latestVersion) return null;

        const url = this.getCanonicalUrl(latestVersion);

        // latest version
        return {
            value: "Latest",
            label: "Latest",
            url: url
        };
    }

    getCanonicalUrl(item) {
        return (item.canonical && !item.canonical.endsWith("/")) ? (item.canonical + "/") : item.canonical;
    }
}

function getId(meta) {
    return meta.id || meta.project_id;
}
