import React,{useEffect} from "react"
import { connect } from "react-redux"

import DocContainer from "./DocContainer"
import Nav from "./nav/Nav"
import Content from "./content/Content"
import CodePanel from "./content/CodePanel"

import DocHeader from "./DocHeader";
import Breadcrumb from "../../common/base/BreadCrumb/BreadCrum"
import { CombinedProvider, useCombined }from "../../common/base/CombinedContext";
import Loader from "../../common/base/Loader/Loader"
import { ToastProvider } from "../../common/base/ToastContext"
import Header from "../../common/base/Header/Header"
import Footer from "../../common/base/Footer/Footer"

export class Doc extends React.Component {
    onStartLoadOrphenLink = (url) => {
        this.props.dispatch({
            type: 'FETCH_STATIC_ORPHEN_LINK',
        })

        window.fetch(url)
            .then((e) => e.text())
            .then(data => {
                this.props.dispatch({
                    type: 'FETCH_STATIC_ORPHEN_LINK_FULFILLED',
                    payload: { 
                      url: url,
                      data: massageOrphanHtml(url, data)
                    },
                })
          }) 
    }
    onOrphenLinkGoback = () => {
        this.props.dispatch({
            type: 'ORPHEN_LINK_GOBACK',
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selected.data && this.props.selected.data && prevProps.selected.data !== this.props.selected.data) {
            try {
                let _container = window.PubHubDocInstance.container;

                const _event = new CustomEvent("load", {
                    detail: {
                        data: {
                            ...this.props,
                            content: this.props.selected.data,
                            container: this.refs.doc.getWrappedInstance().refs["root"]
                        }
                    },
                    bubbles: true,
                    cancelable: true
                });

                _container.dispatchEvent(_event);
            } catch (e) {  }
        }
    }

    render() {
        const { items, config, meta } = this.props;

        if (!items || !meta || !config) {
            return <div className="overlay">
                <div className="loading-img"></div>
            </div>;
        }
        return (
          <CombinedProvider>
            <ToastProvider>
            <APIPageLoader />
                <Header></Header>
                <APIBreadcrumb config={config}/>
              <DocContainer {...this.props} ref="root">
                  {config.useBreadcrumb && <Breadcrumb config={config} meta={meta}/>}
                  <Nav {...this.props} selected={{...this.props.selected}} />
                  <CodePanel />
                  <Content {...this.props} selected={{...this.props.selected}} 
                    onStartLoadOrphenLink={this.onStartLoadOrphenLink} 
                    onOrphenLinkGoback={this.onOrphenLinkGoback}
                    ref="doc" />
              </DocContainer>
              <div className="mt-3"></div>
              <Footer/>
              </ToastProvider>
        </CombinedProvider>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        items: state.items,
        meta: state.meta,
        selected: state.selected,
        config: state.config,
        search: state.search
    };
}

function massageOrphanHtml(htmlUrl, htmlContent) {
  const pattern = /<img\s+[^>]*src=["']([^"']+)["']/g;
  function replaceSrc(match, src) {
    if (src.startsWith("http://") || src.startsWith("https://") || src.startsWith("/")) {
      return match;
    }
    return match.replace(src, resolveUrl(src, htmlUrl));
  }

  return (htmlContent || '').replace(pattern, replaceSrc);
}

function resolveUrl(relativePath, baseUrl) {
  const isSupportURL = window.URL && (typeof window.URL === 'function');
  if (isSupportURL) {
    return new URL(relativePath, baseUrl).href
  }

  const urlParts = baseUrl.split('/');

  urlParts.pop(); // remove the html file name
  const parts = relativePath.split('/');

  for (let i = 0; i < parts.length; i++) {
    if (parts[i] === "..") {
      urlParts.pop();
    } else if (parts[i] !== ".") {
      urlParts.push(parts[i]);
    }
  }

  return urlParts.join('/');
}

const APIPageLoader = () => {
  const { loadingSet } = useCombined();
  return loadingSet.size > 0 && <Loader />;
};

const APIBreadcrumb = ({config}) => {
  const { setBreadcrumbItems } = useCombined();

  const getBreadCrumbUrl =(urls) => {
    if(!urls || !urls[0]) {return false}
     let retUrl = urls.join('/').trim()
    return `/${retUrl.toLowerCase().replace(/ /g, '_')}/`
  }
  let windowLocationPath = window.location.pathname;
  windowLocationPath = windowLocationPath.replace(/^\/|\/$/g, '');
  let urlArr = windowLocationPath.split('/');

  const authIndex = urlArr.indexOf('auth');
  if(authIndex > -1) {
    urlArr = urlArr.slice(authIndex+1);
  }
  useEffect(() => {
    let breadcrumbItems = [
      { title : "Cisco IT APIs",
        url: "/"
      },
      { title : config.site,
        url: getBreadCrumbUrl([urlArr[0]])
      }
    ];

    if(config.apiTitle) {
      breadcrumbItems.push(
        { title : config.apiGroup,
          url: getBreadCrumbUrl([urlArr[0],urlArr[1]])
        }
      )
      breadcrumbItems.push(
        { title : config.apiTitle,
          url: ""
        }
      )
    }

    setBreadcrumbItems(breadcrumbItems);
  }, [setBreadcrumbItems,config]);
  return <Breadcrumb />;
};

export default connect(mapStateToProps, null, null, { withRef: true })(Doc);
