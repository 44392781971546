import React from "react"
import {Provider} from "react-redux"
import Doc from "./components/Doc"
import {createStoreInstance} from "./store/store"
import RoutableContainer from "../common/base/RoutableContainer"
//action
import {fetchData, navigateTo, loadFirstContent, clearData} from "./actions/data"
import {updateLocation, updateConfig} from "./actions/config"
import {loadsearch} from "./actions/content"

const createReactClass = require('create-react-class');

const Wrapper = createReactClass({
  render() {
    let store = this.store = createStoreInstance();
    return <Provider store={store}>
      <Doc ref="component" {...this.props}/>
    </Provider>
  }
});


export default class DocEngine extends RoutableContainer {
  /**
   *@property selectedItem
   */

  get selectedItem() {
    if (this.component) {
      return this.component.props.selected.item;
    }
  }


  /**
   * @constructor
   */
  constructor() {
    super();
    this.componentClass = Wrapper;
  }


  componentDidMount() {

    this.store = this.wrapper.store;
  }


  configDataDidLoad(data) {

    if (!data["items"]) {
      console.error("Wrong config file");
      return false
    }
    //
    this.component.props.dispatch(fetchData(data));

    //update location in store
    if (data["meta"] && (data["meta"]["doc_location"] || data["meta"]["location"])) {
      this._location = (data["meta"]["doc_location"] || data["meta"]["location"])
    }
    this.component.props.dispatch(updateLocation(this._location));


    //firstTime
    if (this.defaultURI) {
      this.router.navigateTo(this.defaultURI)
    } else if (this.router.getQueryString()) {
      this.router.queryString = this.router.getQueryString();
    } else {
      //Special case
      //load first content without hash change
      this.component.props.dispatch(loadFirstContent());
    }
  }

  navigateTo(value) {
    if (!value || value === "") {
      this.component.props.dispatch(loadFirstContent());
    }
    this.url = value;
    this.navigationDidChange(value);
  }

  configDataGetError() {
    console && console.error("URL path of location or config file is not correct")
  }

  navigationDidChange(url) {
    try {
      // display search
      if (url && url.match(/^search[\/|\?]/)) {
        this.component.props.dispatch(loadsearch(url));
      } else if (url) {
        this.component.props.dispatch(navigateTo(url.replace(/\?[^\#]*/, '')));
      }
      else if (!this.component.props.selected.item || url === "" || url === "/") {
        this.component.props.dispatch(loadFirstContent());
      }
    } catch (e) {
      console && console.error(e);
    }
  }

  updateConfig(key, value) {
    if (this.component) {
      this.component.props.dispatch(updateConfig({key, value}));
    }
  }

  clear() {
    super.clear();
    if (this.component) {
      this.component.props.dispatch(clearData());
    }
  }

}



