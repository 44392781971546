import React from "react";
import { generateConfigData, generateInfoCard, processLink, generateLink } from "Common/utility/infoCard"
import { generateCardsJson } from "Common/utility/serialize";
import { removeBR } from "../../../js/common/utility/utils";

export default class PortalCapabilities extends React.Component {
    render() {
        const { config, location, type } = this.props;

        if (!config) {
            return <span>Loading</span>
        }
        const cards = generateCardsJson(config)
        const data = generateConfigData(config);
      
        return (
            <>
                <div class="community-section section-padding-sm">
                    <div className="row">
                        <div className="col-md-12 col-lg-8 col-sm-12">
                            <h2 class="main-section-header mt-0 mb-3 text-left">{data.title}</h2>
                            <p className="text-left mb-4">{removeBR(data.description)}</p>
                            <div className="cards-container">
                                {cards.length > 0 &&
                                    cards.map((element, index) => (
                                        <div className="card" key={index}>
                                            <h4 className="bold">{element.title}</h4>
                                            <p>{element.description}</p>
                                            <a href={element.pageLink} target="_blank">
                                                Learn More <i className="atm-pictogram general-wayfinding-arrow-up-right"></i>
                                            </a>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 text-center align-content-center justify-content-center d-none d-lg-block">
                            <img className="illustration-img img-fluid" src={data.image.src} alt="illustration" />
                        </div>
                    </div>
                </div>
                
               
            </>
        )
    }
}