import React, { createContext, useState, useContext } from 'react';
import NotificationToast from './Toast/Toast';
import { ToastContainer } from 'react-bootstrap';
const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const showToast = (toast) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Math.floor(Math.random() * 10000000), ...toast },
    ]);
  };

  const hideToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}

      <div class="hbr-toast-stack">        
      {toasts.map((toast) => (
          <NotificationToast
            key={toast.id}
            show={true}
            bg={toast.bg}
            onClose={() => hideToast(toast.id)}
            header={toast.header}
            message={toast.message}
            duration={toast.duration}
            closable={toast.closable}
            title={toast.title}
            link={toast.link}
            linkTitle={toast.linkTitle}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);