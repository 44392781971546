import React from "react";
import axios from "axios";

export default class Setting extends React.Component {

    state = {
        loaded: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selected.data && this.props.selected.data && prevProps.selected.data !== this.props.selected.data) {
            if (this.props.config["include_header"] && !this.state.loaded) {
                const header_html_fragment_url = this.props.config.location + "header.html";
                axios.get(header_html_fragment_url)
                    .then((res) => {
                        document.getElementById("devnet-doc-header").innerHTML = res.data;
                    }).catch(() => {
                })
            }
        }
    }


    render() {
        const {items, selected, config, meta} = this.props;

        if (!items || !meta) {
            return null
        }

        return (<div id="devnet-doc-header"></div>)

    }
}
