import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import "bootstrap-icons/font/bootstrap-icons.css";
import './MyApps.scss';
import TablePaginationNew from '../../../common/Pagination/TablePaginationNew';
import EditOrCreateApplication from './EditOrCreateApplication/EditOrCreateApplication';
import Loader from '../../../common/base/Loader/Loader';
import { setLoader, fetchApplicationsList, updateApplicationDetail, resetSecretApplication, deleteApplicationDetail } from '../../actions';
import {default as copyText} from '../../../common/utility/copyText';
import { useToast } from '../../../common/base/ToastContext';
import { TEXT_PATTERN } from '../../../common/base/constants';
import {API_BASE_PATH, commonHeaders} from '../../actions/constants';
import CopyTexttoClipBrd from '../../../common/base/CopyTexttoClipBrd/CopyTexttoClipBrd';
import { getPropTypeLabel } from '../../../common/utility/utils';
import RequestStatusBadge from '../../../common/base/RequestStatusBadge/RequestStatusBadge';
import CommonConfiramation from '../../../common/base/CommonConfiramation/CommonConfiramation';
import Modal from 'react-bootstrap/Modal';
import { useCombined } from '../../../common/base/CombinedContext';


const MyApps = ({ selected, config, items, ...props }) => {
    const [applications, setApplications] = useState([]);
    const [error, setError] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);
    const [hiddenStates, setHiddenStates] = useState([]);
    const [showCopyStates, setShowCopyStates] = useState([]);
    const [showCopy, setShowCopy] = useState({client_id: true, client_secret: true});
    const [showCopyStatesSecret, setShowCopyStatesSecret] = useState([]);
    const [hideText, setHideText] = useState(true);
    const [dropdownVisible, setDropdownVisible] = useState([]);
    const [showModal, setShowModal] = useState({ edit: false, reset: false, apis: false, delete: false, resetConfirmation: false, deleteConfirmation: false });
    const grantTypes = ['Authorization Code Grant', 'Client Credential Grant', 'Resource Owner Grant', 'Implicit Grant', 'Refresh Token'];
    const [currentApplication, setCurrentApplication] = useState(null);
    const [appIdForModal, setAppIdForModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [popupData, setPoupupData] = useState({title: "", message: "", status: "", showAlert: false});
    const [formValues, setFormValues] = useState({
        clientAppName: '',
        clientAppDescription: '',
        applicationURL: '',
        grantTypes: [],
        redirectUris: ['']
    });
    const [tableConfig, setTableConfig]= useState({
        pageSize: 10,
        totalPages: 0,
        totalRecords:0,
        currentPage:1,
        pageLimit: [10, 15, 20]
      });
    const [searchTerm, setSearchTerm] = useState('');
    const apiGrantTypesToFormValues = {
        'authorization_code': 'Authorization Code Grant',
        'client_credentials': 'Client Credential Grant',
        'resource_owner_credentials': 'Resource Owner Grant',
        'implicit': 'Implicit Grant',
        'refresh_token': 'Refresh Token'
    };
    const otherGateways = [
        {
            title: "Mulesoft",
            ref: "mulesoft",
            img: "mulesoft-logo.png",
            url: "https://anypoint.mulesoft.com/exchange/applications/"
        },
        {
            title: "Cisco API Console",
            ref: "cisco-api-console",
            img: "mashery-logo.png",
            url: "https://apiconsole.cisco.com/apps/myapps"
        }
    ]
    const dropdownRefs = useRef([]);

    const breadcrumbItems = [{
        title:"Cisco IT APIs",
        url:"/"
      },
      {
        title:"My Apps",
        url:""
      }]

    const { setBreadcrumbItems } = useCombined();
    useEffect(() => {
      if(!config) setBreadcrumbItems(breadcrumbItems);
    }, [setBreadcrumbItems]);

    const handleClose = (modal) => {
        setShowModal(prev => ({ ...prev, [modal]: false }))
        if (modal === 'edit') {
            setPoupupData({title: "", message: "", status: "", showAlert: false});
        }
    };
    
    const handleShowModal = async (modal, appId) => {
        setShowModal(prev => ({ ...prev, [modal]: true }))
        setAppIdForModal(appId);
        const selectedApp = applications.find(app => app.id === appId);
        setCurrentApplication(selectedApp);
        if (modal === 'edit') {
            setPoupupData({title: "", message: "", status: "", showAlert: false});
            setFormValues({
                clientAppName: selectedApp?.name || '',
                clientAppDescription: selectedApp?.description || '',
                applicationURL: selectedApp?.url || '',
                grantTypes: selectedApp?.grantTypes ? selectedApp.grantTypes.map(apiGrantType => apiGrantTypesToFormValues[apiGrantType] || apiGrantType) : [],
                redirectUris: selectedApp?.redirectUri || ''
            });
            //getApplication(appId);
        }
    };

    const { showToast } = useToast();

    useEffect(() => {
        fetchApplications();
    }, [props.refetchApplication]);

    useEffect(() => {
    console.log("props.errorResponse == ",props.errorResponse);
        if (props.errorResponse?.status == false) {
            setError(props.errorResponse?.data);
            showToast({
                title: 'Operation Failed',
                bg: 'danger',
                message: props.errorResponse?.data,
            });
        }
    }, [props.errorResponse, props.response])

    useEffect(() => {
        if (props.applicationList.data) {
            let data = props.applicationList.data;
            setApplications(data.clients);
            // setExpandedRow(null);
            setHiddenStates(Array(data.clients.length).fill(true));
            setShowCopyStates(Array(data.clients.length).fill(true));
            setShowCopyStatesSecret(Array(data.clients.length).fill(true));
            setDropdownVisible(Array(data.clients.length).fill(false));
            setTableConfig({
                ...tableConfig,
                totalPages: data.totalPages,
                totalRecords: data.totalRecords
            });
            setLoading(false);
            setError(null);
        }
    
      }, [props.applicationList])

      useEffect(() => {
        if (props.deleteResponse.data) {
            fetchApplications();
            setLoading(false);
            handleClose('delete');
            showToast({
                title: 'Operation Successful',
                bg: 'success',
                message: 'Application deleted successfully!',
            });
        }

      }, [props.deleteResponse])

      function showResetConfirmation(data) {
        setLoading(false);
        if (data) {
            setCurrentApplication(prevValue => ({...prevValue, clientSecret: data.clientSecret}));
            handleClose('reset');
            setShowModal(prev => ({ ...prev, resetConfirmation: true }));
        }
      }
      
      

    const fetchApplications = async () => {
        setLoading(true);
        const organizationId = config?.organizationId || null;
        const assetId = config?.assetId || null;
        const versionGroup = config?.versionGroup || null;
        props.dispatch(fetchApplicationsList(organizationId, tableConfig.currentPage, tableConfig.pageSize, searchTerm, assetId, versionGroup));
        
    };

    const updateApplication = async (appId, updatedData) => {
        setPoupupData({title: "", message: "", status: "", showAlert: false});
        const addOrganizationId = config?.organizationId ? `/organizations/${config?.organizationId}` : '';
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_PATH}${addOrganizationId}/applications/${appId}`, {
                method: 'PATCH',
                body: JSON.stringify(updatedData),
                headers: commonHeaders,
            });

            const contentType = response.headers.get('Content-Type');
            const isJson = contentType?.includes('application/json');
            if (!response.ok) {
                setLoading(false);
                const errorData = isJson ? await response.json() : await response.text();
                const errorMessage = errorData.error || 'Failed to update application';
                throw new Error(errorMessage);
            } else {
                const data = await response.json();       
                await fetchApplications();
                setLoading(false);
                handleClose('edit');
                showToast({
                    title: 'Operation Successful',
                    bg: 'success',
                    message: 'Application updated successfully!',
                });
                //updateExpendedRow();
                return data;
            }
        } catch (error) {
            setError(error.message);
            setPoupupData({title: "Request Failed", message: `${error.message}`, status: "danger", showAlert: true});
        }
    };

    const mapFormValuesToApiGrantTypes = (data) => {
        return {
            ...data,
            grantTypes: data.grantTypes.map(apiGrantType => apiGrantTypesToFormValues[apiGrantType] || apiGrantType),
        };
    }

    const resetSecret = async (appId) => {
        setLoading(true);
        const organizationId = config?.organizationId || null;
        props.dispatch(resetSecretApplication(organizationId, appId, showResetConfirmation));
    };

    const deleteApplication = async (appId) => {
        setLoading(true);
        const organizationId = config?.organizationId || null;
        props.dispatch(deleteApplicationDetail(organizationId, appId));
    };

    const toggleRow = async (index, appId) => {
        if (expandedRow === index) {
            setExpandedRow(null);
        } else {
            setExpandedRow(index);
            setCurrentApplication(applications[index]);
            //getApplication(appId);
        }
    };

    const toggleClass = (index) => {
        setHiddenStates(prevHiddenStates => {
            const newHiddenStates = [...prevHiddenStates];
            newHiddenStates[index] = !newHiddenStates[index];
            return newHiddenStates;
        });
    };

    const toggleDropdown = (index) => {
        setDropdownVisible(prevVisibility => {
            const newVisibility = [...prevVisibility];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
    const handleSearch = () => {
        let dumConfig = tableConfig;
        dumConfig.currentPage = 1;
        setTableConfig(dumConfig);
        fetchApplications();
    };

    const handleSearchTermChange = (e) => {
        const { value } = e.target;
        if (TEXT_PATTERN.test(value) || value === '') {
            setSearchTerm(value);
        }
    };
    

    // Handle page change
    const handlePageChange = (pageNumber) => {
        let dumConfig = tableConfig;
        dumConfig.currentPage = pageNumber;
        setTableConfig(dumConfig);
        fetchApplications();
    };

    // Handle rows per page change
    const handleRowsPerPageChange = (newRowsPerPage) => {
        let dumConfig = tableConfig;
        dumConfig.currentPage = 1;
        dumConfig.pageSize = newRowsPerPage;
        setTableConfig(dumConfig);
        fetchApplications();
    };

    const modals = {
        reset: {
            title: 'Reset Client Secret',
            body: 'Are you sure you want to reset the selected client secret?',
            footerButtons: {
                cancel: { text: 'Close', variant: 'secondary', onClick: () => handleClose('reset') },
                ok: { text: 'Yes, reset secret', variant: 'primary', onClick: () => resetSecret(appIdForModal) }
            }
        },
        delete: {
            title: 'Delete Application',
            body: 'Are you sure you want to delete this application?',
            footerButtons: {
                cancel:{ text: 'Close', variant: 'secondary', onClick: () => handleClose('delete') },
                ok: { text: 'Yes, Delete', variant: 'danger', onClick: () => { deleteApplication(appIdForModal) } }
            }
        },
        resetConfirmation: {
            title: 'Successfully Generated Client Secret',
            body: "",
            footerButtons: {
                ok: { text: 'Close', variant: 'primary', onClick: () => handleClose('resetConfirmation') }
            },
            data: {
                client_id: currentApplication?.clientId,
                client_secret: currentApplication?.clientSecret
            }
        },
        apis: {
            title: 'Associated APIs and status',
            body: (
                <table className="table mt-2 mb-5 associated-table iap-table">
                    <thead>
                        <tr>
                            <th>Api Name</th>
                            <th>API Instance</th>
                            <th>SLA Tier</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentApplication?.apis?.map((api, index) => (
                            <tr key={index}>
                                <td>
                                    <hbr-tooltip size="small" content={api.apiName} trigger="hover" placement="top" class="atm-tooltip--small">
                                        <span className='api-name'>{api.apiName}</span>
                                        <span className='api-version'>{api.version}</span>
                                    </hbr-tooltip>
                                </td>
                                <td>{api.apiInstance}</td>
                                <td>{api?.slaTier ? api.slaTier : "-"}</td>
                                <td className="api-status">
                                    <RequestStatusBadge status={api.contractStatus} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ),
            footerButtons: [
                { text: 'Close', variant: 'primary', onClick: () => handleClose('apis') }
            ]
        }
    };

    const handleCopyClick = async (text, index, type="") => {
        copyText(text);
        if (type == "reset") {
            setShowCopy(prev => ({ ...prev, [index]: false }));
            setTimeout(() => setShowCopy(prev => ({ ...prev, [type]: true })), 1500);
        } else if(type=="secret") {
            setShowCopyStatesSecret(prev => ({ ...prev, [index]: false }));
            setTimeout(() => setShowCopyStatesSecret(prev => ({ ...prev, [index]: true })), 1500);
        } else {
            setShowCopyStates(prev => ({ ...prev, [index]: false }));
            setTimeout(() => setShowCopyStates(prev => ({ ...prev, [index]: true })), 1500);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            dropdownRefs.current.forEach((ref, index) => {
                if (ref && !ref.contains(event.target)) {
                    setDropdownVisible(prevVisibility => {
                        const newVisibility = [...prevVisibility];
                        newVisibility[index] = false;
                        return newVisibility;
                    });
                }
            });
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderAPIContent = () => {
        currentApplication.apis = currentApplication.apis ?? [];
        const apis = currentApplication.apis.length < 3 ? currentApplication.apis : currentApplication.apis.slice(0, 2);
        if(!apis.length) return 'No APIs Associated';
        return (
            <>
                {
                    apis.map(api => (
                        <div key={api.apiName} className='api-list-container'>
                            <span>{api.apiName}</span>
                            <span className='api-version'>{api.version}</span>
                            <span>{api.apiInstance}</span>
                            <RequestStatusBadge status={api.contractStatus} />
                        </div>
                    ))
                }
                {currentApplication.apis.length > 0 && <span className='toggle-button' onClick={()=>handleShowModal('apis', currentApplication.id)}>Show all APIs and Status</span>}
            </>
        )
    }

    const renderTable = () => (
        <>
        <div className="search-container my-3">
        <input
            type="text"
            placeholder="Search for your Application by name"
            value={searchTerm}
            onChange={handleSearchTermChange}
            onKeyUp={handleKeyPress}
            className="search-input"
        />
        <i class="atm-icon search search-icon" onClick={handleSearch}/>
    </div>

    <>
        <table className="pubhub-table iap-table">
            <thead>
                <tr>
                    <th></th>
                    <th>Application Name</th>
                    <th>Client ID</th>
                    <th>Client Secret</th>
                    <th>Registered APIs</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {!loading && applications.length === 0 && (
                    <tr>
                        <td colSpan="7">
                            <div className='text-center empty-state'>
                                <img
                                    src="https://www.cisco.com/c/dam/cdc/ui/atm/1-5-0/images/illustrations/empty-state/no-results-light-large.svg"
                                    alt="Empty state illustration"
                                    width="240px"
                                    height="160px"
                                />
                                <p className='text-center mb-0'>No Records found</p>
                            </div>
                        </td>
                    </tr>
                )}
                {applications && applications.length > 0 && applications.map((application, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td>
                                <div onClick={() => { toggleRow(index, application.id) }}>
                                    <i class={`atm-icon chevron-${expandedRow === index ? 'down' : 'right'}`}/>
                                </div>
                            </td>
                            <td>{application.name}</td>
                            <td>
                                {application.clientId}

                                {showCopyStates[index] 
                                    ? <i className="pl-3 atm-icon clone cursor" onClick={() => handleCopyClick(application.clientId, index)}></i> 
                                    : <i className="pl-3 bi bi-check-lg green"></i>}

                            </td>
                             <td>
                                <span className={`semi-bold-black ${hiddenStates[index] ? 'hide-content' : 'elipsis'}`}>{application.clientSecret}</span>
                                {showCopyStatesSecret[index]
                                    ? <i className="pl-3 atm-icon clone cursor" onClick={() => handleCopyClick(application.clientSecret, index, "secret")}></i>
                                    : <i className="pl-3 bi bi-check-lg green"></i>}
                                {hiddenStates[index]
                                    ? <i className="mr-2 pl-2 atm-icon eye" onClick={() => toggleClass(index)}></i>
                                    : <i className="mr-2 pl-2 atm-icon eye-closed" onClick={() => toggleClass(index)}></i>
                                }
                            </td> 
                            <td>{application.registeredApiCount}</td>
                            <td>
                                <Button className="action-icons mr-1" variant="text" data-toggle="modal" data-target="#exampleModal" onClick={() => handleShowModal('edit', application.id)}>
                                    <i class="atm-icon draw"/>
                                </Button>
                                <Button className="action-icons" variant="text" onClick={() => handleShowModal('delete', application.id)}>
                                    <i class="atm-icon delete"/>
                                </Button>
                                {/* <span className='pl-1' style={{ position: 'relative', cursor: 'pointer' }}><i className="bi bi-three-dots-vertical" onClick={() => toggleDropdown(index)}></i>
                                    {dropdownVisible[index] && (
                                        <div className="dropdown-menu" ref={el => dropdownRefs.current[index] = el}>
                                            <a className="dropdown-item" onClick={() => { handleShowModal('reset', application.id); dropdownVisible[index] = false }}>Reset Client Secret</a>
                                        </div>
                                    )}
                                </span> */}
                            </td>
                        </tr>
                        {expandedRow === index && !loading && (
                            <tr>
                                <td colSpan="7" className="expanded-row">
                                    <div className='expanded-row-container'>
                                        <div className='top-row row'>
                                            <div className='col-details col-4'>
                                                <span className='expanded-row-label'>Client ID</span>
                                                <span className='expanded-row-content semi-bold-black client-id'>{application.clientId}</span>
                                                <span className='expanded-row-action'>
                                                    <CopyTexttoClipBrd textToCopy={application.clientId} />
                                                </span>
                                            </div>
                                            <div className='col-details  col-5'>
                                                <span className='expanded-row-label'>Client Secret</span>
                                                <span className={`expanded-row-content semi-bold-black ${hiddenStates[index] ? 'hide-content' : 'elipsis'}`}>{application.clientSecret}</span>
                                                <span className='expanded-row-action'>
                                                    {hiddenStates[index]
                                                        ? <i className="mr-2 pl-2 atm-icon eye" onClick={() => toggleClass(index)}></i>
                                                        : <i className="mr-2 pl-2 atm-icon eye-closed" onClick={() => toggleClass(index)}></i>
                                                    }
                                                    <CopyTexttoClipBrd textToCopy={application.clientSecret} />
                                                </span>
                                            </div>
                                            <div className='col-details col-3'>
                                                <span className="row-expend-link" onClick={() => handleShowModal('reset', application.id)}>
                                                    Reset Client Secret
                                                    <i class="atm-icon animation ml-1"/>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='row bottom-row'>
                                            <div className='col-4 api-section'>
                                                <span className='expanded-row-label'>Description</span>
                                                <span className='expanded-row-content'>{application.description || '-'}</span>
                                                <span className='expanded-row-label mt-2'>Grant Types</span>
                                                <span className='expanded-row-content'>{(application.grantTypes || []).map(type => getPropTypeLabel(type))?.join(', ')}</span>
                                            </div>
                                            <div className='col-4 api-section'>
                                                <span className='expanded-row-label'>Application URL:</span>
                                                <span className='expanded-row-content'>{application.url || '-'}</span>
                                                <span className='expanded-row-label mt-2'>Redirect URI:</span>
                                                <span className='expanded-row-content'>{(application.redirectUri || []).join(', ')}</span>
                                            </div>
                                            <div className='col-4 api-section'>
                                                <span className='expanded-row-label'>APIs</span>
                                                <div className='expanded-row-content'>
                                                    {renderAPIContent()}
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
        <TablePaginationNew
            tableConfig={tableConfig}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
        />
    </>
    </>
    )
    return (
        <>
            <EditOrCreateApplication 
                className='custom-width-540'
                show={showModal.edit} 
                showAlert={popupData.showAlert} 
                title={popupData.title} 
                status={popupData.status} 
                message={popupData.message} 
                type={'Edit'} data={formValues} 
                onSubmit={(updatedData) => updateApplication(currentApplication.id, updatedData)} 
                onClose={() => handleClose('edit')} 
            />

            {Object.keys(modals).map((key) => (
                <>
                    {key === 'apis' ? (
                        <Modal show={showModal[key]} centered="true" onHide={() => handleClose(key)} className='app-modal-popup custom-width-650' backdrop='static'>
                            <Modal.Header closeButton>
                                <Modal.Title>{modals[key].title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{modals[key].body}</Modal.Body>
                            <Modal.Footer>
                                {modals[key].footerButtons.map((button, index) => (
                                    <Button key={index} variant={button.variant} onClick={button.onClick}>
                                        {button.text}
                                    </Button>
                                ))}
                            </Modal.Footer>
                        </Modal>
                    ) :(
                        <CommonConfiramation
                            key={key}
                            show={showModal[key]}
                            onClose={() => handleClose(key)}
                            popupConfig={{
                                header: modals[key].title,
                                message: modals[key].body,
                                okButtonLabel: modals[key].footerButtons.ok?.text,
                                cancelbuttonLabel: modals[key].footerButtons.cancel?.text,
                                showCancel: !!modals[key].footerButtons.cancel,
                                sentiment:modals[key].footerButtons.ok?.variant,
                                data: modals[key].data
                            }}
                            onCancel={modals[key].footerButtons.cancel?.onClick}
                            onSubmit={modals[key].footerButtons.ok?.onClick}
                        />
                    )}
                </>
            ))}

           
            <div className={`mb-3 px-${config ? 4 : 5} pt-3 myappsTable`} id="pubhub-widgets">
                {loading && !error && <Loader />}
                <h3 className='pubhub-page-title mt-0 mb-4'>My Applications</h3>
                {config ? (
                    <>
                        {renderTable()}
                    </>

                ) :
                    <hbr-tab-group primary>
                        <hbr-tab slot="nav" panel="managed" active={true}>
                            Managed Credentials
                        </hbr-tab>
                        <hbr-tab slot="nav" panel="unmanaged">
                            Unmanaged Credentials
                        </hbr-tab>
                        <hbr-tab-panel name="managed">
                            <hbr-banner
                                closable="false"
                                icon="status-info-outline"
                                sentiment="info"
                                open="true"
                            >
                                These credentials are directly managed by the IT API Portal to access the APIs.
                                <i slot="icon" class="atm-icon status-info"></i>
                            </hbr-banner>
                            {renderTable()}
                        </hbr-tab-panel>
                        <hbr-tab-panel name="unmanaged">
                            <hbr-banner
                                closable="false"
                                icon="status-info-outline"
                                sentiment="info"
                                open="true"
                            >
                                Unmanaged credentials are handled by external platforms. Click the links below to manage them directly on their respective portals.                                
                                <i slot="icon" class="atm-icon status-info"></i>
                            </hbr-banner>
                            <div className='d-flex mt-5 gateway-container'>
                                {otherGateways.map(gateway => (
                                    <div className='gateway-wrapper'>
                                        <a href={gateway.url} target="_blank">
                                            <div class="box d-flex flex-column align-items-center">
                                                <div className={`gateway-img  mb-3 ${gateway.ref.toLowerCase()}`} ></div>
                                                <div className='title'>{gateway.title}</div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </hbr-tab-panel>
                    </hbr-tab-group>

                }

            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        loader: state.request.isLoading,
        errorResponse: state.request.errorResponse,
        response: state.request.response,
        deleteResponse: state.request.deleteResponse,
        resetResponse: state.request.resetResponse,
        applicationList: state.request.applicationList,
        applicationDetail: state.request.applicationDetail,
        refetchApplication: state.request.refetchApplication
    }
}

export default connect(mapStateToProps)(MyApps);
