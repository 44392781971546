import React from "react";
import { generateConfigData, generateInfoCard, processLink, generateLink } from "Common/utility/infoCard"
import { generateCardsJson } from "Common/utility/serialize";
import CommonConfiramation from "../../../js/common/base/CommonConfiramation/CommonConfiramation";

export default class PortalGroupCard extends React.Component {
    constructor(props) {
        super(props);
        this.isAuthenticated = window.sessionStorage.getItem("isAuthenticated");

        this.state = {
            cards: [],
            sections: []
        };
        this.allCards = [];
    }

    componentDidMount() {
        const { config } = this.props;
        if (config) {
            const sections = config.querySelectorAll('section');
            const categorizedData = {};
            sections.forEach((section) => {
                const tabName = section.getAttribute('tab');
                const cards = Array.from(section.querySelectorAll('card')).map(card => ({
                    title: card.querySelector('title')?.textContent.trim(),
                    description: card.querySelector('description')?.innerHTML.trim(),
                    icon: card.querySelector('icon')?.textContent.trim(),
                    pageLink: card.querySelector('pagelink')?.textContent.trim(),
                }));

                categorizedData[tabName] = cards;
            });

            this.setState({
                sections: categorizedData,
            });
        }
    }
    renderCards = (cards) => (
        <div className="pt-5 px-5">
            {cards.map((card, index) => (
                <>
                    {card.title && <strong>{card.title}:</strong>}
                    <div dangerouslySetInnerHTML={{ __html: card.description }}></div>
                    {card.pageLink && this.isAuthenticated && 
                        <div className="pb-3 pl-3">
                            <a className="feature-link" href={"auth/#" + card.pageLink}>Go to {card.title} <i className="atm-pictogram general-wayfinding-arrow-up-right"></i></a>
                        </div>
                    }
                </>
            ))}
        </div>
    );
    render() {
        const { config } = this.props;
        const { sections, cards, searchVal, viewType, bgImage, IconImage } = this.state;

        if (!config) {
            return <span>Loading...</span>;
        }
        return (
            <>
                <div className="whats-new-container mt-3">
                    <hbr-tab-group primary>
                        {Object.keys(sections).map((tabName, index) => (
                            <hbr-tab
                                key={tabName}
                                slot="nav"
                                panel={tabName}
                            >
                                {tabName}
                            </hbr-tab>
                        ))}

                        {Object.entries(sections).map(([tabName, cards]) => (
                            <hbr-tab-panel key={tabName} name={tabName}>
                                {this.renderCards(cards)}
                            </hbr-tab-panel>
                        ))}
                    </hbr-tab-group>
                </div>

            </>
        )
    }
}