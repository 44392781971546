/**
 * Created by aaikepae on 1/31/17.
 */
import React from "react"
import {parseURL, parseLink, parseImageURL} from "./link"
import GlobalSearch from "../../siteEngine/components/GlobalSearch";


export function generate(configXML, location, configType) {
  const data = generateConfigData(configXML);
  return generateInfoCard(data, location, configType);
}

/**
 * Generate json data from hyperlink xml
 * @param config
 * @returns {{title: *, subtitle: *, image: {}, link: {}}}
 */
export function generateConfigData(config) {

  //basic info
  const title = config.queryChild("title") ? config.queryChild("title").innerText.trim() : null;
  const subtitle = config.queryChild("subtitle") ? config.queryChild("subtitle").innerText.trim() : null;
  const search = config.queryChild("search") ? config.queryChild("search") : null;
  const image = {};

  let description = null;
  if (config.queryChild("description")) {
    description = config.queryChild("description").innerHTML;

    //remove first enter from xml
    if (description.substr(0, 1).match(/\n/)) {
      description = description.replace(/\n/, "")
    }


    description = description.replace(/\n/g, "<br />")

  } else if (config.queryChild("rich-description")) {
    description = config.queryChild("rich-description").innerHTML.trim();
  }

  //process image
  const infoImage = config.queryChild("infoImage");
  if (infoImage) {
    image.src = infoImage.queryChild("src") ? infoImage.queryChild("src").innerText.trim() : null;
    image.text = infoImage.queryChild("text") ? infoImage.queryChild("text").innerText.trim() : null;
    image.url = infoImage.queryChild("url") ? infoImage.queryChild("url").innerText.trim() : null;
    image.target = infoImage.queryChild("target") ? infoImage.queryChild("target").innerText.trim() : null;
    image.backgroundColor = infoImage.queryChild("backgroundColor") ? infoImage.queryChild("backgroundColor").innerText.trim() : null;
  }

  //process link
  const hyperLink = config.queryChild("hyperlink");
  const link = processLink(hyperLink);

  return {title, subtitle, description, image, link, search}
}

/**
 * Generate HTML dom from hyperlink json
 * @param data
 * @param location
 * @param configType
 * @returns {{image: *, title, subtitle: *, description: XML, link: *, imageStyle: *}}
 */

export function generateInfoCard(data, location, configType) {
  //image
  let image = null;
  let imageStyle = null;
  if (data.image.src) {
    imageStyle = {
      backgroundColor: data.image.backgroundColor,
      backgroundImage: 'url(' + parseURL(data.image.src, location, location) + ')'
    };

    if (data.image.url) {

      image = <a href={parseLink(data.image.url, location)} title={data.image.text}
                 className="pubhub-info-card-image"
                 target={data.image.target || "_self"}><img src={parseImageURL(data.image.src, location, location)}
                                                            alt={data.image.text || data.title}/></a>
    } else {
      image = <div title={data.image.text} className="pubhub-info-card-image " style={imageStyle}></div>
    }
  }

  //title
  let title = data.title;
  if (data.image.url) {
    title = <a href={parseLink(data.image.url, location)} title={data.title}
               className="pubhub-info-card-title-link "
               target={data.image.target || "_self"}>{title}</a>
  }

  title = <div className="pubhub-info-card-title ">{title}</div>;


  //subtitle

  let subtitle = null;
  if (data.subtitle) {
    subtitle = <div className="pubhub-info-card-subtitle ">{data.subtitle}</div>
  }

  //description
  const description = <div
    className="pubhub-info-card-description "
    dangerouslySetInnerHTML={{__html: data.description}}></div>;


  let link = generateLink(data.link, location);

  //search
  const search = data.search ? <GlobalSearch /> : null;

  return {image, title, subtitle, description, link, imageStyle, search}
}

export function processLink(hyperLink) {
  const link = {};

  if (hyperLink) {
    link.text = hyperLink.queryChild("text") ? hyperLink.queryChild("text").innerText.trim() : null;
    link.url = hyperLink.queryChild("url") ? hyperLink.queryChild("url").innerText.trim() : null;
    link.target = hyperLink.queryChild("target") ? hyperLink.queryChild("target").innerText.trim() : null;
    link.type = hyperLink.queryChild("type") ? hyperLink.queryChild("type").innerText.trim() : null;
    link.typeClass = hyperLink.queryChild("typeClass") ? hyperLink.queryChild("typeClass").innerText.trim() : 'btn-primary';
  }

  return link;
}

export function generateLink(dataLink, location) {
  let link = null;
  if (dataLink.text) {

    if (dataLink.type == "button") {
      link = <a href={parseLink(dataLink.url, location)} title={dataLink.text}
                className={"pubhub-info-card-link-btn btn " + dataLink.typeClass}
                target={dataLink.target || "_self"}>{dataLink.text}</a>
    } else {
      link = <a href={parseLink(dataLink.url, location)} title={dataLink.text}
                className="pubhub-info-card-link "
                target={dataLink.target || "_self"}>{dataLink.text}</a>
    }

  }

  return link;
}
