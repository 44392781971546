import { useRef, useState } from "react"
import axios from "axios";
import { API_BASE_PATH } from "../../../actions/constant";

const initialIds = {
  orgId: '',
  orgName: '',
  apiId: '',
  apiName: '',
  selectedApi: {},
  insId: '',
  insName: '',
  insLabel: ''
};

const useMetricsFilter = () => {
  const [loading, setLoading] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [publisherApiList, setPublisherApiList] = useState([]);
  const [instancesList, setInstancesList] = useState([]);
  const [selectedApi, setSelectedApi] = useState({org:'', apiName:''});
  const selectedIds = useRef(initialIds);

  function getOrgList() {
    setLoading(true);
    axios.get(`${API_BASE_PATH}/publisher_metrics/get_organisations`).then(res => {
      if(res && res.data) {
        setOrgList(res.data.organisations || []);
      }
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      setLoading(false);
    });
  }

  function getAPIList(orgId, selected){
    if(!selected){
        selectedIds.current = {...selectedIds.current, orgName: '', orgId: '', apiId: '', insId: ''};
        setPublisherApiList([]);
        setInstancesList([]);
        return;
    }
    selectedIds.current = {...selectedIds.current, orgName: selected.businessGroupName, orgId};

    const {organizationId, environmentId} = selected;
    setLoading(true);
    axios.get(`${API_BASE_PATH}/publisher_metrics/get_publisher_apis/organizations/${organizationId}/environments/${environmentId}/`).then(res => {
      if(res && res.data) {
        setPublisherApiList(res.data.assets || []);
      }
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      setLoading(false);
    });
  }

  function getInstancesList(apiId, selected) {
    if(!selected) {
        selectedIds.current = {...selectedIds.current, apiName: '', selectedApi: {}, apiId: '', insId: ''};
        setInstancesList([]);
        return;
    }
    selectedIds.current = {...selectedIds.current, apiName: selected.assetId, selectedApi: selected, apiId};
    setInstancesList(selected.instances);
  }

  function setSelectedAPIName(insId, selected) {
    if(!selected) {
        selectedIds.current = {...selectedIds.current, insName: '', insLabel: '', insId: ''};
    }else {
        selectedIds.current = {...selectedIds.current, insName: selected.productVersion, insLabel: selected.instanceLabel, insId};
    }
    setSelectedApi({
      org: selectedIds.current.orgName,
      apiName: `${selectedIds.current.apiName} / ${selectedIds.current.insName} ${selectedIds.current.insLabel ? `/ ${selectedIds.current.insLabel}` : ''}`
    })
  };

  function resetFilters() {
    selectedIds.current = initialIds;
    setSelectedApi({org: '', apiName: ''});
  }

  return {
    loading,
    getOrgList,
    getAPIList,
    getInstancesList,
    setSelectedAPIName,
    resetFilters,
    orgList,
    publisherApiList,
    instancesList,
    selectedApi,
    selectedIds: selectedIds.current
  }
}

export default useMetricsFilter;