/**
 * Created by aaikepae on 3/27/17.
 */

import React from "react"
import {generateLink} from '../../../common/utility/utils'


export default class NavItemHeaderNavigation extends React.Component {
  render() {

    const {data, selected, config, router, toggle} = this.props;

    const child = (function () {
      
      if (!data || !selected) {
        return null
      }

      //1. has nav attribute
      //2. is active
      //3. has more than 1 item
      //4. equal to selected item
      //4. config to show navigation
      //5. page to config to show

      if (!data["active"] || !data["nav"] || (!config.showSingleSubNavigation && data["nav"].length <= 1) || selected.itemURL != data["url"]) {
        return null
      }


      if (!(data["type"] || data["alternative"])) {
        return null;
      }

      if (selected.item["config"] && selected.item["config"]["showSubNavigation"] == false) {
        return null
      }

      if (!config.showSubNavigation) {
        return null
      }

      const uri = data["alternative"] || data["url"];
      const url = router.generateLink(uri);

      const navList = data["nav"].filter((item) => {
        return item.title.replace(/[\r\n]/g, '') != ''
      });

      // if (navList.length <= 1) {
      //   return null
      // }

      // if title is duplicated with first item, then remove it

      if(!config.showSingleSubNavigation && selected.item.title == navList[0].title){
        navList.shift(0)
      }
      //
      // if (navList.length <= 1) {
      //   return null
      // }

      const sublinkEL = navList.map((item, index) => {
        //
        const subLink = item["url"] || generateLink(item.title);
        const suburi = uri + '/' + subLink;
        const suburl = router.mode === 'history' ? `#${subLink}` : router.generateLink(suburi);

        return <li data-tag-type={item.tag} key={index} data-link={generateLink(subLink)}>
          <a href={suburl} data-tag="navigation-item" data-url={suburl} onClick={(e) => {
            if (router.mode === 'history') {
              e.preventDefault();
              router.navigateTo(`${uri}${location.search}#${subLink}`);
            }
            toggle();
          }}>{item.title}</a>
        </li>;
      });


      return <div className="pubhub-header-nav" key="1">
        <span className="arrow-indicator" ref="indicator" key="2"/>
        <ul className="pubhub-header-nav-list" key="3">
          {sublinkEL}
        </ul>
      </div>

    })();


    return child

  }
}
