export const CATCH_ERROR = "CATCH_ERROR";
export const SET_LOADER = "SET_LOADER";
export const RESET_ACTION = "RESET_ACTION";

export const FETCH_APPLICTION = "FETCH_APPLICTION";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const GET_APPLICATION_DETAIL = "GET_APPLICATION_DETAIL";
export const RESET_APPLICATION = "RESET_APPLICATION";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";

export const FETCH_API_INSTANCE = "FETCH_API_INSTANCE";
export const APPLICATION_INSTANCE = "APPLICATION_INSTANCE";
export const GET_SLA_TIERS = "GET_SLA_TIERS";
export const CREATE_REQUEST = "CREATE_REQUEST";
export const CREATE_APPLICATION = "CREATE_APPLICATION";
export const REFETCH_APPLICATION = "REFETCH_APPLICATION";

export const FETCH_PENDING_REQUESTS = "FETCH_PENDING_REQUESTS";
