import React from 'react';
import { generateConfigData, generateInfoCard, processLink, generateLink } from "Common/utility/infoCard"
import { featureSectionBG } from '../../../js/common/utility/images';
import { removeBR } from '../../../js/common/utility/utils';

export default class GettingStartedSection extends React.Component {
    getHref(uri = "", isReactRouting = true) {
        const isAuthenticated = window.sessionStorage.getItem("isAuthenticated");
        let retUrl = isAuthenticated ? "/auth" : "";
        if (uri) {
          retUrl += isReactRouting ? `/#${uri}` : uri.startsWith('/') ? uri : `/${uri}`;
        }
        retUrl += "/";
        return retUrl;
    }

    getLink() {
        var el = this.props.config.querySelector('GettingStartedSection > action');
        return {
            label: el?.dataset.label,
            href: el?.dataset.href
        }  
    }

    render() {
        const { config, location, type } = this.props;

        if (!config) {
            return <span>Loading</span>
        }
        const link = this.getLink();
        const data = generateConfigData(config);
        const {title, subtitle, description} = data;
        const imgSrc = window.location.hostname === 'local.cisco.com' ? 'https://it-developer-nprd-dev.cisco.com/sdk/5b08f319439bfd8b6085.png' : 'puball/images/Getting_Started.png';
        return (
            <div className='getting-started-section'>
                <div className='getting-started-container section-padding-sm' style={{background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 50%), url(${featureSectionBG}) lightgray 50% / cover no-repeat`}}>
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-12 col-md-9 getting-started-image mx-4 d-none d-lg-block'>
                            <img src={imgSrc} alt="Getting Started Section" class="img-fluid"/>
                        </div>
                        <div className='section-up col-12 col-lg-5 p-3'>
                            <h2 className='header_text mt-0'>{title}</h2>
                            <h2 className='header_sub_text mt-0'>{subtitle}</h2>
                            <p className='sub_text'>{removeBR(description)}</p>
                            {link.href && <div className="d-flex justify-content-end">
                                <hbr-button submit="true" type="submit" variant="fill" size="medium">
                                    <a className='footer-link text-white' href={this.getHref(link.href, false)}>{link.label}</a>
                                </hbr-button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}