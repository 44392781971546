import React, { useState, useEffect, useRef,useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import CopyTexttoClipBrd from '../CopyTexttoClipBrd/CopyTexttoClipBrd';
import { Modal, Button, Overlay, Tooltip } from 'react-bootstrap';
import "./_CommonConfiramation.scss";
import { TEXT_PATTERN, LENGTH_RANGE } from '../../base/constants';

function CommonConfiramation({ show, onClose, popupConfig, onCancel, onSubmit }) {
  const [hide, setHide] = useState(true);
  const [denialMessage, setDenialMessage] = useState({ value: "", invalid: true, error: false, helpText: `0 / ${LENGTH_RANGE.MAX}`, touched: false });

  const onChangeDenialReason = useCallback((e) => {
    const textArea = e.target;
    let denialMsgObj = {
      invalid: textArea.invalid ?? true,
      value: textArea.value ?? '',
      touched: denialMessage.touched,
    };
    const inputLength = textArea.value?.length ?? 0;
    denialMsgObj.helpText = `${inputLength} / ${LENGTH_RANGE.MAX}`;
    setDenialMessage(denialMsgObj)
  });

  const textAreaRef = useRef();

  useEffect(() => {
    const denialReason = textAreaRef.current;
    if (!denialReason) {
      return; 
    }
    denialReason.addEventListener('hbr-input', onChangeDenialReason);
    return () => {
      denialReason.removeEventListener('hbr-input', onChangeDenialReason);
    };
   
  }, []);


  const defaultConfig = {
    header: "",
    message: "",
    okButtonLabel: "Ok",
    cancelbuttonLabel: "Cancel",
    showCancel: true,
    sentiment:"primary" //danger
  }

  function handleClose() {
    if(onCancel) onCancel();
  }

  function handleSubmit() {
    let submitValue = ""
    if (popupConfig.provideDenialReason) {
      submitValue = denialMessage.value;
    }
    if(onSubmit) onSubmit(submitValue);
  }

  const handleView = () => {
    setHide(prev => !prev);
  }

  return (
      <Modal show={show} centered className='app-modal-popup fixed-width' backdrop='static' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{popupConfig.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-5 pt-4 common-modal' centered>
      <div className='px-1'>
        <div className='message'>
          {popupConfig.message}
        </div>
          {popupConfig.provideDenialReason && (
            <div className='mt-3'>
              <hbr-textarea pattern={TEXT_PATTERN}
                placeholder="Type your message here"
                ref={textAreaRef} required resize="none"
                maxlength={LENGTH_RANGE.MAX}
                minlength={LENGTH_RANGE.MIN}
                error={denialMessage.touched && denialMessage.invalid}
                onBlur={() => setDenialMessage({ ...denialMessage, touched: true })}
              >
                <div slot='label' className='denial_label'>Reason for Denial</div>
                <div slot='help-text' className='help-text d-flex justify-content-between'>
                  <span>
                    {denialMessage.invalid && denialMessage.touched && 'Please provide message with minimum 3 characters'}
                  </span>
                  <span>
                    {denialMessage.helpText}
                  </span>
                </div>
              </hbr-textarea>
            </div>
          )}
        {popupConfig?.data && popupConfig.data.client_id && (
          <>
            <div className="fw-bold head-txt mt-3">
              Client ID
            </div>
            <div className="d-flex mt-1 align-items-center">
              <div className="sub-txt">
                {popupConfig.data.client_id}
              </div>
              <div className="ms-auto">
                <CopyTexttoClipBrd textToCopy={popupConfig.data.client_id} />
              </div>
            </div>
              <div className="head-txt mt-3 fw-bold align-items-center">
                Client Secret
              </div>
            <div className="d-flex mt-1 align-items-center">
              <div className="sub-txt">
                <span className={hide ? 'secureText sub-txt' : 'sub-txt'}>{popupConfig.data.client_secret}</span>
              </div>
              <div className="ms-auto d-flex align-items-center">

                <i class={` ${hide ? 'eye' : 'eye-closed'} atm-icon eye-icon`} onClick={() => handleView()} />
                <div className="ms-auto">
                  <CopyTexttoClipBrd textToCopy={popupConfig.data.client_secret} />
                </div>

              </div>
            </div>
          </>
        )}
      </div>
      </Modal.Body>
      <Modal.Footer> 

        {(popupConfig.showCancel ?? defaultConfig.showCancel)&& (
          <Button variant="secondary" onClick={handleClose}>
          {popupConfig.cancelbuttonLabel ?? defaultConfig.cancelbuttonLabel}
        </Button>
      ) 
          }
        <Button variant={popupConfig.sentiment ?? defaultConfig.sentiment} onClick={handleSubmit}
          disabled={popupConfig.provideDenialReason && denialMessage.invalid}>
          {popupConfig.okButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
    // <hbr-modal class="atm-modal--small common-modal" open={showPopup} onHbr-hide={handleSubmit}>

    //   <div slot='label' className="modal-title">{popupConfig.header}
    //   </div>
    //   <div className='px-1 modal-body'>
    //     <div className='message'>
    //       {popupConfig.message}
    //     </div>
    //     {popupConfig?.data && (
    //       <>
    //         <div className="d-flex mt-3">
    //           <div className="fw-bold head-txt">
    //             Client ID
    //           </div>
    //           <div className="sub-txt">
    //             {popupConfig.data.client_id}
    //           </div>
    //           <div className="ms-auto">
    //             <CopyTexttoClipBrd textToCopy={popupConfig.data.client_id} />
    //           </div>
    //         </div>


    //         <div className="d-flex mt-3">
    //           <div className="head-txt fw-bold align-items-center">
    //             Client Secret
    //           </div>
    //           <div className="sub-txt">
    //             <span className={hide ? 'secureText sub-txt' : 'sub-txt'}>{popupConfig.data.client_secret}</span>
    //           </div>
    //           <div className="ms-auto d-flex align-items-center">

    //             <i class={` ${hide ? 'eye' : 'eye-closed'} atm-icon eye-icon`} onClick={() => handleView()} />
    //             <div className="ms-auto">
    //               <CopyTexttoClipBrd textToCopy={popupConfig.data.client_secret} />
    //             </div>

    //           </div>
    //         </div>
    //       </>
    //     )}
    //   </div>

    //   <div slot="footer">
    //     {popupConfig.showCancel && (
    //       <hbr-button noPadding="true" variant="outline" class="mr-2 me-2" onClick={handleClose} >{popupConfig.cancelbuttonLabel}</hbr-button>)
    //     }
    //     <hbr-button noPadding="true" sentiment={popupConfig.sentiment ?? ''} onClick={handleSubmit}> {popupConfig.okButtonLabel}</hbr-button>
    //   </div>
    // </hbr-modal>
  )

}




export default CommonConfiramation;