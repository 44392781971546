import React, { useState, useEffect,useCallback, useRef } from 'react';
import "./_myAccessPage.scss";
import IconChevron from './IconChevron';
import RequestDetails from "./RequestDetails/RequestDetails";
import TablePaginationNew from '../../../common/Pagination/TablePaginationNew';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Form, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { useDispatch, useSelector } from 'react-redux'
import ActionModals from './ActionModals/ActionModals';
import { deleteRequest, setLoader, fetchAccessRequest, fetchPendingRequests, approveRequest, denyRequest } from '../../actions';
import { useToast } from '../../../common/base/ToastContext';
import { TEXT_PATTERN } from '../../../common/base/constants';
import { useCombined } from '../../../common/base/CombinedContext';
import RequestStatusBadge from '../../../common/base/RequestStatusBadge/RequestStatusBadge';
import { useHistory, useNavigate } from 'react-router-dom';
function MyAccessPage({...props}) {
  // const dispatch = useDispatch();
  // const accessState = useSelector((state) => state.accessState);
  const navigate = useNavigate();

  const [requestResponse, setRequestResponse] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchTxt, setSearchTxt] = useState('');
  const [approveStatus, setApproveStatus] = useState('');
  const [tableConfig, setTableConfig]= useState({
    pageSize: 10,
    totalPages: 0,
    totalRecords:0,
    currentPage:1,
    pageLimit: [10, 15, 20]
  });
  const [isHashChanged, setIsHashChanged] = useState(false);
  const [hashValue, setHashValue] = useState(window.location.hash);
  const [notificationId, setNotificationId] = useState(null);
  const { showLoader, hideLoader } = useCombined();
  const { showToast } = useToast();
  const breadcrumbItems = [{
    title:"Cisco IT APIs",
    url:"/"
  },
  {
    title:"Request Access Management",
    url:""
  }]

  const { setBreadcrumbItems } = useCombined()
  useEffect(() => {
    setBreadcrumbItems(breadcrumbItems);
  }, [setBreadcrumbItems]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      const queryParams = new URLSearchParams(hash.split('?')[1]);
      const notificationId = queryParams.get('notificationId') || null;

      if (notificationId) {
        setNotificationId(notificationId);
      }
      setIsHashChanged(true);
      setHashValue(hash);
    };

    if (window.location.hash) {
      handleHashChange();
    }
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  useEffect(() => {
    if (requestResponse.length > 0 && notificationId) {
      const index = requestResponse.findIndex(request => request.id === parseInt(notificationId));
      if (index !== -1) {
        setExpandedRow(index);
      }
    }
  }, [requestResponse, hashValue]);
  
  useEffect(() => {
    if (isHashChanged) {
      fetchRequest();
    }
  }, [approveStatus, hashValue, isHashChanged]);

  useEffect(() => {
    if (props.errorResponse?.status == false) {
      hideLoader("failed");
      showToast({
          title: 'Operation Failed',
          bg: 'danger',
          message: props.errorResponse?.data,
      });
    }

    if (props.response.data) {
      hideLoader("failed");

      if (props.response.data?.status == "SUCCESS") {
        showToast({
          title: 'Operation Success',
          bg: 'success',
          message: props.response?.data?.message,
        });
        fetchRequest();
        fetchPendingRequest();
      } else {
        showToast({
          title: 'Operation Failed',
          bg: 'danger',
          message: props.response?.data?.message,
      });
      }
    }

  }, [props.errorResponse, props.response])
  
  useEffect(() => {
    if (props.accessRequestList.data) {
        let dumTableConf = tableConfig;
        dumTableConf.totalPages = props.accessRequestList.data.totalPages;
        dumTableConf.totalRecords = props.accessRequestList.data.totalRecords;
        setTableConfig(dumTableConf);
        setRequestResponse(props.accessRequestList.data.accessRequests);
        hideLoader("fetchRequest");
    }
  }, [props.accessRequestList])

  async function fetchRequest() {
    const hash = window.location.hash;
    const queryParams = new URLSearchParams(hash.split('?')[1]);
    const notificationId = queryParams.get('notificationId') || null;
    showLoader("fetchRequest");
    props.dispatch(fetchAccessRequest(tableConfig.currentPage, tableConfig.pageSize, searchTxt, approveStatus.toUpperCase(), notificationId));
  }

  async function fetchPendingRequest() {
    showLoader("fetchPendingRequest");
    props.dispatch(fetchPendingRequests());
  }

  const handleDelete = async () => {
    showLoader("handleDelete");
    props.dispatch(deleteRequest(selectedRequest.id));
  };

  const handleApprove = async () => {
    showLoader("handleApprove");
    props.dispatch(approveRequest(selectedRequest.id, "approve"));
  };

  const handleDeny = async (reason="") => {
    showLoader("handleDeny");
    props.dispatch(denyRequest(selectedRequest.id, "reject", reason));
  };

  const getClassName = (status) => {
    let retClass = 'success';
    if (status.toLowerCase() === 'deny') {
      retClass = 'danger';
    }
    if (status.toLowerCase() === 'remove') {
      retClass = 'dark';
    }
    if(status.toLowerCase() === 'invalid') {
      retClass = 'info'
    }
    return `min-width-actn btn-outline-${retClass}`;
  };
  const approveBtn = {"label":"approve", "btn_variant":"btn-primary", };
  const removeBtn = {"label":"remove", "btn_variant":"btn-outline-secondary"};
  const denyBtn = {"label":"deny", "btn_variant":"btn-outline-danger",};
  const approveIco = {"label":"approve", "variant":"check-outline","tooltip_label":'Approve Request'}
  const denyIco = {"label":"deny", "tooltip_label":'Deny Request', "variant":"exit-outline"}
  const removeIco = {"label":"remove", "tooltip_label":'Remove Request', "variant":"delete"}
  const statusAction = {
    pending:[approveBtn,denyBtn],
    approved: [denyIco,removeIco],
    denied: [approveIco,removeIco],
    invalid: [removeIco]
  }

  const clickPop = (type,id) => {
    setSelectedRequest({"type":type,"id":id});
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRequest(null);
  };
 
  const handleViewAll = () => {
    setNotificationId(null);
    navigate('/auth', { replace: true });
    window.location.hash = '#request/'; 
    setExpandedRow(null);
  };

  const getActionBtns = (status,req_id) => {
     let statusCase = status.toLowerCase();
     let retVal = "";
     switch (statusCase) {
      case 'pending':
           retVal = statusAction[statusCase].map(action => (
            // can use hbr-btn after ATM support
            //<hbr-button class="text-capitalize" variant={action.btn_variant} sentiment={action.sentiment} size="medium" >{action.label} </hbr-button>
            <button
              key={action.label}
              className={`btn text-capitalize iap-btn ${action.btn_variant}`}
              onClick={() => clickPop(action.label,req_id)}
            >
              {action.label}
            </button>
          ))
        break;

       default:
        retVal =  (
        <div className=' d-flex align-items-center'> 
        {statusAction[statusCase].map(action => (
           <hbr-tooltip  key={action.label} size="large" content={action.tooltip_label} placement="top" class="atm-tooltip--large">
             <i class={`atm-icon mr-3 ${action.variant}`} onClick={() => clickPop(action.label,req_id)}/>
           </hbr-tooltip>
        ))}</div>)
       break;
     }
      return (
        <div className="action">
              {retVal}
         </div>
     )
  }

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
     handleSearch();
    }
  };
  const handleRequest = (reason = "") => {
    closeModal();
    if (selectedRequest.type === 'remove') {
      handleDelete();
    } else if (selectedRequest.type === 'approve') {
      handleApprove()
    } else {
      handleDeny(reason);
    }
  }
  const handleSearch = () => {
    let dumConfig = tableConfig;
    dumConfig.currentPage = 1;
    setTableConfig(dumConfig);
    handleViewAll();
    fetchRequest();
  };

  const handleSearchTermChange = (e) => {
    const { value } = e.target;
    if (TEXT_PATTERN.test(value) || value === '') {
      setSearchTxt(value);
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    let dumConfig = tableConfig;
    dumConfig.currentPage = pageNumber;
    setTableConfig(dumConfig);
    fetchRequest();
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage) => {
    let dumConfig = tableConfig;
    dumConfig.currentPage = 1;
    dumConfig.pageSize = newRowsPerPage;
    setTableConfig(dumConfig);
    fetchRequest();
  };


  const onStatusChange = () => {
    let dumConfig = tableConfig;
  //  setApproveStatus(value);
    dumConfig.currentPage = 1;
    setTableConfig(dumConfig);
  }

  const onHbrChange = useCallback((e) => {
    onStatusChange();
    setApproveStatus(e.target.value);
  });

  const selectRef = useRef();

  useEffect(() => {
    const select = selectRef.current;
    if (!select) {
      return false; 
    }
    select.addEventListener('hbr-change', onHbrChange);
    return () => {
      select.removeEventListener('hbr-change', onHbrChange);
    };
   
  });

  return (
    <div id="pubhub-widgets" className="pubhub-site-container">
    {isModalOpen && 
      <ActionModals show={isModalOpen} type={selectedRequest?.type} onClose={() => closeModal()} onSubmit={(reason)=> handleRequest(reason)}/>
    }
    <div className="mt-4 mx-5 request-table">
      <h4 className='text-primary mb-4'>API Access Request Management</h4>
      <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            <div className="myaccess-search-container">
              <input
                type="text"
                className='search-inp'
                value={searchTxt}
                onChange={handleSearchTermChange}
                onKeyUp={handleKeyPress}
                placeholder="Search your request"
              />
              <span onClick={handleSearch} className="search-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>

              </span>
            </div>
            <div className="ml-5">
              {notificationId && (
                <hbr-button variant="outline" onClick={handleViewAll}>View all Requests</hbr-button>
              )}
            </div>
          </div>
      <div className='d-flex align-items-center'>
        <span className='mr-3'>Filter by Status:</span>
        <span className='select-box-width'>
        <hbr-select ref={selectRef}>
            <hbr-option value="">All</hbr-option>
              <hbr-option value="Pending">Pending</hbr-option>
              <hbr-option value="Approved">Approved</hbr-option>
              <hbr-option value="Denied">Denied</hbr-option>
              <hbr-option value="Invalid">Invalid</hbr-option>
        </hbr-select>
        </span>
      </div>
      </div>
      <table className="table mt-4 iap-table">
        <thead>
          <tr>
            <th className='arrow-head'></th>
            <th className='api-name'>API Name</th>
            <th className='app-name'>Application Name</th>
            <th>Requester Name</th>
            <th>Requester Email</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {requestResponse.length > 0 && requestResponse.map((request, ind) => (
            <React.Fragment key={request.id} className="table-scroll">
              <tr>
                <td className='arrow-head'> 
                <div onClick={() => toggleRow(ind)}>
                    <IconChevron  isopen={expandedRow === ind} />
                  </div>
                  
                </td>
                <td className='api-name'>{request.apiName}</td>
                <td className='app-name'>{request.applicationName}</td>
                <td>{request.requesterName}</td>
                <td>{request.requesterEmail}</td>
                <td>
                  <RequestStatusBadge status={request.status} />
                </td>
                <td>{getActionBtns(request.status,request.id)}</td>
              </tr>
              {expandedRow === ind && (
                <tr>
                  <td colSpan="7" className="expand_row-td">
                    <RequestDetails request={request} />
                    
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          {requestResponse.length == 0 && (
           <tr>
            <td colSpan="7">
                  <div className='text-center empty-state'>
                    <img
                                src="https://www.cisco.com/c/dam/cdc/ui/atm/1-5-0/images/illustrations/empty-state/no-results-light-large.svg"
                                alt="Empty state illustration"
                                width="240px"
                                height="160px"
                            />
                    <p className='text-center mb-0'>No Records found</p>
                  </div>
            </td>
           </tr>)
            
          }
        </tbody>
      </table>
      { requestResponse.length != 0 &&
      <TablePaginationNew
          tableConfig={tableConfig}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
      />}
    </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      loader: state.access.isLoading,
      errorResponse: state.access.errorResponse,
      accessRequestList: state.access.accessRequestList,
      pendingRequestList: state.access.pendingRequestList,
      response: state.access.response
  }
}


export default connect(mapStateToProps)(MyAccessPage);
