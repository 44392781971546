import React, { useEffect, useRef, useState, useCallback } from 'react';
import './globalSearch.scss';
import axios from 'axios';
import { debounce } from '../../../common/utility/utils';
import { useCombined } from '../../../common/base/CombinedContext';


const GlobalSearch = ({className, showIcon}) => {
    const initialState = {
        query: {
            portal: true,
            local: false
        },
        result: {}
    };
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(false);
    const [noResult, setNoResult] = useState(false);
    const [smallScreen, setSmallScreen] = useState(false);
    const [resultContainerHeight, setResultContainerHeight] = useState('auto');
    const [searchScope, setSearchScope] = useState(initialState);
    const resultBackup = useRef();
    const searchRef = useRef();
    const isReset = useRef();
    const globalSearch = useRef();
    const currectClick = useRef('query');
    const resultContainerPosition = useRef('55px');

    // const { breadcrumbItems } = useCombined(); -- it gives error for home page search
    
    let combined = null;
    try {
       combined = useCombined();
    }catch(e) {
        console.log(e);
    }
    const breadcrumbItems = combined?.breadcrumbItems;
    const [searchText, setSearchText] = useState("");

    const searchClick = (e, item) => {
        e.preventDefault();
        const isAuthenticated = window.sessionStorage.getItem("isAuthenticated");
		if (item.url) {
			const protocol = window.location.protocol;
            const host = window.location.host;
            const isAuth = isAuthenticated ? '/auth' : '';
            window.location.href = `${protocol}//${host}${isAuth}${item.url}`;
		}
	}

    const resetSearch = () => {
        setResult({});
        setNoResult(false);
        setSearchScope(prevState => ({...prevState, result: {}}));
        currectClick.current = 'query';
        isReset.current = true;
    }

    const onClear = () => {
        resetSearch();
        setSearchText('');
        if(searchRef.current) searchRef.current.value = '';
    }

    const formatData = (data) => {
        const groupObj = {};

        data.forEach(item => {
            if(!groupObj[item.type]) {
                groupObj[item.type] = [item];
            }else {
                groupObj[item.type].push(item);
            }
        })
        return groupObj;
    }

    const queryHandlers = (e) => {
        handleSearch(e.target.value);
    }

    const scrollToTop = () => {
        const searchEle = globalSearch.current;
        const offset = 10;
        if(resultContainerPosition.current) resultContainerPosition.current = searchEle.getBoundingClientRect().height + 3;
        const elePosition = searchEle.getBoundingClientRect().top + window.scrollY;
        const offsetPos = elePosition - offset;
        window.scrollTo({
            top: offsetPos,
            behavior: 'smooth'
        });
        setTimeout(() => {
            const resultPosOffset = searchEle.getBoundingClientRect().height + searchEle.getBoundingClientRect().top;
            maintainResultContainerHeight(resultPosOffset);
        }, 500)
    }

    const maintainResultContainerHeight = (offsetPos) => {
        // if(Object.keys(resultBackup.current).length === 0) return;
        const offset = 180;
        const height = window.innerHeight - offsetPos - offset;
        setResultContainerHeight(height > 200 ? height : 200);
    }

    const APICall = useCallback(
        debounce((query) => {
            const path = breadcrumbItems && breadcrumbItems.length > 1 ? breadcrumbItems.slice(1, breadcrumbItems.length).map(item => item.title).join('/') : '/';
            const isAuthenticated = window.sessionStorage.getItem("isAuthenticated");
            const url = isAuthenticated ? '/auth/search' : '/search';
            setLoading(true);
            axios.get(`${url}?q=${query}`, {headers: {
                Path: searchScope.query.portal ? '/' : path
            }}).then(res => {
                if(res.data) {
                    if(res.data.length === 0) {
                        setNoResult(true);
                        setResult({});
                    }
                    const data = formatData(res.data);
                    resultBackup.current = {...data};
                    scrollToTop();
                    setResult(data);
                }
            }).catch(_ => {
                resetSearch();
                setNoResult(true);
                setResult({});
            }).finally(() => {
                setLoading(false);
            })
        }, 1000),
        [searchScope]
    );

    const handleSearch = () => {
        setNoResult(false);
        let query = searchText;
       
        if(!query || query.length < 3) {
            if(result.length) setResult([]);
            return;
        }
        APICall(query)
    }

    const handleSearchScope = (name, type) => {
        currectClick.current = type;
        if(loading) return;
        if(type === 'query') {
            setSearchScope({query: {portal: false, local: false, [name]: true}, result: {}});
        } else {
            setSearchScope(prevValue => {
                const state = {...prevValue.result};
                Object.keys(state).forEach(key => {
                    if(name !== key) {
                        state[key] = false;
                    }
                })
                state[name] = !state[name];
                return {...prevValue, result: state};
            });
        }
    }

    const onEnter = (e) => {
        if(e.keyCode === 13) {
            queryHandlers(e);
        }
    }

    const RenderTags = () => {
        const isBreadCrumb = breadcrumbItems && breadcrumbItems.length > 0;
        return (
            <div className='tag-container'>
                <div className='filters'>Query Filters</div>
                <hbr-tag sentiment="info" onClick={() => handleSearchScope('portal', 'query')} selected={searchScope.query['portal']} size="small" variant="selectable">IT API Portal</hbr-tag>
                {isBreadCrumb && <hbr-tag sentiment="info" onClick={() => handleSearchScope('local', 'query')} selected={searchScope.query['local']} size="small" variant="selectable">{breadcrumbItems[breadcrumbItems.length-1].title}</hbr-tag>}
                {Object.keys(result).length > 0 && <>
                    <div className='filters'>Result Filters</div>
                    {Object.keys(resultBackup.current).map(key => (
                        <React.Fragment key={key}>
                            <hbr-tag sentiment="info" onClick={() => handleSearchScope(key, 'result')} selected={searchScope.result[key]} size="small" variant="selectable">{key}</hbr-tag>
                        </React.Fragment>
                    ))}
                </>}
            </div>
        )
    }

    const handleClickOutside = (e) => {
        if(globalSearch.current && !globalSearch.current.contains(e.target)) {
            resetSearch();
            setSmallScreen(false);
        }
    }

    const handleDefaultTabSelection = () => {
        const isLocal = breadcrumbItems && breadcrumbItems.length > 1;

        setSearchScope(prevValue => ({
            ...prevValue,
            query: {
                portal: !isLocal,
                local: isLocal
            }
        }))
    }

    const handleIconClick = () => {
        setSmallScreen(true);
        setTimeout(() => {
            if(searchRef.current) searchRef.current.focus();
        }, 200)
    }

    useEffect(() => {
        handleDefaultTabSelection();

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])


    useEffect(() => {
        if(currectClick.current === 'query') {
            if(!isReset.current) handleSearch();
        }else {
            const selectedType = Object.keys(searchScope.result).find(key => searchScope.result[key]);
            const data = selectedType ? {[selectedType]: resultBackup.current[selectedType]} : {...resultBackup.current};
            setResult(data);
        }
        
    }, [searchText, searchScope])

    return (
        <>
            {showIcon && <div className="d-flex d-md-none" onClick={handleIconClick}><i class="atm-icon search"/></div>}   
            <div ref={globalSearch} className={`global-search-container pubhub-widgets justify-content-center ${className} ${smallScreen ? 'small-screen' : ''}`}>
                <div class="search-container">
                    <i class="atm-icon search"/>
                    <input 
                        ref={searchRef} 
                        type="text" 
                        placeholder="Search..." 
                        onInput={(e) => {
                            isReset.current = false;
                            currectClick.current = 'query';
                            setSearchText(e.target.value)
                        }} 
                        onKeyUp={onEnter}
                    />
                    {!loading && searchText && <i class="atm-icon close pointer" onClick={onClear}/>}
                    {loading && <hbr-progress-ring size="50" indeterminate="true" />}
                </div>
                {(Object.keys(result).length > 0 || noResult) && (
                    <div className='result-container' style={{marginTop: resultContainerPosition.current}}>
                        <RenderTags />
                        <div className='result-content-container' style={{maxHeight: resultContainerHeight}}>
                            {Object.keys(result).length ===0 && noResult && (
                                <div className='no-record'>
                                    <i sentiment="danger" class="atm-icon exclamation-circle"/>
                                    No record found!
                                </div>
                            )}
                            {Object.keys(result).map((key, j) => (
                                <div key={key}>
                                    <div className={`group-type ${j !== 0 ? 'mt-3 mb-2' : 'mb-2'}`}>{key}</div>
                                    {result[key].map((item, i) => (
                                        <a key={`${i}-${item.title}`} className='result' onClick={(e) => searchClick(e, item)}>
                                            <div className='result-title'>{item.title}</div>
                                            <div className='result-content'>{item.content}</div>
                                        </a>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default React.memo(GlobalSearch);