import React from "react";
import { MAX_ELLIPSIS_LENGTH } from "../../../js/common/base/constants";

export default class FeatureSectionCtrl extends React.Component {
    render() {
        const data = this.props.data;
        const header = this.renderHeader(data.header);
        const cards = this.renderCards(data.cards, data.section.containerClass);
        const footer = this.renderFooter(data.footer);

        const className = 'pubhub-feature-section section-padding-sm';

        return (
            <div id={data.section.id} className={className} style={data.section.style}>
                {header}
                {cards}
                {footer}
            </div>
        );
    }
    renderHeader(data) {
        if (!data) {
            return;
        }

        const icon = this.renderImage(data.icon, 'pubhub-feature-section-header');
        const title = this.renderText(data.title, 'pubhub-feature-section-header-title');
        const description = this.renderText(data.description, 'pubhub-feature-section-header-desc');
        const action = this.renderText(data.action, 'pubhub-feature-section-header-action');
        const className = ('pubhub-feature-section-header ' + (data.className || '')).trim();
        const containerClass = data.containerClass ? data.containerClass : 'container';
        if(!title && !description) {
            return null;
        }
        return (
            <div className={className} style={data.style}>
                <div className={containerClass}>
                    {icon}
                    {title}
                    {description}
                    {action}
                </div>
            </div>
        );
    }
    renderFooter(data) {
        if (!data || data.actions.length == 0) {
            return;
        }

        const actions = data.actions.map((action, idx) => this.renderText(action, 'pubhub-feature-section-footer-action', idx));
        const className = ('pubhub-feature-section-footer container ' + (data.className || '')).trim();
        return (
            <div className={className} style={data.style}>
                {actions}
            </div>
        );
    }
    renderCards(data, containerClass) {
        if (!data || data.length == 0) {
            return null;
        }

        const cards = data.map((card, idx) => {
            return this.renderCard(card, idx);
        });

        return (
            <div className={`pubhub-feature-section-body ${containerClass ? containerClass : 'container'}`}>
                <div className='row'>
                    {cards}
                </div>
            </div>
        );
    }
    renderCard(card, key) {
        const cols = this.getCardColumnClassName(card);
        const icon = this.renderImage(card.icon, 'pubhub-feature-section-card');
        const iconTitle = this.renderText(card.iconTitle, 'pubhub-feature-section-card-icon-title');
        const title = this.renderText(card.title, '');
        const description = this.renderText(card.description, 'pubhub-feature-section-card-desc');
        const items = this.rendeCardItems(card.items);
        const flexGap = this.renderCardActionFlexGap(card.action);
        const action = this.renderText(card.action, 'pubhub-feature-section-card-action');
        const containerClassName = (`pubhub-feature-section-card-container ${cols}`).trim()
        const className = (`pubhub-feature-section-card ${card.className}`).trim();
        const iconPartClassName = (card.icon?.src ? ' has-icon' : '');
        const cardSHeaderStyle = card.icon ? card.icon.style : {};
        return (
            <a href={card.action.href} key={key} id={card.id} className={containerClassName} style={card.style}>
                <div className={className} style={card.style}>
                    <div className={"card-header" + iconPartClassName} style={cardSHeaderStyle}>
                        <h2 className="card-title">{title}</h2>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-between">
                        <div className="card-description">
                            {description}
                            {items}
                            {flexGap}
                        </div>
                        {/* <div>{action}</div> */}
                    </div>
                </div>
            </a>
            // <div key={key} id={card.id} className={containerClassName} style={card.style}>
            //     {/* <div className={className} style={card.style}>
            //          <div className={'pubhub-feature-section-card-iconpart' + iconPartClassName}>
            //             {iconTitle}
            //             {icon}
            //         </div> 
            //         <div className='pubhub-feature-section-card-textpart'>
            //             {title}
            //             {description}
            //             {items}
            //             {flexGap}
            //             {action}
            //         </div>
            //     </div> */}
            //     <div className={className} style={card.style}>
            //         <div class="card-header" style={{backgroundImage: `url(${card.icon.src})` }}>
            //             <h2 class="card-title">{title}</h2>
            //         </div>
            //         <div class="card-body d-flex flex-column justify-content-between">
            //             <div class="card-description">
            //                 {description}
            //                 {items}
            //                 {flexGap}
            //             </div>
            //             <div>{action}</div>
            //         </div>
            //     </div>
            // </div>
        );
    }
    renderText(data, classNameFixedPart = '', key) {
        if (!data) {
            return null;
        }

        const { text, href, target, id, className, style } = data;
        let smartTarget = this.getLinkTarget(href, target);
        style.borderColor = style.borderColor || style.backgroundColor;

        const colorTextClassName = style.color ? 'type-colorful' : '';
        const fullClassName = (`${classNameFixedPart} ${className} ${colorTextClassName}`).trim();
        const isLongDescription = text.length > MAX_ELLIPSIS_LENGTH;
        let displayText = text;
        if(classNameFixedPart == 'pubhub-feature-section-card-desc'){
            displayText = isLongDescription ? text.slice(0, MAX_ELLIPSIS_LENGTH).trim() + "…" : text;
        }
        if (data.href) {
            return (
                <a
                    key={key}
                    style={style}
                    id={id}
                    className={fullClassName}
                    href={href}
                    target={smartTarget}
                    dangerouslySetInnerHTML={{ __html: displayText }}>
                </a>
            );
        }

        return (
            <div
                key={key}
                style={style}
                id={id}
                className={fullClassName}
                dangerouslySetInnerHTML={{ __html: displayText }}>
            </div>
        );
    }
    getLinkTarget(href, target) {
        if (target) {
            return target;
        }

        return (href || '').indexOf('http') == 0 ? '_blank' : null;
    }
    renderImage(data, classNamePrefix = '') {
        if (!(data && data.src)) {
            return;
        }

        const loadingProp = data.loading ? { loading: data.loading } : {};
        const img = <img src={data.src} className={classNamePrefix + '-icon'} style={data.style} {...loadingProp} />

        const { src, href, target, id, className } = data;
        const classNameFixedPart = classNamePrefix + '-icon-container';
        const fullClassName = (`${classNameFixedPart} ${className}`).trim();

        if (data.href) {
            return (
                <a id={id} className={fullClassName} href={data.href} target={data.target}>
                    {img}
                </a>
            );
        }

        return (
            <div id={id} className={fullClassName}>
                {img}
            </div>
        );
    }
    rendeCardItems(items) {
        if (!items || items.items.length == 0) {
            return null;
        }

        const list = items.items.map((item, idx) => {
            var text = this.renderText(item, 'pubhub-feature-section-card-item');
            return (
                <li key={idx}>
                    {text}
                </li>
            );
        });

        const className = ('pubhub-feature-section-card-items ' + items.className).trim();
        return (
            <ul className={className} style={items.style}>
                {list}
            </ul>
        );
    }
    renderCardActionFlexGap(action) {
        if (action && (action.className.includes('type-button') || action.className.includes('sticky-bottom'))) {
            return (
                <div className='pubhub-feature-section-card-flex-gap'></div>
            );
        }

        return null;
    }
    getCardColumnClassName(card) {
        const columns = (card.columns || '3') + '';
        if (columns == 4) {
            return 'col-xl-3 col-lg-4 col-md-6';
        }
        if (columns == 3) {
            return 'col-lg-4 col-md-6';
        }
        if (columns == 2) {
            return 'col-lg-6';
        }

        return '';
    }
}