/**
 * Created by aaikepae on 1/9/17.
 */
import React from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import store from "./store/store"

import Site from "./components/Site"
import CustomUiRouter from "../common/base/CustomUiRouter"

// import AOS from "aos";

// window.AOS = AOS;

import {fetchData, loadContent, clearData} from "./actions/data"

import {updateConfig} from "./actions/config"

import RoutableContainer from "../common/base/RoutableContainer"
import Header from "../common/base/Header/Header"
import { ToastProvider } from "../common/base/ToastContext"
import Breadcrumb from "../common/base/BreadCrumb/BreadCrum"
import { CombinedProvider } from "../common/base/CombinedContext"
import Loader from "../common/base/Loader/Loader"
import { useCombined } from "../common/base/CombinedContext"
import Footer from "../common/base/Footer/Footer"

const createReactClass = require('create-react-class');


const APIPageLoader = () => {
    const { loadingSet } = useCombined();
    return loadingSet.size > 0 && <Loader />;
  };

const Wrapper = createReactClass({

    render() {
        return (
            <>
            <Provider store={store}>
                    <>
                    <ToastProvider>
                        <CombinedProvider>
                            <APIPageLoader/>
                            <Header />
                            <Breadcrumb />
                        <CustomUiRouter store={store}></CustomUiRouter>
                        <Footer></Footer>
                        </CombinedProvider>
                    </ToastProvider>
                    </>
                </Provider>
            </>
        )
    }
});


export default class SiteEngine extends RoutableContainer {

    get languages() {
        const meta = this.store.getState().meta;
        if (meta) {
            return meta["languages"] || []
        }
        return [];
    }

    set language(value) {
        const state = this.store.getState();
        const match = this.languages.some((item) => item.code == value);
        if (match && state.preference.language !== value) {
            //update preference
            this.store.dispatch({
                type: "UPDATE_LANGUAGE",
                payload: value
            });

            //reload content
            this.load()
        }
    }

    get language() {
        const state = this.store.getState();
        const preferenceLanguage = state.preference.language;
        let language = state.config.defaultLanguage;
        //if doc has multiple language
        if (state.meta.languages) {
            // if preference in site's language
            if (state.meta.languages.some((item) => item.code == preferenceLanguage)) {
                language = preferenceLanguage;
            }
        }

        return language;
    }


    set defaultLanguage(value) {
        //update preference
        this.store.dispatch({
            type: "UPDATE_LANGUAGE",
            payload: value
        });
    }

    get defaultLanguage() {
        const state = this.store.getState();
        return state.preference.language
    }


    constructor() {
        super();
        this.componentClass = Wrapper;
        this.store = store;
        if (this.router) {
            this.router.baseURL = location.pathname;
        }
    }

    // componentDidMount() {

    //     window["AOS"] = AOS;

    // }


    configDataDidLoad(data) {

        //update data
        this.store.dispatch(fetchData(data));
        //update location in store
        if (data["meta"] && data["meta"]["location"]) {
            this._location = data["meta"]["location"];
        }
        this.store.dispatch(updateConfig({key: "location", value: this._location}));

        this.load()
    }

    navigationDidChange(url) {
        const _url =  "index";
        this.store.dispatch(loadContent(_url));
    }


    updateConfig(key, value) {
        this.store.dispatch(updateConfig({key, value}));
    }

    load() {
        // custom routing for simple web app
        if (this.configData.config && this.configData.config["routing"]) {
            const customRouting = this.configData.config["routing"]
            const subroute = "/" + this.router.getQueryString() + "/";
           
            if (this.router.getQueryString()) {
                // support reg in custom routing
                let rule = Object.keys(customRouting).find((reg) => {
                    if (reg != "*") {
                        return subroute.match(new RegExp(reg))
                    }
                }) || (customRouting["*"] ? "*" : null);

                // if there is matched rule
                if (rule) {
                    this.router.queryString = getCustomRoutingValue(this.router.getQueryString(), rule, customRouting[rule]);
                } else {
                    // default
                    this.router.queryString = "index"
                }
            } else {
                this.router.queryString = "index";
            }

            // if there is hash or url parameters, trigger changes by changed querystring
        } else if (this.defaultURI) {
            this.router.queryString = this.defaultURI;
        } else if (this.router.getQueryString()) {
            this.router.queryString = this.router.getQueryString()
        } else {
            //Special case
            //load first content without hash change
            this.store.dispatch(loadContent("index"));

            // AOS.init({
            //     disable: 'mobile'
            // });
        }


        this.unsubscribe = store.subscribe(() => {
            const _store = store.getState();
            // Only trigger if the location is not empty and the html content is missing
            if ( _store["config"]["location"] !== undefined && !_store['content']) {
                const _event = new CustomEvent("contentLoadError", {
                    bubbles: true,
                    cancelable: true
                });
                this._contaner.dispatchEvent(_event);
            }

        })

    }


    clear() {
        super.clear();
        this.store.dispatch(clearData());
    }
}

function getCustomRoutingValue(url, rule, value) {
    if (!value) return "index";

    if (value == "*") return url;

    if (typeof(value) == "string") return value;

    if (typeof(value) != "object") {
        return url;
    }

    if (rule == "*") return url;

    const regexp = value.regexp || "";
    const normalizedRegexp = regexp.endsWith("/") ? regexp : regexp + "/";
    const regRule = new RegExp(rule);
    const subroute = `/${url}/`;
    const replacedSubroute = subroute.replace(regRule, normalizedRegexp);

    return replacedSubroute.endsWith("/") ? replacedSubroute.substr(0, replacedSubroute.length - 1) : replacedSubroute;
}