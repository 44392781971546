import React, { useEffect, useState } from 'react';
import './_Footer.scss';
import { debounce } from '../../utility/utils';

const Footer = () => {
    const isAuthenticated = window.sessionStorage.getItem("isAuthenticated");
    const [showFooter, setShowFooter] = useState(false);
    function getHref (uri="",isReactRouting=true) {
        let retUrl = ""
        if(isAuthenticated) {
            retUrl +="/auth";
        }
        if(uri) {
            retUrl += isReactRouting ? `/#${uri}` : `/${uri}`;
            
        }
        retUrl += `/`;
        return retUrl;
    }
    
    function clickToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const callback = debounce(() => {
        setShowFooter(true);
    }, 2000);

    useEffect(() => {
        
        const observer = new MutationObserver(callback);
        
        const config = {
            childList: true,
            subtree: true,
            attributes: true,
            attributeFilter: ['class', 'style']
        };
        observer.observe(document.body, config);

        return () => {
            observer.disconnect();
        }
    }, []);

    if(!showFooter) {
        return null;
    }

    return (
        <div className='footer-container pubhub-site-container'> 
            <div className='support_container d-flex justify-content-center align-items-center section-padding'>
                <div className='container wt-700'>
                    <h2 className='header_text mt-0'>Questions? We are here to help.</h2>
                    <p className='sub_text mb-0'>Use the links listed in the sections below to get help from Cisco for this website. If you have any questions or comments related to the website, send an email to the API Management Portal Support Team at api-portal-support@cisco.com</p>
                </div>
            </div>
            <div className='support_link_footer d-flex section-padding-sm flex-md-row flex-column-reverse'>
                <div className='d-flex w-100 justify-content-around justify-content-md-start'>
                    <div className='px-3 px-md-5'>
                        <p className='header_text'>IT API PORTAL</p>
                        <p className='sub_text'><a className='footer-link' onClick={() => clickToTop()} href={getHref()}>Cisco IT APIs</a></p>
                        <p className='sub_text'><a className='footer-link'  onClick={() => clickToTop()} href={getHref('getting-started', false)}>Getting Started</a></p>
                        <p className='sub_text'><a className='footer-link'  onClick={() => clickToTop()} href={getHref('whats-new', false)}>What's New</a></p>
                    </div>
                    <div className=''>
                        <p className='header_text'>Quick Links</p>
                        <p className='sub_text'><a className='footer-link' target="_blank" href="https://www.cisco.com/c/en/us/about/contact-cisco.html">Contacts</a></p>
                        <p className='sub_text'><a className='footer-link' target="_blank" href="https://secure.opinionlab.com/ccc01/o.asp?id=jBjOhqOJ">Feedback</a></p>
                        <p className='sub_text'><a className='footer-link'  target="_blank" href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html">Terms &amp; Conditions</a></p>
                        <p className='sub_text'><a className='footer-link'  target="_blank" href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html">Privacy Statement</a></p>
                        <p className='sub_text'><a className='footer-link' target="_blank" href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies">Cookie Policy</a></p>
                        <p className='sub_text'><a className='footer-link'  target="_blank" href="https://www.cisco.com/c/en/us/about/legal/trademarks.html">Trademarks</a></p>
                    </div>
                </div>
                <div className='w-100 ms-auto d-flex align-items-center pe-md-5 justify-content-center justify-content-md-end mb-4 mb-md-0'>
                    <p className='mb-2 logo-text'>IT API PORTAL <br/> <span className='copy-right-text'>Copyright {new Date().getFullYear()} Cisco</span></p>
                </div>
            </div>
        </div>
    );
}
export default Footer;