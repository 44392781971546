import React from "react";
import FeatureSectionCtrl from './FeatureSectionCtrl';
import { parseImageURL } from "Common/utility/link";
import { featureSectionBG } from "../../../js/common/utility/images";

export default class FeatureSection extends React.Component {
    render() {
        const config = this.props.config;
        if (!config) {
            return 'Loading';
        }

        const data = {
            section: this.parseSection(config),
            header: this.parseHeader(config),
            cards: this.parseCards(config),
            footer: this.parseFooter(config)
        }

        return (
            <FeatureSectionCtrl data={data} />
        );
    }
    getImgProps(dom, selector) {
        var el = dom.querySelector(selector);

        if (!el) {
            return null;
        }

        var img = el.querySelector('img');
        var src = img ? img.src || img.dataset.src : parseImageURL(el.getAttribute("data-src"),
            this.props.location, this.props.location);
        var loading = img ? null : el.getAttribute("loading");

        return {
            id: el.dataset.id,
            className: el.dataset.class || '',
            src,
            href: el.dataset.href,
            target: el.dataset.target,
            style: {
                width: el.dataset.width,
                maxWidth: el.dataset.maxWidth,
                minWidth: el.dataset.minHeight,
                overflow: el.dataset.overflow,
                borderRadius: el.dataset.borderRadius,
                boxShadow: el.dataset.boxShadow,
                padding: el.dataset.padding,
                ...(src ? {background: `url(${src}) no-repeat center center / cover, #0F2B45`} : {backgroundColor: 'var(--atm-color-brand-secondary)'}),
                mixBlendMode: 'darken'
            },
            loading: loading
        }
    }
    getTextProps(dom, selector) {
        var el = dom.querySelector(selector);
        if (!el) {
            return null;
        }

        return this.getTextPropsFromElement(el);
    }
    getTextPropsForList(dom, selector) {
        var list = Array.from(dom.querySelectorAll(selector));
        return list.map(el => this.getTextPropsFromElement(el));
    }
    getTextPropsFromElement(el) {
        const tagProps = this.getTagPropsFromElement(el);
        return {
            ...tagProps,
            text: el.innerHTML,
            href: el.dataset.href,
            target: el.dataset.target,
        }
    }
    getTagProps(dom, selector) {
        var el = dom.querySelector(selector);
        if (!el) {
            return null;
        }

        return this.getTagPropsFromElement(el);
    }
    getTagPropsFromElement(el) {
        const styleProps = this.getStylePropsFromElement(el);
        return {
            id: el.dataset.id,
            className: el.dataset.class || '',
            containerClass: el.dataset.containerclass,
            style: styleProps,
        }
    }
    getStylePropsFromElement(el) {
        return {
            color: el.dataset.color,
            backgroundColor: el.dataset.backgroundColor,
            borderColor: el.dataset.borderColor,
            fontSize: el.dataset.fontsize,
            fontWight: el.dataset.fontWight,
            padding: el.dataset.padding,
            margin: el.dataset.margin,
            borderRadius: el.dataset.borderRadius,
            boxShadow: el.dataset.boxShadow,
            overflow: el.dataset.overflow,
            textAlign: el.dataset.textalign,
            width: el.dataset.width,
            background: el.dataset.defaultbg ? `linear-gradient(to bottom, rgba(255, 253, 246, 0.90) 0%, rgba(253, 239, 219, .95) 50%), url(${featureSectionBG}) no-repeat` : el.dataset.background,
            backgroundSize: 'contain'
        }
    }
    parseSection(config) {
        const props = this.getTextPropsFromElement(config);
        const bgimg = this.getImgProps(config, 'FeatureSection > bgimg');
        return {
            ...props,
            style: {
                ...props.style
            }
        }
    }
    parseHeader(config) {
        if (!config.querySelector('header')) {
            return {
                icon: this.getImgProps(config, 'FeatureSection > icon'),
                title: this.getTextProps(config, 'FeatureSection > rich-title') || this.getTextProps(config, 'FeatureSection > title'),
                description: this.getTextProps(config, 'FeatureSection > description'),
                action: this.getTextProps(config, 'FeatureSection > action'),
            }
        }

        const tagProps = this.getTagProps(config, 'header');
        return {
            ...tagProps,
            icon: this.getImgProps(config, 'header icon'),
            containerClass: config.dataset.containerclass,
            title: this.getTextProps(config, 'header rich-title') || this.getTextProps(config, 'header title'),
            description: this.getTextProps(config, 'header description'),
            action: this.getTextProps(config, 'header action'),
        }
    }
    parseCards(config) {
        const cards = config.querySelector('cards');
        if (!cards) {
            return null;
        }

        const columns = cards.dataset.columns;
        const className = cards.dataset.class || '';

        const list = Array.from(config.querySelectorAll('cards card'));

        return list.map(card => {
            const cardClassName = ((card.dataset.class || '') + ' ' + className).trim();
            const items = this.parseCardItems(card);
            const tagProps = this.getTagPropsFromElement(card);
            return {
                ...tagProps,
                className: cardClassName,
                columns: columns,
                iconTitle: this.getTextProps(card, 'icon-title'),
                icon: this.getImgProps(card, 'icon'),
                title: this.getTextProps(card, 'rich-title') || this.getTextProps(card, 'title'),
                description: this.getTextProps(card, 'description'),
                items: items,
                action: this.getTextProps(card, 'action'),
                style: {
                    // card styling here
                }
            };
        })
    }
    parseFooter(config) {
        if (!config.querySelector('footer')) {
            return null;
        }

        return {
            className: this.getTextProps(config, 'footer').className,
            actions: this.getTextPropsForList(config, 'footer action'),
        }
    }
    parseCardItems(card) {
        const el = card.querySelector('list');
        if (!el) {
            return null;
        }

        const list = Array.from(el.querySelectorAll('i'));
        if (list.length == 0) {
            return null;
        }

        const items = list.map(i => {
            const props = this.getTextPropsFromElement(i);
            props.className = (props.className || '').trim();
            return props;
        });

        const tagProps = this.getTagPropsFromElement(el);
        const style = tagProps.style || {};
        style.listStyleType = el.dataset.listStyleType;

        return {
            ...tagProps,
            className: el.dataset.class,
            items: items
        }
    }
}
