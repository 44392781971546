import axios from "axios";
import {
    SET_LOADER,
    CATCH_ERROR,
    FETCH_API_INSTANCE,
    APPLICATION_INSTANCE,
    GET_SLA_TIERS,
    CREATE_REQUEST,
    CREATE_APPLICATION,
    RESET_ACTION,
    REFETCH_APPLICATION
} from "./type";
import { API_PATHS, bearerToken } from "./constants";

export const setLoader = (loader) => (dispatch) => {
    dispatch({ type: SET_LOADER, payload: loader })
}

export const resetAction = () => (dispatch) => {
  dispatch({ type: RESET_ACTION, payload: {} });
};

export const reFetchApplication = () => (dispatch) => {
  dispatch({ type: REFETCH_APPLICATION, payload: Math.random()*100 });
};

export const fetchAPIInstance = (organizationId, assetId, versionGroup) => {
  return (dispatch) => {
    axios
      .get(`${API_PATHS.REQUEST_PAGE_API}/organizations/${organizationId}/assets/${assetId}/versionGroups/${versionGroup}/apiInstances`)
      .then((res) => {
        console.log("res == ",res);
        dispatch({ type: FETCH_API_INSTANCE, payload: { data: res?.data, status: true }})
      })
      .catch((err) => {
        dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data, status: false }})
      })
  }
}

export const fetchApplications = (organizationId, apiInstance) => {
  return (dispatch) => {
    axios
      .get(`${API_PATHS.REQUEST_PAGE_API}/organizations/${organizationId}/applications/list?apiInstanceId=${apiInstance}`)
      .then((res) => {
          console.log("fetch_request ",res);
          dispatch({ type: APPLICATION_INSTANCE, payload: { data: res?.data, status: true }})
      })
      .catch((err) => {
        dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data, status: false }})
      })
  }
}

export const getSLATiers = (organizationId, assetId, versionGroup, apiInstance) => {
  return (dispatch) => {
    axios
      .get(`${API_PATHS.REQUEST_PAGE_API}/organizations/${organizationId}/assets/${assetId}/versionGroups/${versionGroup}/instances/${apiInstance}/tiers`)
      .then((res) => {
          console.log("fetch_request ",res);
          dispatch({ type: GET_SLA_TIERS, payload: { data: res?.data, status: true }})
      })
      .catch((err) => {
        dispatch({ type: CATCH_ERROR, payload: { data: err?.response?.data, status: false }})
      })
  }
}

export const createRequest = (organizationId, appId, data) => {
  return (dispatch) => {
    axios
      .post(`${API_PATHS.REQUEST_PAGE_API}/organizations/${organizationId}/applications/${appId}/accessRequests`,{...data})
      .then((res) => {
        console.log("res == ",res);
          dispatch({ type: CREATE_REQUEST, payload: { data: res?.data, status: true }})
      })
      .catch((err) => {
        dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data?.error, status: false }})
      })
  }
}

export const createApplication = (organizationId, apiInstance, data) => {
  return (dispatch) => {
    axios
      .post(`${API_PATHS.REQUEST_PAGE_API}/organizations/${organizationId}/applications?apiInstanceId=${apiInstance}`,data)
      .then((res) => {
          console.log("fetch_request ",res);
          dispatch({ type: CREATE_APPLICATION, payload: { data: res?.data, status: true }})
      })
      .catch((err) => {
        dispatch({ type: CATCH_ERROR, payload: { data: err, status: false }})
      })
  }
}