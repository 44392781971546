import React from 'react';
import '../BreadCrumb/_BreadCrum.scss';
import { useCombined } from '../CombinedContext';

const Breadcrumb = () => {
  const { breadcrumbItems, setBreadcrumbItems } = useCombined();
  function redirectURL(item) {
    if (item && item.url) {
      const url = getURL(item.url);
      if (url) {
        window.location.href = url;
      }
    }
  }

  function getURL(url) {
    let retUrl = "";
    if (window.sessionStorage.getItem("isUserAuthenticated") || window.sessionStorage.getItem("isAuthenticated")) {
      retUrl += `/auth`;
    }
    if (!url.startsWith("/")) {
      url = "/" + url;
    }
    if(!url.endsWith("/")) {
      url = url + '/';
    }
    retUrl += url;
    return retUrl;
  }

  return (
    breadcrumbItems && breadcrumbItems.length > 1 &&
    <div className='breadCrumb-container py-3 px-3 px-sm-5'>
      {breadcrumbItems.map((item, index) => {
        const isLastItem = index === breadcrumbItems.length - 1;
        return (
          <span key={index}>
            <span 
              onClick={() => !isLastItem && redirectURL(item)} 
              className={isLastItem ? 'lastItem' : 'bcItems'}
            >
              {item.title}
            </span>
            {!isLastItem && <span className='splitter'><i class="atm-icon chevron-right"/></span>}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;