// require("@webcomponents/webcomponentsjs/custom-elements-es5-adapter");
// require("@webcomponents/webcomponentsjs/webcomponents-bundle.js");
import React from "react";
import ReactDOM from "react-dom";

import '../../webcomponent';

const context = {
    props: {
        config: {},
        meta: {}
    }
};

function generateClass(Widget, selector) {
    return class  extends HTMLElement {
        connectedCallback() {

            let self = this;
            // not able to access to childNodes when mounting to the dom tree
            // should be better way
            setTimeout(() => {
                // clone config
                const config = self.config = self.cloneNode(true);

                self.innerHTML = "";

                if (typeof Widget === 'object') {
                    Widget.loader().then((component) => {
                        let widget = React.createElement(component.default, {
                            "config": config,
                            "id": self.getAttribute("id"),
                            "type": self.getAttribute("type"),
                            "node": this,
                            "location": "",
                            "context": context,
                            "parent": this.parentNode,
                            "wrapper": self.parentNode
                        });
        
                        let el = document.createElement("div");
                        el.id = "pubhub-widgets";
                        el.classList.add("pubhub-site-container");
        
                        let _el = document.createElement("div");
                        _el.classList.add("pubhub-widget-container");
                        _el.setAttribute("data-selector", selector);
        
                        el.appendChild(_el);
                        self.appendChild(el);
        
                        // shadow dom
                        // let shadowRoot = this.attachShadow({mode: 'open'});
                        // shadowRoot.appendChild(el);
        
                        // console.log(self.innerHTML);
        
                        //render el to that place
                        ReactDOM.render(widget, el);
                    })
                } else {
                    let widget = React.createElement(Widget, {
                        "config": config,
                        "id": self.getAttribute("id"),
                        "type": self.getAttribute("type"),
                        "node": this,
                        "location": "",
                        "context": context,
                        "parent": this.parentNode,
                        "wrapper": self.parentNode
                    });
    
                    let el = document.createElement("div");
                    el.id = "pubhub-widgets";
                    el.classList.add("pubhub-site-container");
    
                    let _el = document.createElement("div");
                    _el.classList.add("pubhub-widget-container");
                    _el.setAttribute("data-selector", selector);
    
                    el.appendChild(_el);
                    self.appendChild(el);
    
                    // shadow dom
                    // let shadowRoot = this.attachShadow({mode: 'open'});
                    // shadowRoot.appendChild(el);
    
                    // console.log(self.innerHTML);
    
                    //render el to that place
                    ReactDOM.render(widget, el);
                }
            }, 0)
        }

        constructor() {
            super();
            // console.log("constructor", this, this.innerHTML, this.childNodes)
        }
    }
}

export function registerElement(selector, Widget, prefix='pubhub-') {
    window.WebComponents.waitFor(() => window.customElements.get(`${prefix}${selector.toLowerCase()}`) || window.customElements.define(`${prefix}${selector.toLowerCase()}`,generateClass(Widget, selector)));
}

