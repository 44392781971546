export default function (text) {
    try {
        navigator.clipboard.writeText(text);

        // setShowCopy(prev => ({ ...prev, [type]: false }));
        // setTimeout(() => setShowCopy(prev => ({ ...prev, [type]: true })), 1500);
    } catch (err) {
        console.error(
            "Unable to copy to clipboard.",
            err
        );
    }
}