import React from 'react';
import { useToast } from '../../../common/base/ToastContext';

const AccessRestricted = ( { data, selected, config, items, actAsPartner } ) => {
  const { showToast } = useToast();

  return (
    <>
      <a className="hyperlink" onClick={ () => showToast( {
          "title": actAsPartner ? 'Access Restricted for Partner View' : 'Login Required',
          "bg": 'danger',
          "message": actAsPartner ? `You are viewing this page as Partner, switch back to default view to access this page.` : `Please Login to access "${data.title}"`
      } ) }>{ data.title }</a>
    </>
  )
}
export default AccessRestricted;
