import React, { useState, useEffect, useRef } from 'react';
import CopyText, { default as copyText } from '../../utility/copyText';
import { Button } from 'react-bootstrap';
import './_CopyTexttoClipBrd.scss';

function CopyTexttoClipBrd({ textToCopy }) {
    const [showCopy, setShowCopy] = useState(false);
    const [copyMessage, setCopyMessage] = useState("Copy")
    useEffect(() => {
        if (showCopy) {
            setCopyMessage("Copy")
        } else {
            setCopyMessage("Copied")
        }
    }, [showCopy])
    return (
        <hbr-tooltip size="large" content={`${copyMessage} to Clipboard`} trigger="hover" placement="top" class="atm-tooltip--large">
            <Button variant="primary" className='copy-btn' onMouseOver={() => setShowCopy(true)} onClick={() => {
                setShowCopy(false);
                copyText(textToCopy);
            }}>
                Copy<i class="atm-icon clone ms-1" />
            </Button>
        </hbr-tooltip>
    )
}

export default CopyTexttoClipBrd;