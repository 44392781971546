/**
 * Created by aaikepae on 11/6/16.
 */


import React from "react";
import CodeTabs from "./CodeTabs";
import {generateLink} from "../../../js/common/utility/utils";


export default class CodeTab extends React.Component {

  /*
  generateConfigData(config) {
    const code_pre = Array.from(config.querySelectorAll("pre"));
    const mergeSameLanguageCode = !!config.getAttribute("data-mergeSameLanguageCode");
    const codeSectionTitleMapping = config.getAttribute("data-codeSectionTitleMapping") ? JSON.parse(config.getAttribute("data-codeSectionTitleMapping")) : null;
    let titles = [];
    const contents = [];
    // if there is only one code section without title
    if (code_pre.length == 1 && !code_pre[0].getAttribute("class")) {
      titles = [];
      contents.push(_processCodeContent(code_pre[0]))
    } else {

      // loop all code section
      code_pre.reduce(function (titles, el, index) {
        const cssClass = el.getAttribute("class");
        if (!cssClass) {
          titles.push(index ? "Code Snippet - " + index : "Code Snippet");
        } else {
          //title
          const _title = cssClass.split("-");
          let title = _title.length > 1 ? _title.slice(1).join(" ") : _title[0];
          if (codeSectionTitleMapping && codeSectionTitleMapping[title]) {
            title = codeSectionTitleMapping[title];
          }

          // content

          const content = _processCodeContent(el);

          // fot supporting `mergeSameLanguageCode` config
          if (!(mergeSameLanguageCode && titles[titles.length - 1] === title)) {
            titles.push(title);
            contents.push(content)
          } else {
            contents[contents.length - 1] += " \n" + content;
          }

        }

        return titles;
      }, titles);

    }

    return {titles, contents}
  }
*/

  constructor(props) {
    super(props);

    this.handleTitleClick = this.handleTitleClick.bind(this);
  }

  render() {

    const index = parseInt(this.props.config.getAttribute("data-index"));
    const content = this.props.context.selected.codeSections[index];
    const isTitleAutoGenerated = content.title.startsWith("Code section - ");

    let title = <div className="code-tab-title" onClick={(e) => this.handleTitleClick(content.id)}>
      <i className="fal fa-file-code-o"/>
      <span>{content.title}</span>
      <i className="fal fa-angle-right"/>

    </div>;


    let description = content["description"]
      ? <div className="code-tab-description" dangerouslySetInnerHTML={{__html: content["description"]}}/>
      : null;


    let tab = null;

    // console.log(this.props.context.config)

    if (this.props.context.config.codeLayout !== "two-columns" || (this.props.context.config.codeLayout == "two-columns" && this.props.context.config["_platform"] !== "lg")) {
      tab = <CodeTabs groups={content.groups}/>;

      if (isTitleAutoGenerated) {
        title = null;
      }

    }

    // active first item by default
    const cssClass = "code-tab-container " + (index === 0 ? "code-tab-container-active" : " ");

    return (
      <div className={cssClass} ref="tab" id={content.id} data-index={index}>
        {title}
        {description}
        {tab}
      </div>
    )

  }


  handleTitleClick(id) {
    window.location.href = "#" + this.props.context.selected.itemURL + "/" + id;
  }


}

