import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";

const CommonChart = forwardRef(
  (
    {
      chartData,
      loading,
      title,
      styles,
      expand,
      options = {},
      type = "bar",
      legend = true,
    },
    ref
  ) => {
    const ChartComponent =
      {
        bar: Bar,
        line: Line,
        pie: Pie,
        doughnut: Doughnut,
      }[type] || Bar;

    const chartRef = useRef();

    const noDataPlugin = {
      id: "noData",
      beforeDraw: (chart) => {
        if (
          chart.data.datasets[0].data.length === 0 ||
         // chart.data.datasets[0].data.every((data) => data === 0) ||
          chart.options.plugins.loading
        ) {
          const {
            ctx,
            chartArea: { left, top, width, height },
          } = chart;
          chart.options.plugins.legend = {
            display: false,
          };
          ctx.save();
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "16px Arial";
          ctx.fillStyle = "gray";
          ctx.fillText(
            chart.options.plugins.loading ? "Loading..." : "No data points",
            left + width / 2,
            top + height / 2
          );
          ctx.restore();
        }
      },
    };

    useImperativeHandle(ref, () => ({
      getRef: () => chartRef
    }))

    const handleMouseOut = () => {
      if(chartRef.current) {
        const chart = chartRef.current;
        chart.crosshair.enabled = false;
        chart.update();
      }
    };

    useEffect(() => {
      if(chartRef.current) {
        const chart = chartRef.current;
        const chartElement = chart.canvas;
        chartElement?.addEventListener('mouseout', handleMouseOut);

        return () => {
          chartElement?.removeEventListener('mouseout', handleMouseOut);
          chartRef.current.destroy();
        };
      }
    }, []);

    return (
      <div
        className="chart-container position-relative"
        style={styles}
      >
        {title && <div style={{ marginBottom: "24px" }}>{title}</div>}
        <ChartComponent
          ref={chartRef}
          data={chartData}
          options={{
            elements: {
              point: {
                radius: 0,
              },
            },
            scales: {
              x: {
                ticks: {
                  maxRotation: 45,
                  minRotation: 45,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display:legend,
              },
              tooltip: {
                enabled: true, // Enable tooltips
                mode: "index", // Show tooltips for all datasets at the point
                intersect: false,
                backgroundColor: "#ffffff", // Custom background color
                titleColor: "#313233", // Title color
                bodyColor: "#606162", // Body color
                odyAlign: "left", // Ensure the tooltip body is left-aligned
                titleAlign: "left", // Ensure the tooltip title is left-aligned
                displayColors: true, // Display color boxes
                boxHeight: 5,
                callbacks: {
                  // Customize the tooltip title
                  title: (tooltipItems) => {
                    return `Date: ${tooltipItems[0].label}`;
                  },
                  // Customize the tooltip label
                  label: (tooltipItem) => {
                    const dataset = tooltipItem.dataset;
                    const label = dataset.label || "Value";
                    const value = tooltipItem.raw;

                    // Return the label with value, color box will be added by labelColor
                    return ` ${label}: ${value}`;
                  },
                  labelColor: (tooltipItem) => {
                    return {
                      borderColor: tooltipItem.dataset.borderColor,
                      backgroundColor: tooltipItem.dataset.borderColor,
                    };
                  },
                },
              },
              hover: {
                mode: "nearest",
                intersect: true,
              },
              crosshair: {
                line: {
                  color: "#F66",
                  width: 1,
                },
                sync: {
                  enabled: false,
                  group: 1,
                  suppressTooltips: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              loading,
            },
            responsive: true,
            maintainAspectRatio: !expand,
            ...options,
          }}
          plugins={[noDataPlugin]}
        />
      </div>
    );
  }
);

export default React.memo(CommonChart);
