import React from "react";
import { generateErrorMessage } from "Common/utility/serialize";

export default class PortalError extends React.Component {
    render() {
        const { config, location, type, width, height = "" } = this.props;

        if (!config) {
            return <span>Loading</span>
        }
        const data = generateErrorMessage(config);

        function redirectAction() {
            if(!data.actionLink) {
                return false;
            }
            let redirectUrl = "";
            const isAuthenticated = window.sessionStorage.getItem("isAuthenticated");
            if(isAuthenticated) {
                redirectUrl += "/auth"
            }

            window.location.href = redirectUrl + data.actionLink;
        }

        return (<>
            <div className={`p-3 ${data.bannerHeight} text-center align-content-center ${type ?? type} ${type==='fullPage'?'d-lg-flex' :''}`}>
                <img width={`${data.width}`} height={`${data.height}`}
                    src={`https://www.cisco.com/c/dam/cdc/ui/atm/1-5-0/images/illustrations/empty-state/${data.imageSrc}-large.svg`}
                    alt="State Illustration"
                />
                <div className={`content-div ${type === 'fullPage' ? 'row col-3 col-sm-12 col-lg-4 text-sm-center text-md-center text-lg-left':''}`}>
                    <div className="">

                        <p className="mb-2 title-text">{data.title}</p>

                        <p className="mb-3">{data.message}</p>
                        {
                            data.errorActionBtn && (
                                <div>
                                    <hbr-button submit="true" type="submit" variant="fill" size="large" onClick={redirectAction} >
                                        {data.errorActionBtn}
                                    </hbr-button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>


        </>)
    }
}