import React,{useEffect} from 'react';
import { useCombined } from '../../../common/base/CombinedContext';

const SetSiteBreadCrum = ({config,meta}) => {
  const { setBreadcrumbItems } = useCombined()
  const getBreadCrumbItems = () => {
    if(meta.page_type === 'home') {
        return ([
            { title: "Cisco IT APIs", url: "" }
          ])
    }
    if(meta.page_type === 'site') {
        return ([
            { title: "Cisco IT APIs", url: "/" },
            {title: meta.site_page_name, url:""}
          ])
    }

    if(meta.page_type === 'apiGroup') {
        return ([
            { title: "Cisco IT APIs", url: "/" },
            {title: meta.site_page_name, url:meta.site_page_url},
            {title: meta.api_group_page_name, url:""}
          ])
    }
  }
  useEffect(() => {
    const breadcrumbItems = getBreadCrumbItems();

    setBreadcrumbItems(breadcrumbItems);
  }, [setBreadcrumbItems]);

return null;
}
export default SetSiteBreadCrum;