import React,{useEffect} from 'react';
import './_ApiBestPractice.scss';
import { useCombined } from '../../../common/base/CombinedContext';

const ApiBestPractice = () => {
  const { setBreadcrumbItems } = useCombined()
  useEffect(() => {
    const breadcrumbItems = [
      { title: "Cisco IT APIs", url: "/" },
      { title: "API Best Practice", url: "" }
    ];

    setBreadcrumbItems(breadcrumbItems);
  }, [setBreadcrumbItems]);

return (
    <div id="pubhub-widgets" className="pubhub-site-container">
      <div className="features-header mb-5">
        <h2>API Best Practices</h2>
        <p>This page is a summary of the <a href="https://apistyleguide.cisco.com/" target="_blank">
          Cisco API style guide</a> which should
          be used for all APIs. We recommend that you read the entire style guide.<br/>
          The API style guide discusses both Web APIs and device based
          (Netconf YANG) APIs. We are only concerned with the Web based APIs. </p>
      </div>
    
<div class="row base-margin-bottom content-txt">
    <div class="col-1"></div>
    <div class="col-10">
        <h2><a name="APIasaProduct">API as a Product</a></h2>
        <ul>
          <li>The customer for any API is the developer for that customer/partner.</li>
          <li>Treat APIs as a products not projects to ensure a good customer experience.</li>
          <li>Use a structured development process.</li>
          <li>Develop your API road map.</li>
          <li>Follow the guidelines in the Cisco API style guide
            <a href="https://apistyleguide.cisco.com/#!apix-products" target="_blank">API as a product</a> section.</li>
        </ul>
    </div>
    <div class="col-1"></div>
</div>
<div class="row base-margin-bottom content-txt">
    <div class="col-1"></div>
    <div class="col-10">
        <h2><a name="APICustomerExperience">API Customer Experience</a></h2>
        <ul>
          <li>The customer for any API is the developer.</li>
          <li>Must have a good customer experience. The API should be:</li>
          <ul>
            <li>Easy to understand</li>
            <li>Consistent & easy to use</li>
            <li>Well documented</li>
            <li>Well supported</li>
            <li>Contain coding examples</li>
          </ul>
            <li>API discoverability is called out in this section. The new API portal will be the used to discover the APIs.</li>
            <li>Follow the guidelines in the Cisco API style guide
              <a href="https://apistyleguide.cisco.com/#!apix-devexperience" target="_blank">Developer experience</a> section</li>
        </ul>
    </div>
    <div class="col-1"></div>
</div>
<div class="row base-margin-bottom content-txt">
    <div class="col-1"></div>
    <div class="col-10">
        <h2><a name="APIDesignBestpractices">API design Best Practices</a></h2>
        <ul>
          <li>This section calls out general API design best practices.</li>
          <li>For REST APIS, recommend starting with OpenAPI/Swagger.</li>
          <li>This is a very good section to read in its entirety</li>
          <li>Follow the guidelines in the Cisco API style guide
            <a href="https://apistyleguide.cisco.com/#!apix-design" target="_blank">Designing APIs</a> section</li>
        </ul> 
    </div>
    <div class="col-1"></div>
</div>
<div class="row base-margin-bottom content-txt">
    <div class="col-1"></div>
    <div class="col-10">
        <h2><a name="APIversioning">API Versioning</a></h2>
<ul>
  <li>Needed to accommodate introduction of non-backward compatible API changes.</li>
  <li>Understand the difference between versions and revisions.</li>
  <li>Use the “API design-first approach” to avoid breaking changes.</li>
  <li>Plan for versioning in the first API release.</li>
  <li>Customers will continue to use older API versions. They must be supported.</li>
  <li>Keep a change log in your documentation. </li>
  <li>Maintain documentation of older versions. </li>
  <li>Follow the guidelines in the Cisco API style guide
    <a href="https://apistyleguide.cisco.com/#!apix-versioning" target="_blank">Versioning APIs</a> section</li>
</ul>    
    </div>
    <div class="col-1"></div>
</div>
<div class="row base-margin-bottom content-txt">
    <div class="col-1"></div>
    <div class="col-10">
        <h2><a name="REST">REST</a></h2>
        <p>We will follow the REST API best practices defined in the Cisco API style guide
          <a href="https://apistyleguide.cisco.com/#!rest-recommendations" target="_blank">
            Web APIs and REST Design </a> section.</p>
        <p>The <a href="https://apistyleguide.cisco.com/#!rest-recommendations" target="_blank">Recommendations</a>
         section is a checklist on what needs to be taken into account and added to the
         scope of a new API product or a new version of an existing API product.
         There are links to later detailed sections.</p>
    </div>
    <div class="col-1"></div>
</div>
    
    </div>
  );
}
export default ApiBestPractice;