/**
 * Created by aaikepae on 11/4/16.
 */


export default class Router {

  /**
   * @property
   */
  set mode(value) {
    if (this._mode !== value) {
      if (value == 'route') {
        this._unRegisterCaptureEvent();
        this._registerHashEvent();
        this._unRegisterHistoryEvent();
      } else if (value == "disable") {
        this._unRegisterCaptureEvent();
        this._unRegisterHashEvent();
        this._unRegisterHistoryEvent();
      } else if (value === 'history') {
        this._registerHistoryEvent();
        this._unRegisterCaptureEvent();
        this._unRegisterHashEvent();
      } else {
        this._registerCaptureEvent();
        this._unRegisterHashEvent();
        this._unRegisterHistoryEvent();
      }
    }
    this._mode = value;
  }

  get mode() {
    return this._mode;
  }

  /**
   * @property
   */

  set queryString(value) {
    if (value !== this._queryString) {
      this._queryString = value;
      this.triggerChange()
    }
    if (this._mode === 'history') {
      // Great regex from https://stackoverflow.com/a/17682564/9166968
      window.history.pushState(null, '', this.baseURL + value.replace(/\/?(\?|#|$)/, '/$1'));
    }
  }

  get queryString() {
    return this._queryString;
  }


  constructor() {
    this.detectTag = "A";
    this.detechAttribute = "data-tag";
    this.detechAttributeValue = "navigation-item";
    this.dataAttribute = "data-url";
    this.mouserevent = null;

    this.baseURL = '';

    this.delegateContainer = null;

    this._hashHandler = this._hashHandler.bind(this);
    this._capture = this._capture.bind(this);
    this._historyHandler = this._historyHandler.bind(this);
    this.mode = 'route';
  }


  onChange(fn) {
    this._onChangeFn = fn;
  }

  triggerChange() {
    if (this._onChangeFn) {
      this._onChangeFn.call(null, this.mouserevent, this);
    }
  }

  getQueryString() {
    var _queryString = null;
    if (this.baseURL) {
      _queryString = this._getURLQueryString();
    } else {
      _queryString = this._getHashQueryString();
    }

    return _queryString;
  }

  _getURLQueryString() {
    // if same return null
    if (location.pathname == this.baseURL) {
      return null
    }

    const path = location.pathname.substring(this.baseURL.length);
    return path.replace(/\/$/, '') + (this._mode === 'history' ? location.search + location.hash : '');
  }

  _getHashQueryString() {
    let queryString = window.location.hash.substr(1);
    if (queryString.slice(0, 1) === "!") {
      queryString = queryString.slice(1);
    }
    if (queryString.substr(0, 1) === "/") {
      queryString = queryString.substr(1);
    }
    return queryString != '' ? queryString : null;
  }


  navigateTo(url) {
    if (url === null) {
      return
    }
    if (this.mode == "route") {
      location.hash = '#' + url;
    } else {
      this.queryString = url;
    }
  }

  generateLink(url) {
    if(!url){
      return null;
    }
    if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0 || url.indexOf("//") == 0 || url.indexOf("mail") == 0) {
      return url;
    } else {
      return this.baseURL ? this.baseURL + url : "#!" + url
    }
  }

  clear() {
    this.baseURL = null;
    // this._onChangeFn = null;
    this._queryString = null;
    this._unRegisterCaptureEvent();
    this._unRegisterHashEvent();
  }


  _hashHandler(event) {
    if (this.mode !== "disable" && this.getQueryString) {
      this.queryString = this.getQueryString()
    }
  }

  _registerHashEvent() {
    window.addEventListener("hashchange", this._hashHandler, false);
  }

  _unRegisterHashEvent() {
    window.removeEventListener("hashchange", this._hashHandler, false);
  }


  _registerCaptureEvent() {
    if (this.delegateContainer) {
      this.delegateContainer.addEventListener("click", this._capture, true);
    }

  }

  _unRegisterCaptureEvent() {
    if (this.delegateContainer) {
      this.delegateContainer.removeEventListener("click", this._capture, true);
    }
  }

  _historyHandler(e) {
    if (this.getQueryString() !== this._queryString) {
      this._queryString = this.getQueryString();
      this.triggerChange()
    }
  }

  _registerHistoryEvent() {
    window.addEventListener('popstate', this._historyHandler);
  }

  _unRegisterHistoryEvent() {
    window.removeEventListener("popstate", this._historyHandler);
  }

  _capture(event) {
    var src = event.target || event.srcElement;
    var source = null;
    while (src) {
      if (src.nodeName == this.detectTag && src.getAttribute(this.detechAttribute) == this.detechAttributeValue) {
        source = src;
        break;
      }

      if (src.parentNode) {
        src = src.parentNode
      } else {
        break;
      }
    }

    if (source) {
      this.mouserevent = event;
      this.queryString = source.getAttribute(this.dataAttribute);
      if (this.mode == "silent") {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

}
