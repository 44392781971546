import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from "react";
import "./_ClientApp.scss";
import SectionHeader from "../SectionHeader/SectionHeader";
import { PIECOLOR } from "../../../../common/base/constants";
import CommonChart from "../CommonChart";
import Loader from '../../../../common/base/Loader/Loader'
import { generateMetricsPDF } from "../../../../common/utility/utils";

const ClientApp = forwardRef(({ apiResponse, loading }, ref) => {
  const isMounted = useRef(false);
  const [data, setData] = useState({});


  const generatePDF = (selectedIds, selectedDate) => {
    const chart = {
      chartRefs: [],
      chartData: [],
      onlyTable: true
    }
    generateMetricsPDF(chart, 'Client Applications', selectedIds, selectedDate, apiResponse);
  }

  useImperativeHandle(ref, () => ({
    generatePDF: (selectedIds, selectedDate) => {
      generatePDF(selectedIds, selectedDate);
    },
  }));

//   const { showLoader, hideLoader } = useCombined();

  // const data = {
  //     labels: ['0oagyclhsrSssenE1d7', '0oagyclhsrS8y1EnE1d7', '0oagyclhsrkjU1EnE1d7', '2oagyclhsrS8U1EnE1d7', '9oagyclhsrS9f1EnE1d7'],
  //     datasets: [
  //       {
  //         label: 'total',
  //         data: [1,2,3,4,5],
  //         backgroundColor: PIECOLOR,
  //         borderColor: PIECOLOR
  //       }
  //     ]

  // }

  useEffect(() => {
    isMounted.current = true;
    if (apiResponse && apiResponse.results) {
      let results = apiResponse.results;
      let transData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: PIECOLOR,
            borderColor: PIECOLOR,
          },
        ],
      };
      results[0].metrics.forEach((metric) => {
        if (metric.tags.client_id) {
            transData.labels.push(metric.tags.client_id);
           transData.datasets[0].data.push(metric.values[0][1] ?? 0);
        }
      });
      
      if(isMounted.current) {
          setData(transData);
      }
    }

    return () => {
        isMounted.current = false;
    };
  }, [apiResponse]);

  if(loading) {
    return <Loader />
  }

  return (
    <div className="row p-4">
      <div className="card px-3 pb-4">
        <div className="row px-3 py-4">
          <SectionHeader type="REQUESTS_BY_CLIENT_ID" />
        </div>
        <div className="row">
          {/* <div className='col-6'>
                            <CommonChart
                                    expand={false}
                                    type="pie"
                                    chartData={data}
                                    style="position: relative; height:10vh; width:10vw"
                                    legend={true}
                                />
                        </div> */}
          <div className="col-12">
            <table className="table iap-table mt-4">
              <thead>
                <tr>
                  <th>Client ID{` (${data.labels?.length ?? 0})`}</th>
                  <th>Requests</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  data.labels &&
                  data.labels.length &&
                  data.labels.length > 0 &&
                  data.labels?.map((cId, index) => (
                    <tr key={index}>
                      <td>{cId}</td>
                      <td>{data.datasets[0].data[index]}</td>
                    </tr>
                  ))}
                { loading ||
                  !data.labels ||
                  (data.labels.length === 0) && (
                                  <tr>
                                      <td colSpan="7">
                                          <div className='text-center empty-state'>
                                              <img
                                                  src="https://www.cisco.com/c/dam/cdc/ui/atm/1-5-0/images/illustrations/empty-state/no-results-light-large.svg"
                                                  alt="Empty state illustration"
                                                  width="240px"
                                                  height="160px"
                                              />
                                              <p className='text-center mb-0'>No Records found</p>
                                          </div>
                                      </td>
                                  </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
})

export default ClientApp;
