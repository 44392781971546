import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Loader.scss';
const Loader = () => {
    return (
        <div className="spinner-overlay">
            <div className="spinner-container">
                <Spinner animation="border" />
            </div>
        </div>
    );
};

export default Loader;
