import React, { useEffect, useRef } from "react";
import "./_SectionHeader.scss";

export const headerData = {
  REQUESTS_BY_CLIENT_ID: {
    title: "Request By Client ID",
    desc: "Sum of requests in the period, grouped by Client ID",
  },
  request_by_volume: {
    title: "Request By Volume",
    desc: "Sum of requests in the period for the given API",
  },
  failed_request: {
    title: "Failed Request",
    desc: "Sum of failed requests in the period for the given API",
  },
  REQUESTS_VOLUME: {
    title: "Requests Volume",
    desc: "Sum of requests in the period for the given API",
  },
  FAILED_REQUESTS: {
    title: "Failed Requests",
    desc: "Sum of failed requests in the period for the given API",
  },
  TOTAL_4XX_REQUESTS: {
    title: "Total 4xx Errors",
    desc: "Sum of 4xx errors in the period for the given API",
  },
  TOTAL_5XX_REQUESTS: {
    title: "Total 5xx Errors",
    desc: "Sum of 5xx errors in the period for the given API",
  },
  AVERAGE_RESPONSE_TIME: {
    title: "Average Response Time",
    desc: "Average response time of requests in the period, for the given API",
  },
  REQUESTS_BY_METHOD: {
    title: "Requests By Method",
    desc: "Sum of requests in the period, grouped by method",
  },
  REQUESTS_BY_RESPONSE_CODE: {
    title: "Requests By Status Code",
    desc: "Sum of requests in the period, grouped by response code",
  },
  REQUESTS_BY_REQUEST_SIZE: {
    title: "Requests By Request Size",
    desc: "Sum of requests in the period, grouped by request size",
  },
  REQUESTS_BY_RESPONSE_SIZE: {
    title: "Requests By Response Size",
    desc: "Sum of requests in the period, grouped by response size",
  },
  FAILURES_BY_METHOD: {
    title: "Failures By Method",
    desc: "Sum of failed requests in the period, grouped by method",
  },
  FAILURES_BY_RESPONSE_CODE: {
    title: "Failures By Status Code",
    desc: "Sum of failed requests in the period, grouped by response code",
  },
  FAILURES_BY_REQUEST_SIZE: {
    title: "Failures By Request Size",
    desc: "Sum of failed requests in the period, grouped by request size",
  },
  FAILURES_BY_RESPONSE_SIZE: {
    title: "Failures By Response Size",
    desc: "Sum of failed requests in the period, grouped by response size",
  },
};

const SectionHeader = ({ type, showMenu = true, onMenuSelect }) => {
  const menuRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    menuRef.current?.addEventListener("hbr-checked", handleMenuSelect);

    return () => {
      isMounted.current = false;
      menuRef.current?.removeEventListener("hbr-checked", handleMenuSelect);
    };
  }, [menuRef.current]);

  const handleMenuSelect = (e) => {
    if (isMounted.current) {
      onMenuSelect && onMenuSelect(e.target.value);
      setTimeout(() => e.target.removeAttribute("checked"), 100);
    }
  };

  if (!type || !headerData[type]) {
    return <></>;
  }

  return (
    <div className="section-header-container d-flex justify-content-between">
      <div className="d-flex">
        <span className="title">{headerData[type].title}</span>
        <span className="spliter mx-2">|</span>
        <hbr-tooltip
          content={headerData[type].desc}
          placement="top"
          class="atm-tooltip--large"
        >
          <i className="atm-icon info-outline" />
        </hbr-tooltip>
      </div>

      {/* {showMenu && (
        <div className="menu">
          <hbr-dropdown
            hoist={true}
            menu={true}
            size="small"
          >
            <hbr-icon
              name="dots-three-vertical"
              slot="trigger"
              style={{ fontSize: "24px", cursor: "pointer" }}
            ></hbr-icon>

            <hbr-menu
              hoist={true}
              style={{ "--width": "200px", overflow: "hidden" }}
              ref={menuRef}
            >
              <hbr-menu-item value="expand">
                <i
                  slot="prefix"
                  class="atm-icon fullscreen"
                />
                Expand
              </hbr-menu-item>
              <hbr-menu-item value="export">
                <i
                  slot="prefix"
                  class="atm-icon download"
                />
                Export CSV
              </hbr-menu-item>
            </hbr-menu>
          </hbr-dropdown>
        </div>
      )} */}
    </div>
  );
};
export default SectionHeader;
