/**
 * Created by aaikepae on 11/3/16.
 */

import axios from "axios"


export function clearData() {
  return {
    type: "CLEAR"
  }
}
export function fetchData(data) {
  return function (dispatch, getState) {
    dispatch({
      type: "FETCH_META",
      payload: data["meta"]
    });

    dispatch({
      type: "FETCH_CONFIG",
      payload: data["config"]
    });
  };
}


export function loadContent(url) {
  return function (dispatch, getState) {
    const state = getState();
    const preferenceLanguage = state.preference.language;
    let language = state.config.defaultLanguage;

    //if doc has multiple language
    if (state.meta.languages) {
      // if preference in site's language
      if (state.meta.languages.some((item)=>item.code == preferenceLanguage)) {
        language = preferenceLanguage
      }
    }
   // let file = state.config.location + url + ".html"; //for local changes
   let file = state.config.location + language + "/" + url + ".html";
    if (window.sessionStorage.getItem("isAuthenticated")) {
      file = '/auth' + file;
    }
    const commitid = document.querySelector("[name=commitid]");
   // const id = "";//commitid ? commitid.getAttribute("content") : Math.random();
    const node = document.querySelector(`[pubhub-html="${file}"]`);

    if (node) {
      dispatch({
        type: "FETCH_CONTENT_FULFILLED",
        payload: { data: node.innerHTML },
      });
    } else {
      dispatch({
        type: "FETCH_CONTENT",
        payload: axios.get(file)
      });
    }
  }
}

