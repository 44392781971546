import initialState from '../store/initialState';

import {
    SET_LOADER,
    CATCH_ERROR,
    FETCH_APPLICTION,
    DELETE_APPLICATION,
    GET_APPLICATION_DETAIL,
    RESET_APPLICATION,
    UPDATE_APPLICATION,

    FETCH_API_INSTANCE,
    APPLICATION_INSTANCE,
    GET_SLA_TIERS,
    CREATE_REQUEST,
    CREATE_APPLICATION,
    RESET_ACTION,
    REFETCH_APPLICATION
} from '../actions/type';

export default function reducer(state = initialState.request, action) {
    switch(action.type) {
        case SET_LOADER:
            return { ...state, isLoading: action.payload }
        case CATCH_ERROR:
            return { ...state, errorResponse: action.payload }
        case FETCH_APPLICTION:
            return { ...state, applicationList: action.payload }
        case DELETE_APPLICATION:
            return { ...state, deleteResponse: action.payload }
        case GET_APPLICATION_DETAIL:
            return { ...state, applicationDetail: action.payload }
        case RESET_APPLICATION:
            return { ...state, resetResponse: action.payload }
        case UPDATE_APPLICATION:
            return { ...state, response: action.payload }
        case FETCH_API_INSTANCE:
            return { ...state, apiInstances: action.payload }
        case APPLICATION_INSTANCE:
            return { ...state, applicationInsatnce: action.payload }
        case GET_SLA_TIERS:
            return { ...state, slaTiers: action.payload }
        case CREATE_REQUEST:
            return { ...state, response: action.payload }
        case CREATE_APPLICATION:
            return { ...state, response: action.payload }
        case RESET_ACTION:
            return { ...state, errorResponse: [], response: [] }
        case REFETCH_APPLICATION:
            return { ...state, refetchApplication: action.payload }
        default: return state;
    }
}
