import axios from "axios";
import {
    SET_LOADER,
    CATCH_ERROR,
    FETCH_APPLICTION,
    DELETE_APPLICATION,
    GET_APPLICATION_DETAIL,
    RESET_APPLICATION,
    UPDATE_APPLICATION,
    RESET_ACTION
} from "./type";
import { API_PATHS, bearerToken } from "./constants";

export const setLoader = (loader) => (dispatch) => {
    dispatch({ type: SET_LOADER, payload: loader })
}

export const resetAction = () => (dispatch) => {
  dispatch({ type: RESET_ACTION, payload: {} });
};

  export const fetchApplicationsList = (organizationId, currentPage, pageSize, searchTxt, assetId, versionGroup) => {
    const addOrgId = organizationId ? `/organizations/${organizationId}` : '';
    const addAssetAndVersion = (assetId && versionGroup) 
    ? `&assetId=${assetId}&version=${versionGroup}` 
    : ''; 
    return (dispatch) => {
      axios
        .get(`${API_PATHS.REQUEST_PAGE_API}${addOrgId}/applications${addOrgId ? "/" : ""}?page=${currentPage}&size=${pageSize}&searchText=${searchTxt}${addAssetAndVersion}`)
        .then((res) => {
          dispatch({
            type: FETCH_APPLICTION,
            payload: {
              data: res.data,
              status: true
            },
          })
        })
        .catch((err) => {
          dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data, status: false }})
        })
    }
  }

  export const updateApplicationDetail = (organizationId, appId, updatedData) => {
    return (dispatch) => {
      axios
        .patch(`${API_PATHS.REQUEST_PAGE_API}/${organizationId}/applications/${appId}`, {...updatedData})
        .then((res) => {
          dispatch({
            type: UPDATE_APPLICATION,
            payload: {
              data: res.data,
            },
          })
        })
        .catch((err) => {
          dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data, status: false }})
        })
    }
  }

  // export const getApplicationDetail = (organizationId, appId) => {
  //   const addOrgId = organizationId ? `/organizations/${organizationId}` : '';
  //   return (dispatch) => {
  //     axios
  //       .get(`${API_PATHS.REQUEST_PAGE_API}${addOrgId}/applications/${appId}`)
  //       .then((res) => {
            
  //         dispatch({
  //           type: GET_APPLICATION_DETAIL,
  //           payload: {
  //             status: true,
  //             data: res?.data,
  //           },
  //         })
  //       })
  //       .catch((err) => {
  //         dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data, status: false }})
  //       })
  //   }
  // }

  export const resetSecretApplication = (organizationId, appId, callback=null) => {
    const addOrgId = organizationId ? `/organizations/${organizationId}` : '';
    return (dispatch) => {
      axios
        .post(`${API_PATHS.REQUEST_PAGE_API}${addOrgId}/applications/${appId}/secret/reset`)
        .then((res) => {
            console.log("fetch_request ",res);
          dispatch({
            type: RESET_APPLICATION,
            payload: {
              status: true,
              data: res.data,
            },
          })
          if(callback) {
            callback(res.data);
          }
        })
        .catch((err) => {
          dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data, status: false }})
        })
    }
  }

  export const deleteApplicationDetail = (organizationId, appId) => {
    const addOrgId = organizationId ? `/organizations/${organizationId}` : '';
    return (dispatch) => {
      axios
        .delete(`${API_PATHS.REQUEST_PAGE_API}${addOrgId}/applications/${appId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        })
        .then((res) => {
            console.log("delete_request ",res);
            dispatch({ type: DELETE_APPLICATION, payload: { data: {
              message: "Deleted Successfully"
            }, status: true }})
        })
        .catch((err) => {
          dispatch({ type: CATCH_ERROR, payload: { data: err.response?.data, status: false }})
        })
    }
  }


