import {combineReducers} from "redux"

import meta from "./meta"
import config from "./config"
import content from "./content"
import preference from "./preference"
import accessReducer from "./access"
import request from "./request"

export default combineReducers({
  meta,
  config,
  content,
  preference,
  access: accessReducer,
  request

})
