/**
 * Created by aaikepae on 11/4/16.
 */

import axios from "axios"
import SearchItem from "../util/keyword";

export function loadsearch(value) {
  return function (dispatch, getState) {
    const state = getState();
    const SearchAPI = (window.PubHub.APIHost || "https://devnet.cisco.com") + "/v1/foundation-search/";

    const queryKeyMatch = value.match(/[\?|&]q=([^&]*)/);
    const params = queryKeyMatch ? queryKeyMatch : value.split("/");
    const keyword = decodeURIComponent(params[1]);
    const searchItem = SearchItem.parse(keyword);
    const searchingIndexes = !searchItem.type ? "pubhubdoccontent_v2,pubhubapicontent_v2" : {
      doc: "pubhubdoccontent_v2",
      api: "pubhubapicontent_v2"
    }[searchItem.type];

    let page = parseInt(params[2] || 1);
    const queryPageMatch = value.match(/[\?|&]page=([^&]*)/);
    if (queryPageMatch && queryPageMatch[1]) {
      page = parseInt(queryPageMatch[1]);
    }

    dispatch({
      type: "CLEAR_SELECTED"
    });

    dispatch({
      type: "UPDATE_STATE",
      payload: {
        "key": "state",
        "value": "searching"
      }
    });

    dispatch({
      type: "UPDATE_STATE",
      payload: {
        "key": "keyword",
        "value": keyword
      }
    });
    dispatch({
      type: "UPDATE_STATE",
      payload: {
        "key": "page",
        "value": page
      }
    });

    dispatch({
      type: "FETCH_SEARCH",
      payload: axios.post(`${SearchAPI}projects/doc/indexes/${searchingIndexes}/query`, {
        key: searchItem.keyword,
        pageNum: page,
        pageSize: 10,
        filters: [
          {
            field: "url",
            value: state.meta.url_path,
            type: "prefix"
          }
        ],
        sortBy: [
          {
            field: "importance",
            asc: false
          },
          {
            field: "last_modified",
            asc: false
          }
        ]
      }, {
        withCredentials: true
      })
    });
  };
}

export function loadContent(value) {
  return function (dispatch, getState) {
    const state = getState();
    var commitid = document.querySelector("[name=commitid]");
   // var id = "";//commitid ? commitid.getAttribute("content") : Math.random();
    var withCredentials = true;//!!state.config.location.match(/developer.*.cisco.com/);
    let contentVal = {}
    if(value && value.content && value.type != 'doc_page') {
      contentVal = axios.get(state.config.location + value.content, {
        withCredentials: withCredentials
      })
    }
    dispatch({
      type: "FETCH_STATIC_CONTENT",
      payload: contentVal
    });
  };
}
