
export function generateCardsJson(config) {
    const cards = Array.from(config.querySelectorAll('cards > card')).map(card => ({
      title: card.querySelector('title')?.textContent.trim(),
      pictoref: card.querySelector('pictoref')?.textContent.trim() || null,
      description: card.querySelector('description')?.innerHTML.trim(),
      link: card.querySelector('link')?.textContent.trim(),
      pageLink: card.querySelector('pagelink')?.textContent.trim(),
      icon: card.querySelector('icon')?.textContent.trim(),
      content: card.querySelector('content')?.innerHTML.trim()
    }));
    return cards;
}

export function generateErrorMessage(config) {
    const data = {
        title:config.querySelector('title')?.textContent.trim(),
        message:config.querySelector('message')?.textContent.trim(),
        imageSrc:config.querySelector('imageSrc')?.textContent.trim(),
        width:config.querySelector('imageSrc')?.getAttribute('width')?.trim(),
        height:config.querySelector('imageSrc')?.getAttribute('height')?.trim(),
        bannerHeight:config?.getAttribute('height')?.trim(),
        errorActionBtn:config.querySelector('errorActionBtn')?.textContent.trim(),
    };

    if(data.errorActionBtn) {
        data.actionLink = config.querySelector('errorActionBtn').getAttribute('link')?.trim()
    }

    return data;

}