/**
 * Created by aaikepae on 11/3/16.
 */

import {getCookie} from "../../common/utility/cookie"
import {getLanguage} from "../../common/utility/browser"

var obj = {
  meta: {
    languages: [{"code": "en", "name": "English"}]
  },
  config: {
    location: null,
    defaultLanguage: "en"
  },
  preference: {
    language: getCookie("devnet_language") || getLanguage()
  },
  content: null,
  access: {
    isLoading: false,
    response: [],
    accessRequestList: [],
    pendingRequestList: [],
    errorResponse: []
  },
  request: {
    isLoading: false,
    response: [],
    applicationList: [],
    applicationDetail: [],
    apiInstances: [],
    applicationInsatnce: [],
    slaTiers: [],
    errorResponse: [],
    deleteResponse: [],
    resetResponse: [],
    refetchApplication: 0
  }
};

export default obj;
