import React from "react";
import { generateConfigData, generateInfoCard, processLink, generateLink } from "Common/utility/infoCard"
import GlobalSearch from "../../../js/siteEngine/components/GlobalSearch";
import { removeBR } from "../../../js/common/utility/utils";


export default class PortalBanner extends React.Component {
    render() {
        const { config, location, type } = this.props;
        const isAuthenticated = window.sessionStorage.getItem("isAuthenticated");

        if (!config) {
            return <span>Loading</span>
        }
        
        function getHref(uri) {
            return `${isAuthenticated ? '/auth' : ''}/${uri}/`;
        }
        
        const data = generateConfigData(config);
        return (
            <>
            <div className="portal-banner  align-content-center ">
                
                <div class="banner">
                    <div className="wrap-square">

                        <div class="spinning-squares">
                            <div class="square square-1"></div>
                            <div class="square square-2"></div>
                        </div>
                        <div class="spinning-squares-2">
                            <div class="square square-1"></div>
                            <div class="square square-2"></div>
                        </div>
                    </div>
                <div className="row banner-content align-content-center section-padding">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">

                            <h1 className="header mt-0 text-sm-center text-lg-left">
                                {data.title}
                            </h1>
                            <div className="col-12 col-lg-8 text-sm-center text-lg-left pl-0">
                                <p className="description-ph mb-5">{removeBR(data.description)}</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            {data.search && (
                                <GlobalSearch className="" />
                            )}
                            {type !== "whatsNew" && 
                            <div class="d-flex mt-5 justify-content-lg-end justify-content-center">
                                <div className="col-12 col-sm-10 col-md-10 col-lg-12 col-xl-10 whats-new p-4">
                                    <h5 className="whatsNew-header">What’s New on Cisco IT API Portal!</h5>
                                    <div className="row">
                                        <div className="col-9 col-md-12 col-lg-8 col-sm-12 sub-text">
                                            Learn all about the changes happening to the IT API Portal! From new features that have
                                            recently launched, to features that are on their way.
                                        </div>
                                        <div className="col-lg-4 col-md-12
                                        mt-3 align-self-end text-end">
                                            <a class="whats-new-button" href={getHref("whats-new")}><hbr-button submit="true" type="submit" variant="fill" size="medium">
                                                Learn More
                                            </hbr-button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

                {/* <div className="portal-banner align-content-center section-padding" style={{ backgroundImage: `url(${data.image.src})` }}>
                    <div className="row banner-content align-content-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">

                            <h1 className="header mt-0 text-sm-center text-lg-left">
                                {data.title}
                            </h1>
                            <div className="col-12 col-lg-8 text-sm-center text-lg-left pl-0">
                                <p className="description-ph mb-5">{removeBR(data.description)}</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            {data.search && (
                                <GlobalSearch className="" />
                            )}
                            {type !== "whatsNew" && 
                            <div class="d-flex mt-5 justify-content-lg-end justify-content-center">
                                <div className="col-12 col-sm-10 col-md-10 col-lg-12 col-xl-10 whats-new p-4">
                                    <h5 className="whatsNew-header">What’s New on Cisco IT API Portal!</h5>
                                    <div className="row">
                                        <div className="col-9 col-md-12 col-lg-8 col-sm-12 sub-text">
                                            Learn all about the changes happening to the IT API Portal! From new features that have
                                            recently launched, to features that are on their way.
                                        </div>
                                        <div className="col-lg-4 col-md-12
                                        mt-3 align-self-end text-end">
                                            <a class="whats-new-button" href={getHref("whats-new")}><hbr-button submit="true" type="submit" variant="fill" size="medium">
                                                Learn More
                                            </hbr-button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div> */}
            </>
        )
    }
}
