/**
 * Created by aaikepae on 3/27/17.
 */
import React from "react"

//performance improve
//cache dom and variables
var scrollToSelectedItemInNavTimer = null;
var container, nav, doc, navScroll, navBody, codePanel, codePanelContent;
var docBound, navBound, docVisibleHeight, navBodyBound;

import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

const ScreenSize = {
  "xs": 0, // 0 -543px
  "sm": 768, // 544px - 768px
  "md": 992, // 768px - 992px
  "lg": 1200, //992px - 1200px
};


const requestAnimationFrame = window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame;


export default class DocContainer extends React.Component {

  componentDidMount() {

    container = this.refs["root"];
    nav = container.querySelector(".pubhub-nav-container");
    navBody = container.querySelector(".pubhub-nav-body");
    navScroll = container.querySelector(".pubhub-nav-scroll");
    codePanel = container.querySelector(".pubhub-doc-code-panel");

    this.rid = null;

    this.detectPlatform();

    this.updateUI = this.updateUI.bind(this);

  }

  detectPlatform() {
    const bound = container.getBoundingClientRect();
    let platform = null;

    if (bound.width < ScreenSize.md) {
      platform = "sm"
    } else if (bound.width < ScreenSize.lg) {
      platform = "md"
    } else if (bound.width >= ScreenSize.lg && bound.height) {
      platform = "lg"
    }


    if (this.props.config._platform !== platform) {
      container.setAttribute("data-platform", platform);
      navScroll.setAttribute("data-platform", platform);

      this.props.dispatch({
        type: "UPDATE_CONFIG",
        payload: {
          key: "_platform",
          value: platform
        }
      })

    }


  }


  updateUI() {
    this.detectPlatform();
    if (this.props.config._platform !== "sm") {
    //  this.updateContainerHeight();
    //  this.updateNavSize();
    } else if (nav.style.top) {
      nav.style.top = '';
    }

    this.rid = requestAnimationFrame(this.updateUI);
  }


  updateContainerHeight() {
    doc = container.querySelector(".pubhub-doc-container");
    if (!doc) {
      return
    }

    const {config} = this.props;

    docBound = doc.getBoundingClientRect();

    //scroll nav with body and fix it after _navToOffset
    if (config["_navTopOffset"] !== null) {
      const top = Math.max(docBound.top, config["_navTopOffset"]) + "px";
      nav.style.top = top;
      codePanel.style.top = top;
    }

    let breadcrumbTop = 0;

    if (config.useBreadcrumb) {
      breadcrumbTop = 62;
      const top = Math.max(docBound.top, breadcrumbTop) + "px";
      nav.style.top = top;
      codePanel.style.top = top;
    }


    navBound = nav.getBoundingClientRect();
    navBodyBound = navBody.getBoundingClientRect();
    docVisibleHeight = window.innerHeight - navBound.top;

    let height = Math.max(docVisibleHeight, docBound.height + breadcrumbTop);

    //both nav and body height is smaller than visible area
    // if (navBodyBound.height < docVisibleHeight && docBound.height < docVisibleHeight) {
    //   height = docVisibleHeight; //Math.max(navBodyBound.height, docBound.height, 300) + 15;
    //   doc.style.minHeight = height + "px";
    // } else if (docBound.height < docVisibleHeight) {
    //   height = docVisibleHeight
    // } else {
    //   height = docBound.height;
    // }

    container.style.height = height + "px";
    // doc.style.minHeight = height + "px";

    return height;

  }


  updateNavSize() {

    //optimize
    if (!navBound || !navBodyBound) {
      return
    }

    const docVisibleHeight = window.innerHeight - navBound.top;
    const containerBound = container.getBoundingClientRect();
    const containerVisibleHeight = containerBound.height + containerBound.top - navBound.top;

    // if containerVisibleHeight larger than docVisibleHeight, limit nav height to docVisibleHeight
    // else shrunk nav height
    const navHeight = containerVisibleHeight > docVisibleHeight ? docVisibleHeight : containerVisibleHeight;
    navScroll.style.maxHeight = navHeight + "px";
    codePanel.style.height = navHeight + "px";
    navScroll.setAttribute("data-height", navHeight);
    // codePanel.setAttribute("data-height", navHeight);

  }

  /**
   * show selected item when first time loaded
   * @private
   */


  scrollToSelectedItemInNav() {
    if (scrollToSelectedItemInNavTimer) {
      clearTimeout(scrollToSelectedItemInNavTimer)
    }
    scrollToSelectedItemInNavTimer = setTimeout(this._scrollToSelectedItemInNav.bind(this), 700)
  }


  _scrollToSelectedItemInNav() {


    const activeItem = Array.from(nav.querySelectorAll("li.active")).pop();
    if (!activeItem) {
      return
    }


    const navOffSet = navScroll.getBoundingClientRect();
    const activeItemOffset = activeItem.getBoundingClientRect();


    if (activeItemOffset.top < navOffSet.top) {
      navScroll.scrollTop -= navOffSet.top - activeItemOffset.top + navScroll.height / 2;
    } else if (activeItemOffset.top > navOffSet.top + navOffSet.height) {
      navScroll.scrollTop += activeItemOffset.top - navOffSet.top - navOffSet.height / 2
    }

  }


  componentDidUpdate(prevProps, prevState) {
    const {selected, search} = this.props;
    // if there is content
    if (
      (selected && selected.data && prevProps.selected.data != selected.data) ||
      (search && search.state)
    ) {

      if (this.rid) {
        cancelAnimationFrame(this.rid)
      }

      this.rid = requestAnimationFrame(this.updateUI);

      //
      selected["data"] && this.scrollToSelectedItemInNav()

    }
  }

  componentWillUnmount() {
    if (scrollToSelectedItemInNavTimer) {
      clearTimeout(scrollToSelectedItemInNavTimer)
    }

    if (this.rid) {
      cancelAnimationFrame(this.rid)
    }

  }

  render() {
    return <div id="pubhub-container" className="pubhub-container expend" ref="root">
       <Alert stack={{limit: 3}} />
      {this.props.children}
    </div>
  }
}
