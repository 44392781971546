// src/Pagination.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './_tablePagination.scss';

const TablePaginationNew = ({ tableConfig, onPageChange, onRowsPerPageChange }) => {
  const { currentPage, totalPages, rowsPerPage, pageLimit } = tableConfig;
  const pageNumbers = [];
  const maxPageNumbersToShow = 5;

  // Helper function to create pagination items
  const createPageNumbers = () => {
    if (totalPages <= maxPageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfWindow = Math.floor(maxPageNumbersToShow / 2);
      let startPage = Math.max(1, currentPage - halfWindow);
      let endPage = Math.min(totalPages, currentPage + halfWindow);

      if (currentPage <= halfWindow) {
        endPage = maxPageNumbersToShow;
      } else if (currentPage + halfWindow >= totalPages) {
        startPage = totalPages - maxPageNumbersToShow + 1;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }
  };

  createPageNumbers();

  return (
    <div className="d-flex align-items-center mt-4 pagination-custom">
      <div className="d-flex align-items-center mr-2">
        <span className="me-2 select-label">Rows per page:</span>
        <select
          className="form-select"
          value={rowsPerPage}
          onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
        >
            {pageLimit.map((val) => {
                return (
                    <option key={val} value={val}>
                        {val}
                    </option>
                )
            })}
        </select>
      </div>
      <ul className="pagination m-0">

        <li className="page-item">
            <button
                className={`d-flex page-link next-prev-icon  ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <hbr-icon name="caret-left" size="18px" style={{marginRight: "5px"}}></hbr-icon> Previous
            </button>
        </li>

        {/* Display first page and "..." if necessary */}
        {currentPage > maxPageNumbersToShow && (
          <>
            <li className="page-item">
              <button className="page-link item-link" onClick={() => onPageChange(1)}>
                1
              </button>
            </li>
            <li className="page-item disabled">
              <span className="page-link item-link">...</span>
            </li>
          </>
        )}

        {/* Display page numbers */}
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <button
              className="page-link item-link"
              onClick={() => onPageChange(number)}
            >
              {number}
            </button>
          </li>
        ))}

        {/* Display last page and "..." if necessary */}
        {currentPage + Math.floor(maxPageNumbersToShow / 2) < totalPages && (
          <>
            <li className="page-item disabled">
              <span className="page-link item-link">...</span>
            </li>
            <li className="page-item">
              <button className="page-link item-link" onClick={() => onPageChange(totalPages)}>
                {totalPages}
              </button>
            </li>
          </>
        )}

        <li className="page-item">
        <button
            className={`d-flex page-link next-prev-icon ${currentPage === totalPages ? "disabled" : ""}`}
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
        >
            Next <hbr-icon name="caret-right" size="18px" style={{marginLeft: "5px"}}></hbr-icon>
        </button>
        </li>
      </ul>
    </div>
  );
};

export default TablePaginationNew;
