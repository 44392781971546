import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CommonChart from "../CommonChart";
import { COLOR_PALETTE } from "../../../../common/base/constants";
import SectionHeader from "../SectionHeader/SectionHeader";
import "./_RequestsFailures.scss";
import { consolidateMetrics, generateMetricsPDF, getDate } from "../../../../common/utility/utils";

const RequestsFailures = forwardRef(({ type, apiResponse, selectedDate, loading }, ref) => {
  const isMounted = useRef(false);
  const [response, setResponse] = useState([]);
  const chartsData = useRef([]);
  const chartRefs = [useRef(), useRef(), useRef(), useRef()];

  const sizeLabels = [
    "0 bytes - 100 bytes",
    "100 bytes - 1 KB",
    "1 KB - 1 MB",
    "1 MB - 10 MB",
    "10 MB - 100 MB",
    "100 MB - 1 GB",
    "> 1 GB",
  ];

  const getAllChartsData = {
    [`${type}_BY_METHOD`]: {
      sizeLabels: null
    },
    [`${type}_BY_RESPONSE_CODE`]: {
      sizeLabels: null
    },
    [`${type}_BY_REQUEST_SIZE`]: {
      sizeLabels
    },
    [`${type}_BY_RESPONSE_SIZE`]: {
      sizeLabels
    },
  }

  useEffect(() => {
    isMounted.current = true;

    if (response && isMounted.current) {
      const data = consolidateMetrics(apiResponse);
      setResponse(transformData(data));
    }

    return () => {
      isMounted.current = false;
    };
  }, [apiResponse]);

  const generatePDF = (selectedIds, selectedDate) => {
    const charts = {
      chartRefs: response.map((_, i) => chartRefs[i].current.getRef()),
      chartData: chartsData.current,
    };
    const name = type === 'REQUESTS' ? 'Requests Report' : 'Failures Report';
    generateMetricsPDF(charts, name, selectedIds, selectedDate, apiResponse, type);
  };

  useImperativeHandle(ref, () => ({
    generatePDF: (selectedIds, selectedDate) => {
      generatePDF(selectedIds, selectedDate);
    },
  }));

  const buildLabelFromTags = (tags) =>
    Object.entries(tags)
      .map(([_, value]) => `${value}`)
      .join(", ");

function transformData(inputData) {
  const timestamps = new Set();

  inputData.results.forEach(result => {
      result.metrics.forEach(metric => {
          if (metric.values && metric.values.length > 0) {
              metric.values.forEach(value => {
                  timestamps.add(value[0]);
              });
          }
      });
  });

  const sortedTimestamps = Array.from(timestamps);
  
  function timestampToDate(timestamp) {
      const date = new Date(timestamp);
      return date.toISOString().split('T')[0];
  }

  let dates = sortedTimestamps.map(timestampToDate);
  
  const resultArray = [];
  
  inputData.results.forEach(result => {
      const datasets = [];
      const metricsData = result.metrics.filter((metric, i) => {
        if(getAllChartsData[metric.name] && !metric.columns?.length){
          metric.columns = [
            "time",
            "sum"
          ]
        }
        return metric.columns && metric.columns.length > 0;
      });
      metricsData.forEach((metric, index) => {
          const metricName = metric.name;

          const label = getAllChartsData[metricName]?.sizeLabels?.[index]
            ? getAllChartsData[metricName].sizeLabels[index]
            : buildLabelFromTags(metric.tags) || "";
          
          let dataPoints = [];

          if(metric.values.length === 0) dates = [];
          
          metric.values.forEach(value => {
              const timestamp = value[0];
              dataPoints.push(value[1]);
          });
         // const nulCheck = dataPoints.every(item => item === null);
          
          // if(nulCheck) {
          //   dataPoints = [];
          //   dates = []
          // }
          
          datasets.push({
              name: metricName,
              label: label,
              data: dataPoints,
              borderColor: COLOR_PALETTE[index % COLOR_PALETTE.length],
              backgroundColor: COLOR_PALETTE[index % COLOR_PALETTE.length],
              fill: false,
              tension: 0.1,
              borderWidth: 2,
              pointRadius: dataPoints.length > 1 ? 0 : 3,
              borderWidth: 2,
          });
      });
      
      if (datasets.length > 0) {
          resultArray.push({
              labels: dates,
              datasets: datasets,
              name:datasets[0].name
          });
      }
  });
  
  chartsData.current = resultArray;
  return resultArray;
}

  const getOptions = (data) => {
    const isNoData = data.datasets.every(
      (dataset) =>
        dataset.data.length === 0 || dataset.data.every((value) => value === 0)
    );
    return {
      elements: {
        point: {
          radius: (context) => {
            const datasetIndex = context.datasetIndex;
            const dataset = data.datasets[datasetIndex];

            return dataset.data.length === 1 ? 3 : 0;
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: isNoData ? 1 : undefined,
          },
        },
      },
    };
  };

  return (
    <div className="requests-container">
        {Array.isArray(response) && response.map((data, index) => {
          const title = Object.keys(getAllChartsData)[index];
          return (
            <div key={title} className="chart">
              <CommonChart
                ref={chartRefs[index]}
                expand={false}
                type="line"
                chartData={data}
                title={<SectionHeader type={title} />}
                legend={true}
                options={getOptions(data)}
                loading={loading}
              />
            </div>
          )
        })}
    </div>
  );
});

export default React.memo(RequestsFailures);
