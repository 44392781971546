import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Site from '../../siteEngine/components/Site';
import MyAccessPage from '../../siteEngine/components/MyAcessPage/MyAccessPage';
import WhatsNew from '../../siteEngine/components/WhatsNew/WhatsNew';
import GettingStarted from '../../siteEngine/components/GettingStarted/GettingStarted';
import ApiBestPractice from '../../siteEngine/components/ApiBestPractice/ApiBestPractice';
import MyApps from '../../docEngine/components/MyApps/MyApps';
import APImetrics from '../../siteEngine/components/APImetrics/APImetrics'
import ActAsPartnerAccessError from './ActAsPartnerAccessError/ActAsPartnerAccessError';
import { getActAsPartnerAccess } from '../utility/utils';

function CustomUiRouter({ store}) {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Site store={store} />} />
          <Route path="/auth" element={<Site store={store} />} />
          <Route path="/auth/request" element={getActAsPartnerAccess('request') ? <ActAsPartnerAccessError/> : <MyAccessPage />} />
          <Route path="/request" element={getActAsPartnerAccess('request') ? <ActAsPartnerAccessError/> : <MyAccessPage />} />
          {/* <Route path="/whats_new" element={<WhatsNew />} />
          <Route path="/auth/whats_new" element={<WhatsNew />} />
          <Route path="/getting_started" element={<GettingStarted />} />
          <Route path="/auth/getting_started" element={<GettingStarted />} /> */}
          <Route path="/api_best_practice" element={<ApiBestPractice />} />
          <Route path="/auth/api_best_practice" element={<ApiBestPractice />} />
          <Route path="/my_apps" element={<MyApps />} />
          <Route path="/auth/my_apps" element={<MyApps />} />
          <Route path="/auth/api_metrics" element={<APImetrics />} />
          <Route path="/api_metrics" element={<APImetrics />} />
        </Routes>
      </div>
    </Router>
  );
}

export default CustomUiRouter;