import React, { useRef, useState, useEffect } from 'react';

const Dropdown = ({data, name, label, placeholder, idKey, descKey, onHbrSelect, value, searchable = true}) => {
  const selectRef = useRef();
  const initialData = useRef();
  const dropdownList = useRef({});
  const [state, setState] = useState(data || []);

  useEffect(() => {
    setState(data);
    initialData.current = data;
  }, [data]);

  useEffect(() => {
    selectRef.current?.addEventListener('hbr-change', handleClick);
    selectRef.current?.addEventListener('hbr-search', onHbrSearch);
    selectRef.current?.addEventListener('hbr-clear', handleClick);
    return () => {
      selectRef.current?.removeEventListener('hbr-change', handleClick);
      selectRef.current?.removeEventListener('hbr-search', onHbrSearch);
      selectRef.current?.removeEventListener('hbr-clear', handleClick);
    }
  }, []);

  const handleClick = (e) => {;
    e.target.selectedData = dropdownList.current[e.target.value];
    onHbrSelect(e);
  }

  const onHbrSearch = (e) => {
    const filtered = initialData.current?.filter(item => {
      const text = item[descKey].toLowerCase();
      if (e.detail) {
        const searchText = e.detail.value.toLocaleLowerCase();
        if (text.includes(searchText)) {
          item.query = e.detail.value;
          return item;
        }
      }
    });
    
    if (filtered.length) {
      setState(filtered);
    } else {
      setState([]);
    }
  };

  const renderDesc = (item) => {
    if(Array.isArray(descKey)) {
      let desc = '';
      descKey.forEach((key, i) => {
        if (i > 0) desc += ' / ';
        desc += item[key];
      });
      return desc;
    }
    return item[descKey];
  }

  return (
    <div className='mb-3'>
      <hbr-select
        name={name}
        ref={selectRef}
        label={label}
        placeholder={placeholder}
        clearable={searchable}
        searchable={searchable}
        required
        value={value}
      >
        {state?.map((item, i) => {
          dropdownList.current = { ...dropdownList.current, [item[idKey]]: item };
          return <hbr-option key={`${item[idKey]}-${i}`} value={item[idKey]}>{renderDesc(item)}</hbr-option>
        })}
      </hbr-select>
    </div>
  )
};

export default React.memo(Dropdown);