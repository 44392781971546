import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './_notificationsDropdown.scss';

function NotificationsDropdown({ isLoggedIn, pendingRequests }) {    
    return (
        <Dropdown>
            {pendingRequests &&
            <>
            <Dropdown.Toggle as="div" id="dropdown-custom-components-notification" className='no-caret'>
                {isLoggedIn && pendingRequests.hasPendingRequests ? (
                    <div className='me-3 pending-request'>
                        <i className="atm-icon bell-filled notification-icon" />
                        <p className="mb-0 notification-dot"></p>
                    </div>) : (
                    <i className="atm-icon bell me-3  notification-icon" />
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-custom">
                <Dropdown.Header>
                    Notifications
                    {pendingRequests.pendingAccessRequestDetails && pendingRequests.pendingAccessRequestDetails.length > 0 && (
                        <span className="notification-count">{pendingRequests.pendingAccessRequestDetails.length}</span>
                    )}
                </Dropdown.Header>
                <div className="items-container">
                    {pendingRequests.pendingAccessRequestDetails && pendingRequests.pendingAccessRequestDetails.length ? (
                        pendingRequests.pendingAccessRequestDetails.map((notification) => (
                            <Dropdown.Item
                                href={`/auth/#request/?notificationId=${notification.contractId}`}
                                key={notification.contractId}
                                className="notification-item"
                            >
                                <div className="notification-title">
                                    <i className="atm-icon bell-filled" /><span>Access Request for {notification.apiName}</span>
                                </div>
                                <div className="notification-date">{notification.requestedDate.slice(0, 10)}</div>
                                <div className="notification-description">
                                    {notification.reason}
                                </div>
                            </Dropdown.Item>
                        ))
                    ) : (
                        <div className="empty-notifications">
                            <img
                                src="https://www.cisco.com/c/dam/cdc/ui/atm/1-5-0/images/illustrations/empty-state/no-new-notifications-large.svg"
                                alt="No new notifications"
                                width="240px"
                                height="160px"
                            />
                            <p>No new notifications</p>
                        </div>
                    )}
                </div>
            </Dropdown.Menu>
            </>
}
        </Dropdown>
    );
}

export default NotificationsDropdown;