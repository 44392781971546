import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import "bootstrap-icons/font/bootstrap-icons.css";
import Modal from 'react-bootstrap/Modal';
import PopupAlert from '../../../../common/Alert/PopupAlert';
import { LENGTH_RANGE, TEXT_PATTERN, URL_PATTERN } from '../../../../common/base/constants';

function EditOrCreateApplication({ data = null, className, show, onClose, onSubmit, type, showAlert = false, status="", message="", title="" }) {
    const grantTypesRef = useRef();
    const [formValues, setFormValues] = useState({
        clientAppName: '',
        clientAppDescription: '',
        applicationURL: '',
        grantTypes: [],
        redirectUris: ['']
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [isRedirectUriRequired, setIsRedirectUriRequired] = useState(false);
    const [touched, setTouched] = useState({});
    const [descriptionLength, setDescriptionLength] = useState(0);
    const grantTypeMap = {
        'Authorization Code Grant': 'authorization_code',
        'Client Credential Grant': 'client_credentials',
        'Resource Owner Grant': 'resource_owner_credentials',
        'Implicit Grant': 'implicit',
        'Refresh Token': 'refresh_token'
    };
    
    useEffect(() => {
        if (data) {
            setFormValues(data);
            setDescriptionLength(data.clientAppDescription.length);
        }
    }, [data]);

    function handleClose() {
        onClose();
    }

    function handleInputChange(e) {
        const { name, value } = e.target;
        if(!name) return;
        if (name === 'redirectUris') {
            setFormValues(prevValue => ({...prevValue, [name]: value.split(',').map(uri => uri.trim())}));
        }else {
            let _value = value;
            if(name === 'grantTypes' && value.includes('Refresh Token')) {
                const isRequiredSelected = value.includes('Authorization Code Grant') || value.includes('Resource Owner Grant');
                if(!isRequiredSelected) {
                    _value = value.filter(gt => gt !== 'Refresh Token');
                    grantTypesRef.current.value = !_value.length ? null : _value;
                }
            }
            setFormValues(prevValue => ({...prevValue, [name]: _value}));
        }

        if (name === 'clientAppDescription') {
            setDescriptionLength(value.length);
        }
        setTouched(prevValue => ({
            ...prevValue,
            [name]: true
        }));
    };

    function checkDuplicate(list, errors) {
        const obj = {};
        for(let i=0; i<list.length; i++) {
            if(obj[list[i]]){
                errors.redirectUris = "Duplicate URIs found!"
                break;
            }else {
                obj[list[i]] = 1;
            }
        }

    }

    function validateForm() {
        const errors = {};

        //Application name validation
        if (!formValues.clientAppName.trim()) {
            errors.clientAppName = 'Application name is required';
        }
        if (formValues.clientAppName && !TEXT_PATTERN.test(formValues.clientAppName)) {
            errors.clientAppName = 'Application name must not have special characters';
        }
        if (formValues.clientAppName && formValues.clientAppName.length < LENGTH_RANGE.MIN) {
            errors.clientAppName = `Application name must be at least ${LENGTH_RANGE.MIN} characters`;
        }

        //Application description validation
        if (formValues.clientAppDescription && !TEXT_PATTERN.test(formValues.clientAppDescription)) {
            errors.clientAppDescription = 'Description must not have special characters';
        }

        //Application URL validation
        if (formValues.applicationURL && !URL_PATTERN.test(formValues.applicationURL)) {
            errors.applicationURL = 'Application URL must be a valid URL';
        }    
        //Grant types validation
        if (formValues.grantTypes.length === 0) {
            errors.grantTypes = 'At least one grant type is required';
        }
        if (type !== "edit" && (formValues.grantTypes.includes('Authorization Code Grant') || formValues.grantTypes.includes('Implicit Grant'))) {
            if (!formValues.redirectUris[0]) {
                errors.redirectUris = 'Redirect URI is required';
            }  
        }
        checkDuplicate(formValues.redirectUris || [], errors);
        if (formValues.grantTypes.includes('Refresh Token') &&
            !formValues.grantTypes.includes('Authorization Code Grant') &&
            !formValues.grantTypes.includes('Resource Owner Grant')) {
            errors.grantTypes = 'Refresh Token requires Authorization Code Grant or Resource Owner Grant';
            }  

        //Redirect URI validation
        if (formValues.redirectUris[0]) {
                formValues.redirectUris.forEach(uri => {
                    if (!URL_PATTERN.test(uri)) {
                        errors.redirectUris = 'Redirect URI must be a valid URL';
                    }
                });
        }    
        
        setFormErrors(errors);
        setIsFormValid(Object.keys(errors).length === 0);
    }

    useEffect(() => {
        const isRequired = formValues.grantTypes.includes('Authorization Code Grant') ||
                           formValues.grantTypes.includes('Implicit Grant');
        setIsRedirectUriRequired(isRequired);
        validateForm();
        if(formValues.grantTypes && grantTypesRef.current) {
            grantTypesRef.current.value = formValues.grantTypes;
        }
    }, [formValues]);

    useEffect(() => {
        const select = grantTypesRef.current;
    
        if (!select) {
          return;
        }
        
        if (!show) {
            select.removeEventListener('hbr-change', handleInputChange);
        } else {
            select.addEventListener('hbr-change', handleInputChange);
        }
    }, [show]);

    function handleSubmit() {
        const convertedFormValues = {
            ...formValues,
            grantTypes: formValues.grantTypes.map(grantType => grantTypeMap[grantType].toUpperCase())
        };
        onSubmit(convertedFormValues);
    }

    const isRefreshTokenDisabled = () => {
        return !(formValues.grantTypes.includes('Authorization Code Grant') ||
            formValues.grantTypes.includes('Resource Owner Grant'));
    };

    const isRedirectUrlRequired = () => {
        return formValues.grantTypes.includes('Authorization Code Grant') ||
        formValues.grantTypes.includes('Implicit Grant');
    }

    return (
        <Modal show={show} centered="true" onHide={handleClose} className={`app-modal-popup ${className || ''}`} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>{type} Application</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {showAlert && (
                    <PopupAlert status={status} message={message} title={title} />
                )}
                <hbr-form autocomplete="off">
                    <hbr-input
                        className={formErrors.clientAppName && touched.clientAppName ? "form-control is-invalid" : "form-control"  }
                        label="Application name" 
                        placeholder="Application name" 
                        type="text"
                        name="clientAppName"
                        value={formValues.clientAppName}
                        onInput={handleInputChange}
                        required
                        onBlur={() => setTouched({ ...touched, clientAppName: true })}
                        maxLength={LENGTH_RANGE.MAX}
                        help-text={formErrors.clientAppName}
                        error={formErrors.clientAppName && touched.clientAppName}
                    ></hbr-input>
                    
                    <hbr-textarea
                        onInput={handleInputChange}
                        className={`no-resize ${formErrors.clientAppDescription && touched.clientAppDescription ? "form-control is-invalid" : "form-control"}`}
                        label="Application Description"
                        name="clientAppDescription"
                        placeholder="Application Description"
                        value={formValues.clientAppDescription}
                        onBlur={() => setTouched({ ...touched, clientAppDescription: true })}
                        maxLength={LENGTH_RANGE.MAX}
                        help-text={formErrors.clientAppDescription && touched.clientAppDescription ? formErrors.clientAppDescription : `${descriptionLength} / ${LENGTH_RANGE.MAX} characters used`}
                        error={formErrors.clientAppDescription && touched.clientAppDescription}
                    ></hbr-textarea>

                    <hbr-input
                        className={formErrors.applicationURL && touched.applicationURL ? "form-control is-invalid" : "form-control"}
                        label="Application URL" 
                        placeholder="Application URL" 
                        type="text"
                        name="applicationURL"
                        value={formValues.applicationURL}
                        onInput={handleInputChange}
                        onBlur={() => setTouched({ ...touched, applicationURL: true })}
                        help-text={formErrors.applicationURL}
                        error={formErrors.applicationURL && touched.applicationURL}
                    ></hbr-input>

                    <hbr-select
                        ref={grantTypesRef}
                        label='Oauth 2.0 Grant Type'
                        placeholder="Select Grant Types"
                        variant="multiple"
                        clearable="false"
                        name='grantTypes'
                        required
                        hoist={true}
                        error={formErrors.grantTypes && touched.grantTypes}
                        onBlur={() => setTouched({ ...touched, grantTypes: true })}
                    >
                        {Object.keys(grantTypeMap).map((key, i) => (
                            <React.Fragment key={key}>
                                <hbr-option value={key} disabled={key === 'Refresh Token' && (type === "Edit" || isRefreshTokenDisabled())}>
                                    <hbr-checkbox 
                                        className="atm-option--checkbox"
                                        disabled={key === 'Refresh Token' && (type === "Edit" || isRefreshTokenDisabled())}
                                    >
                                        {key}{key === 'Refresh Token' && <span className='ms-1 refreshHint'>(Requires one of Authorization Code Grant, Resource Owner Grant)</span>}
                                    </hbr-checkbox>
                                </hbr-option>
                                {i === 0 && formErrors.grantTypes && touched.grantTypes && <div slot="help-text">{formErrors.grantTypes}</div>}
                            </React.Fragment>
                        ))}
                    </hbr-select>
                    
                    <hbr-input
                        className={formErrors.applicationURL && touched.applicationURL ? "form-control is-invalid" : "form-control"}
                        label="Redirect URI" 
                        placeholder="Redirect URI" 
                        type="text"
                        required={isRedirectUrlRequired()}
                        name="redirectUris"
                        value={formValues.redirectUris[0] !== '' ? (formValues.redirectUris.length > 1 ? formValues.redirectUris.join(', ') : formValues.redirectUris[0]) : formValues.redirectUris.splice(0)}
                        onInput={handleInputChange}
                        disabled={type === "Edit"}
                        onBlur={() => setTouched({ ...touched, redirectUris: true })}
                        help-text={formErrors.redirectUris ? formErrors.redirectUris : ''}
                        error={formErrors.redirectUris && touched.redirectUris}
                    ></hbr-input>
                </hbr-form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditOrCreateApplication;
