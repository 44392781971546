import React from 'react';
import { useAlert } from './AlertContext';
import "./_popupAlert.scss";

const PopupAlert = ({status, message, title}) => {
    // success, danger, warning, info
    
  return (
      <div className="mb-3">
        <hbr-banner
          closable="true"
          icon="status-info-outline"
          sentiment={status}
          open="true"
          >
          <p class="atm-banner--title">{title}</p>
          {message}
          <i slot="icon" class={`atm-icon status-${status == "success" ? "check" : status == "danger" ? "exclamation-circle" : "warning"}-outline`}></i>
        </hbr-banner>
      </div>
  );
};

export default PopupAlert;