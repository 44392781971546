import React from "react";
import { generateConfigData, generateInfoCard, processLink, generateLink } from "Common/utility/infoCard"
import { generateCardsJson } from "Common/utility/serialize";
import PortalError from "../portalError/portalError";
import { MAX_ELLIPSIS_LENGTH } from "../../../js/common/base/constants";


export default class PortalGroupCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [],
            searchVal: "",
            viewType: 'card',// 'card' || 'list'
            bgImage: "",
            IconImage: ""
        };
        this.allCards = [];


        let originalHTML = `<portalError type="" height="height-80">
        <title>No Results Found</title>
        <message>Try adjusting your search !</message>
        <imageSrc width="200px" height="200px">nothing-found</imageSrc>
 </portalError>`
        const wrapper = document.createElement("div");
        wrapper.innerHTML = originalHTML;
        this.errorConfig = wrapper;
    }

    handleInputChange = (e) => {
        const searchVal = e.target.value;
        this.setState({ searchVal }, this.handleSearch);
    };

    handleSearch = () => {
        const { searchVal } = this.state;
        const filteredCards = this.allCards.filter((card) =>
            card.title.toLowerCase().includes(searchVal.toLowerCase())
        );
        this.setState({ cards: filteredCards });
    };


    changeViewType = (type) => () => {
        if (type === this.state.viewType) return;
        this.setState({
            viewType: type,
            searchVal: "",
            cards: this.allCards
        });
    };

    handleLinkRedirect = (link) => {
        window.location.href = link;
    }

    componentDidMount() {
        const { config } = this.props;

        if (config) {
            const generatedCards = generateCardsJson(config);
            this.allCards = generatedCards;
            this.setState({
                cards: generatedCards,
                bgImage: config.querySelector('bgImage')?.textContent.trim(),
                IconImage: config.querySelector('IconImage')?.textContent.trim()
            });
            if (this.allCards.length > 10) {
                this.setState({ viewType: 'list' });
            }
        }
    }

    render() {
        const { config } = this.props;
        const { cards, searchVal, viewType, bgImage, IconImage } = this.state;

        if (!config) {
            return <span>Loading...</span>;
        }
        return (
            <>
                <div className="portalGroupCard">
                    <div className="row justify-content-between mx-5  align-items-center header">
                        <div className="col">
                            <h5 className="mb-0 header-title">Available APIs

                            </h5>
                        </div>
                        <div className="col my-3 d-flex justify-content-end">
                            <div className="d-flex mx-3 align-items-center justify-content-between">

                                <hbr-input
                                    clearable="true"
                                    pill="true"
                                    placeholder="Filter APIs"
                                    type="search"
                                    size="small"
                                    value={searchVal}
                                    onInput={this.handleInputChange}
                                >
                                    <i class="atm-icon filter" slot="prefix"></i>
                                    <i class="atm-icon" onClick={this.handleSearch} slot="clear-icon"></i>
                                </hbr-input>
                            </div>
                            <div className="d-flex align-items-center justify-content-between view-type">
                                <p className="mb-0 title">View:</p>
                                <div className={`mx-2 view-card-icon ${viewType === 'card' ? 'type-active px-2' : 'icon-card'} `} onClick={this.changeViewType('card')}><i class="atm-icon grid-view" /></div>
                                <div className={`view-list-icon ${viewType === 'list' ? 'type-active px-2' : 'icon-list'}`} onClick={this.changeViewType('list')}><i class="atm-icon list-view" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center 
 justify-content-sm-center justify-content-md-start px-5">
                        {viewType === 'card' && cards.map((card, index) => (
                            <div className="card col m-3" key={index} onClick={(e) => this.handleLinkRedirect(card.pageLink)}>
                                <img src={bgImage} class="card-img-top" alt=""></img>
                                <img src={IconImage} class="card-img-ico" alt=""></img>
                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="my-3 title">{card.title}</h5>
                                        <div className="description">{card.description.length > MAX_ELLIPSIS_LENGTH ? card.description.slice(0, MAX_ELLIPSIS_LENGTH).trim() + "…" : card.description}</div>
                                    </div>

                                </div>
                                {/* <div class="card-footer text-end pb-4">
                                    <a className="" href={card.link}><hbr-button variant="outline" size="medium">View API</hbr-button></a>
                                </div> */}
                            </div>
                        ))}
                        {viewType === 'list' && (
                            <div className="px-3">
                                <table className="table iap-table">
                                    <thead>
                                        <th className="title-td">API Name</th>
                                        <th>Description</th>
                                    </thead>
                                    <tbody>
                                        {cards.map((card, index) => (
                                            <tr key={index}>
                                                <td className="title-td"><a className="" href={card.pageLink}>{card.title}</a></td>
                                                <td>{card.description.length > MAX_ELLIPSIS_LENGTH ? card.description.slice(0, MAX_ELLIPSIS_LENGTH).trim() + "…" : card.description}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>


                        )}
                        {searchVal && cards.length == 0 && (
                            <PortalError config={this.errorConfig} />
                        )}
                    </div>
                </div>
            </>
        )
    }
}