/**
 * Created by aaikepae on 1/9/17.
 */
import React from "react";
import { generateConfigData, generateInfoCard, processLink, generateLink } from "Common/utility/infoCard"

export default class Banner extends React.Component {
  render() {
    const { config, location, type } = this.props;

    if (!config) {
      return <span>Loading</span>
    }

    const data = generateConfigData(config);
    const elements = generateInfoCard(data, location, this.props.type);

    const link2 = generateLink(processLink(config.queryChild("extra-hyperlink")));
    const button2 = link2 ? (
      <span className="pubhub-hero-banner-static-btn"
        data-track-name="herobanner:action_btn"
        data-track-value={data.title}>
        {link2}
      </span>
    ) : null;
    const colClassName = link2 ? 'multi-btn-col col' : 'col';
    let bgImgClass = ""
    if(type) {
      bgImgClass =+ type;
    }
    const bgImg = elements.imageStyle?.backgroundImage ? `has-bg-img ${type}` : bgImgClass;
    
    return (
      <div className={'pubhub-hero-banner-static py-5 px-3 px-sm-5 pubhuhb-weiget ' + bgImg} style={elements.imageStyle} id={this.props.id}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col">
              <h1 className="mt-0 mb-2">
                {elements.title}
              </h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-sm-12 description-container">
              {elements.description}
            </div>
            {elements.search}
          </div>
          <div className="pubhub-hero-banner-static-link row">
            <div className={colClassName} ref="btn">
              <span className="pubhub-hero-banner-static-btn"
                data-track-name="herobanner:action_btn"
                data-track-value={data.title}>
                {elements.link}
              </span>
              {button2}
            </div>
          </div>
        </div>
      </div>
    );
  }
}